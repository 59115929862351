import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

import {
  AnimatedWatch,
  DefaultLoader,
  Loading,
} from '../../presentational/AnimatedWatch/AnimatedWatch'

export const ChatMainLoadable = loadable(() => import('./ChatMain'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('chat main index split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
