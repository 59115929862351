import { useDispatch } from 'react-redux'

import { resetReferrerAction } from 'actions/system/resetReferrerAction'
import { go } from 'functions/router'

import { useShallowEqualSelector } from './useShallowEqualSelector'

/**
 * Нужно для проверки возвращения с отдельных страниц-оплат биллинга.
 * @param referrer
 */
export const fromPayPalOrBillingExchange = (referrer?: string) =>
  referrer?.startsWith('https://www.paypal.com/') ||
  referrer?.startsWith('https://www.mamba.ru/exchange') ||
  referrer?.startsWith('http://exchange.mamba.ru')

/**
 * На paypal.com при нажатии на кнопки на подобии "Вернуться на страницу продавца",
 * "Отмена оплаты" происходит push в browser history на приложение mamba.ru.
 * Где показывается витрина, на которой есть крестик,
 * который делает goBack, что возвращает нас на paypal.com
 *
 * Этот хук возвращает обработчик, который разрывает этот порочный круг.
 * При нажатии на крестик на витрине, этот обработчик возвращает нас на эмпирическое число страниц 2
 */
export const useGoBackAfterPayPal = () => {
  const dispatch = useDispatch()
  const { referrer } = useShallowEqualSelector(
    ({ systemReducer: { referrer } }) => ({
      referrer,
    })
  )

  if (fromPayPalOrBillingExchange(referrer)) {
    return {
      goBackAfterPayPal() {
        dispatch(resetReferrerAction())
        dispatch(go(-2))
      },
    }
  }

  return {}
}
