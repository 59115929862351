import React, { FC, ReactNode } from 'react'

import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'
import {
  OrdinaryButton,
  SecondaryButton,
} from 'components/presentational/button'
import { GeolocationPermissionSvg } from 'components/presentational/svg/GeolocationPermissionSvg'
import UniNoticeViewContent from 'components/uninotice/UniNoticeViewContent'

import { NotNowButton } from './NotNowButton'

export const GeoPermissionRequestContent: FC<{
  title?: ReactNode | null
  onPrimaryButtonClick: VoidHandler
  primaryButtonLoading?: boolean
  onSecondaryButtonClick?: VoidHandler
}> = ({
  title,
  onPrimaryButtonClick,
  primaryButtonLoading,
  onSecondaryButtonClick,
}) => {
  return (
    <UniNoticeViewContent
      title={title}
      imageComponent={<GeolocationPermissionSvg />}
      textComponent={
        <FormattedMessage
          id="geolocation.you.need.to.provide.access"
          defaultMessage="Для поиска партнёра приложению нужен доступ к вашему местоположению. Не волнуйтесь, веб-приложение будет получать координаты только в тот момент, когда вы им пользуетесь."
        />
      }
      primaryComponent={
        <OrdinaryButton
          onClick={onPrimaryButtonClick}
          loading={primaryButtonLoading}
          data-name="share-action"
        >
          <FormattedMessage
            id="geolocation.grant_access"
            defaultMessage="Разрешить доступ"
          />
        </OrdinaryButton>
      }
      secondaryComponent={
        onSecondaryButtonClick && (
          <NotNowButton onClick={onSecondaryButtonClick} />
        )
      }
      inline
    />
  )
}
