import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { findCountryCode } from 'actions/profile/function/findCountryCode'
import { AsyncThunkAction } from 'actions/types'
import { setCounterDownAction } from 'actions/utility/counterDown/counterDownAction'
import {
  fetchPasswordCrossUserApi,
  resetPasswordEmailApi,
  resetPasswordPhoneApi,
} from 'api/authorizationApi'
import { concatPhone } from 'components/page/RestorePassword/function/concatPhone'
import {
  emailResetSuccessPath,
  phoneAcceptedPath,
  restorePasswordPath,
} from 'components/page/RestorePassword/RestorePasswordLoadable'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'

export const RESET_PASSWORD = 'RESET_PASSWORD' as const

interface ResetPasswordAction extends AsyncAction {
  type: typeof RESET_PASSWORD
  isPhone?: boolean
  isEmail?: boolean
}

export const resetPasswordPhoneAction = (
  ignoreCrossAuth: boolean,
  baseUrl = ''
): AsyncThunkAction => (dispatch, getState) => {
  const { authorizationReducer, countriesInfo } = getState()
  const { form } = authorizationReducer
  const {
    phone: { phoneNumber, countryCode },
  } = form

  const { countriesList } = countriesInfo
  const foundCountry = findCountryCode(countriesList, countryCode)
  const isPhone = true

  if (foundCountry && phoneNumber) {
    const { prefix } = foundCountry
    return dispatch({
      type: RESET_PASSWORD,
      isPhone,
      promise: () => {
        return resetPasswordPhoneApi(
          concatPhone(prefix, phoneNumber),
          ignoreCrossAuth
        ).then((json) => {
          if (json.ok) {
            dispatch(
              push(
                mergeAllUrls(baseUrl, restorePasswordPath, phoneAcceptedPath)
              )
            )
          }
          return json
        })
      },
    })
  } else {
    console.error(`cannot find country with code ${countryCode}`)
    return Promise.resolve()
  }
}

export const resetPasswordPhoneNextAction = (ignoreCrossAuth?: boolean) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  const {
    authorizationReducer: {
      form: {
        phone: { phoneNumberFull },
      },
    },
  } = getState()

  if (!phoneNumberFull) {
    console.warn('Missing phone verification data')
    return
  }

  return dispatch({
    type: RESET_PASSWORD,
    isPhone: true,
    promise: async () => {
      const response = await resetPasswordPhoneApi(
        phoneNumberFull,
        ignoreCrossAuth
      )

      if (response.ok) {
        dispatch(setCounterDownAction(60))
      }

      return response
    },
  })
}

export const resetPasswordEmailAction = (
  isRestorePasswordCrossAuthorized: boolean,
  baseUrl = ''
): AsyncThunkAction => (dispatch, getState) => {
  const { authorizationReducer } = getState()
  const { form } = authorizationReducer
  const { email } = form

  dispatch({
    type: RESET_PASSWORD,
    promise: () => {
      const success = (json) => {
        if (json.ok) {
          dispatch(
            push(
              mergeAllUrls(baseUrl, restorePasswordPath, emailResetSuccessPath)
            )
          )
        }
        return json
      }

      if (isRestorePasswordCrossAuthorized) {
        return fetchPasswordCrossUserApi(email).then(success)
      }
      return resetPasswordEmailApi(email).then(success)
    },
  })
}

export const resetPasswordEmailNextAction = (email: string) => ({
  type: RESET_PASSWORD,
  isEmail: true,
  promise: () => resetPasswordEmailApi(email),
})

export type ResetPasswordTypes = ResetPasswordAction
