import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const CityListLoadable = loadable(
  async () => (await import('./CityList')).CityList,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('City list split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
