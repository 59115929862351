import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from '../../presentational/AnimatedWatch/AnimatedWatch'

export const CashOutLoadable = loadable(() => import('./CashOutIndex'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('cash out split error', error)
    }
    return <DefaultLoader />
  })({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
})
