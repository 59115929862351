import { useMemo } from 'react'

import { hasEdnaSupport } from 'functions/edna/hasEdnaSupport'

import { useShallowEqualSelector } from './useShallowEqualSelector'

export const useSupportEdna = () => {
  const { features } = useShallowEqualSelector(
    ({ systemReducer: { features } }) => ({
      features,
    })
  )

  return useMemo(() => {
    return hasEdnaSupport(features)
  }, [features])
}
