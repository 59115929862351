import { PartialRootState } from 'common/store/store.types'
import { RootState } from 'reducers/RootState'

export const mutatePartialRootStateWithVipBeelineState = (
  reducerState: RootState,
  partialRootState: PartialRootState
): void => {
  if (reducerState.vipBeeline) {
    partialRootState.vipBeeline = {
      subscriptionId: reducerState.vipBeeline.subscriptionId,
    }
  }
}
