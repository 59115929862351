import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'
import { SecondaryButton } from 'components/presentational/button'

export const NotNowButton: FC<{ onClick: VoidHandler }> = ({ onClick }) => {
  return (
    <SecondaryButton onClick={onClick} data-name="later-action">
      <FormattedMessage id="app.not_now" defaultMessage="Не сейчас" />
    </SecondaryButton>
  )
}
