import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { requestAccountRemovalWithReasonApi } from 'api/settings/requestAccountRemovalWithReasonApi'
import { DeleteReasons } from 'components/page/DeleteProfile/DeleteProfile.constants'
import { finalProfileDeletePath } from 'components/page/DeleteProfile/DeleteProfile.paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { replace } from 'functions/router'

export const REQUEST_ACCOUNT_REMOVAL_WITH_REASON = 'REQUEST_ACCOUNT_REMOVAL_WITH_REASON' as const

export const requestAccountRemovalWithReasonAction = (
  emailVerified: boolean
) => async (dispatch: AppDispatchNext, getState: AppGetState) => {
  const {
    profileDelete: { reasonSelected },
    systemReducer: { baseUrl },
  } = getState()

  if (emailVerified) {
    return dispatch(requestAccountRemovalWithReasonPlainAction(reasonSelected))
  } else {
    return dispatch(
      replace(mergeAllUrls(baseUrl, finalProfileDeletePath, reasonSelected!))
    )
  }
}

export const requestAccountRemovalWithReasonPlainAction = (
  removalReason?: DeleteReasons | null
) => ({
  type: REQUEST_ACCOUNT_REMOVAL_WITH_REASON,
  promise: () => requestAccountRemovalWithReasonApi(removalReason!),
})
