import React, { FC } from 'react'

import loadable from '@loadable/component'

import { indexPath, ratingPath } from 'components/paths'
import { withLoader } from 'components/system/loadable/withLoader'
import { Replace } from 'components/system/Replace'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const BlockedProfileLoadable: FC = () => {
  const { profileBanned, authorized } = useShallowEqualSelector(
    ({
      errorReducer: { profileBanned },
      authorizationReducer: { authorized },
    }) => ({
      profileBanned,
      authorized,
    })
  )

  if (!profileBanned && !authorized) {
    return <Replace to={indexPath} uid="BlockedProfile" />
  } else if (!profileBanned && authorized) {
    return <Replace to={ratingPath} uid="BlockedProfile-authorized" push />
  }

  return <_BlockedProfileLoadable />
}

const _BlockedProfileLoadable = loadable(
  async () => (await import('./BlockedProfile')).BlockedProfile,
  withLoader
)
