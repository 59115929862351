import React, { useEffect, FC } from 'react'

import { useDispatch } from 'react-redux'

import { fetchTeamoTokenAction } from 'actions/profile/teamoCurrentProfileAction'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { OrdinaryButton } from 'components/presentational/button'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const TeamoUniNoticeLink: FC<{
  title: string
}> = ({ title }) => {
  const dispatch = useDispatch()

  const { url } = useShallowEqualSelector(
    ({
      profile: {
        teamo: { url },
      },
    }) => ({
      url,
    })
  )

  useEffect(() => {
    dispatch(fetchTeamoTokenAction())
  }, [dispatch])

  if (!url) {
    return <DefaultLoader />
  }

  return (
    <OrdinaryButton
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      data-name="uninotice-login-to-teamo-action"
    >
      {title}
    </OrdinaryButton>
  )
}
