import { Reducer } from 'redux'

import {
  fetchPromoCodeAboutAction,
  FETCH_PROMO_CODE_ABOUT,
} from 'actions/promoCode/fetchPromoCodeAboutAction'
import {
  resetPromoCodeAboutAction,
  RESET_PROMO_CODE_ABOUT,
} from 'actions/promoCode/resetPromoCodeAboutAction'
import {
  restorePartialStateAction,
  RESTORE_PARTIAL_STATE,
} from 'actions/system/localStorageAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export const promoCodeReducer: Reducer<
  PromoCodeReducerState,
  PromoCodeReducerActions
> = (
  state = {
    aboutCode: null,
    aboutCodeFetching: false,
    aboutCodeFetched: false,
    aboutCodeFetchingFailed: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_PROMO_CODE_ABOUT:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          aboutCode: null,
          aboutCodeFetching: true,
          aboutCodeFetched: false,
          aboutCodeFetchingFailed: false,
        }),
        (result) => ({
          aboutCode: result,
          aboutCodeFetching: false,
          aboutCodeFetched: true,
          aboutCodeFetchingFailed: false,
        }),
        () => ({
          aboutCode: null,
          aboutCodeFetching: false,
          aboutCodeFetched: false,
          aboutCodeFetchingFailed: true,
        })
      )

    case RESET_PROMO_CODE_ABOUT:
      return {
        ...state,
        aboutCode: null,
        aboutCodeFetching: false,
        aboutCodeFetched: false,
        aboutCodeFetchingFailed: false,
      }

    case RESTORE_PARTIAL_STATE:
      if (action.name === nameReducer) {
        return {
          ...state,
          ...action.partialState.promoCode,
        }
      }

      return state

    default:
      return state
  }
}

export const PROMO_CODE_BONUS_TYPE_VIP = 'vip'
export const PROMO_CODE_BONUS_TYPE_COINS = 'coins'
const nameReducer = 'promoCode'

export interface PromoCodeReducerState {
  aboutCode: {
    promoCode: string
    bonusType:
      | typeof PROMO_CODE_BONUS_TYPE_VIP
      | typeof PROMO_CODE_BONUS_TYPE_COINS
    vipDays?: number | undefined
    coinsAmount?: number | undefined
    canBeApplied: boolean
  } | null
  aboutCodeFetching: boolean
  aboutCodeFetched: boolean
  aboutCodeFetchingFailed: boolean
}

type PromoCodeReducerActions =
  | ReturnType<typeof fetchPromoCodeAboutAction>
  | ReturnType<typeof resetPromoCodeAboutAction>
  | ReturnType<typeof restorePartialStateAction>
