import { requestRemovalApi } from 'api/settings/settingsApi'

import { AsyncAction } from './actions.types'

export const REQUEST_REMOVAL = 'REQUEST_REMOVAL' as const

export interface RequestRemovalAction extends AsyncAction {
  type: typeof REQUEST_REMOVAL
}

export const requestRemovalAction = (): RequestRemovalAction => ({
  type: REQUEST_REMOVAL,
  promise: () => requestRemovalApi(11, 'Uniweb'),
})

export type DeleteProfileActionType = RequestRemovalAction
