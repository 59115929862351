import { testPasswordApi } from 'api/userApi'

import { fetchApi } from './index'

export const fetchArchiveApi = () => fetchApi('/personal_data/archive/request')

export function requestGdrpDataApi(password) {
  // {"requestedAt":1535980216,"ready":true,"info":{"size":3037554,"expiresAt":1536585030}}
  return fetchApi('/personal_data/archive/request', {
    method: 'POST',
    body: JSON.stringify({ password }),
  })
}

export const fetchArchiveUrlApi = (password) =>
  fetchApi('/personal_data/archive', {
    method: 'POST',
    body: JSON.stringify({ password }),
  })

export const recallGdprApi = (language, lexeme, password) =>
  fetchApi('/gdpr/withdrawConsent', {
    method: 'POST',
    body: JSON.stringify({ language, password, lexeme, applicationVersion: 1 }),
  })

export const giveConsentGdprApi = (language, lexeme) =>
  fetchApi('/gdpr/giveConsent', {
    method: 'POST',
    body: JSON.stringify({ language, lexeme, applicationVersion: 1 }),
  })

export const rejectAgreementGdprApi = (language, lexeme, password) =>
  fetchApi('/gdpr/rejectAgreement', {
    method: 'POST',
    body: JSON.stringify({ language, password, lexeme, applicationVersion: 1 }),
  })

export const testPasswordAndRecallGdprApi = async (
  locale,
  lexeme,
  passwordCheck
) => {
  const json = await testPasswordApi(passwordCheck)

  if (json.result) {
    return await recallGdprApi(locale, lexeme, passwordCheck)
  }
  return json
}

export const testPasswordAndRejectGdprApi = async (
  locale,
  lexeme,
  passwordCheck
) => {
  const json = await testPasswordApi(passwordCheck)

  if (json.result) {
    return await rejectAgreementGdprApi(locale, lexeme, passwordCheck)
  }
  return json
}
