import { Reducer } from 'redux'

import {
  OPEN_SIDEBAR_MENU_MAILRU,
  CLOSE_SIDEBAR_MENU_MAILRU,
  GET_SIDEBAR_MENU_MAILRU,
} from 'actions/menuMailruAction'

export interface MenuSideBarMailruState {
  open: boolean
  menu: {
    name: string
    url: string
  }[]
}

export const menuSideBarMailruReducer: Reducer<MenuSideBarMailruState> = (
  state = {
    open: false,
    menu: [],
  },
  action
) => {
  const { result } = action

  switch (action.type) {
    case OPEN_SIDEBAR_MENU_MAILRU:
      return { ...state, open: true }
    case CLOSE_SIDEBAR_MENU_MAILRU:
      return {
        ...state,
        open: false,
      }
    case GET_SIDEBAR_MENU_MAILRU:
      return {
        ...state,
        menu: result,
      }
    default:
      return state
  }
}
