import { legacyProfilePath, userProfilePath } from 'components/paths'
import { findMatchUrl } from 'functions/findMatch'
const profilePath = userProfilePath

const findUserIdMathWithLegacyCheck = (pathname) => {
  // Нужно для старого "тача"
  const legacyMatch = findMatchUrl(
    pathname,
    legacyProfilePath,
    false,
    false,
    false
  )
  if (legacyMatch) {
    return legacyMatch
  }
  return findMatchUrl(pathname, profilePath, false, false, false)
}

/**
 * Нужно для SPA именно через findMatchUrl
 */
export const findProfileUserId = (pathname) => {
  return findUserIdMathWithLegacyCheck(pathname)
}
