import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const PhotoViewerAttachLoadable = loadable(
  () => import('./PhotoViewerRoutesAttach'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('photo viewer attach split error', error)
      }

      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
