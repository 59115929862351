import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

export const GeoPermissionDeniedText: FC = () => {
  return (
    <FormattedMessage
      id="search.settings.location_denied"
      defaultMessage="Похоже, вы закрыли доступ к местоположению.{br}Исправьте это в настройках браузера."
      values={{ br: <br /> }}
    />
  )
}
