import { AsyncAction } from 'actions/actions.types'
import { AppDispatchNext } from 'actions/actions.typesNext'
import { definitions } from 'api/generated/seo'
import { landingSearchApi, SeoSearchPath } from 'api/mainSearchApi'
import { NodeHeaders } from 'api/NodeHeaders'

export const CALCULATE_BANNERS_URLS = 'CALCULATE_BANNERS_URLS'

interface CalculateBannersUrlsAction {
  type: typeof CALCULATE_BANNERS_URLS
}

export const calculateBannersUrlsAction = () => ({
  type: CALCULATE_BANNERS_URLS,
})

export const LANDING_SEARCH = 'LANDING_SEARCH' as const

interface LandingSearchAction extends AsyncAction<definitions['SearchResult']> {
  type: typeof LANDING_SEARCH
  form: definitions['SearchRequest']
}

export const landingSearchAction = (
  searchRequest: SeoSearchPath['post']['parameters']['body']['body'],
  locale?: string,
  headers?: NodeHeaders
) => (dispatch: AppDispatchNext) => {
  if (searchRequest.request) {
    searchRequest.request.statusNames =
      'online,hasVerifiedPhoto,spaceTimeLocation'

    return dispatch({
      type: LANDING_SEARCH,
      promise: () => landingSearchApi(searchRequest, locale, headers),
      form: searchRequest.request,
    })
  }
}

export const CLEAR_LANDING_DATA = 'CLEAR_LANDING_DATA'

interface ClearLandingDataAction {
  type: typeof CLEAR_LANDING_DATA
}

export const clearLandingDataAction = () => ({
  type: CLEAR_LANDING_DATA,
})

export type LandingActionTypes =
  | LandingSearchAction
  | CalculateBannersUrlsAction
  | ClearLandingDataAction
