import React, { FC } from 'react'

import { PhotoviewerPathParams, profilePath } from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import RedirectWithStatus from './RedirectWithStatus'

/**
 * Редиректит со старой структуры url на новую https://redmine.mamba.ru/issues/115724
 */
export const PhotoviewerOldPathRedirect: FC<{
  match: {
    params: PhotoviewerPathParams
  }
}> = ({
  match: {
    params: { profileId, photoId },
  },
}) => {
  const newUrl = mergeAllUrls(profilePath, profileId, 'photo', photoId)

  return (
    <RedirectWithStatus
      to={newUrl}
      status={301}
      uid="PhotoviewerOldPathRedirect"
    />
  )
}
