import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const LandingCommonLoadable = loadable(() => import('./LandingCommon'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Landing common split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
