import { mergeAllUrls } from 'functions/mergeAllUrls'

import { searchPath } from '../Search.paths'

export const profileCursorNegativesPartPath = 'negativeTags/:negativeTags'
export const profileCursorSearchIdPartPath = 'searchId/:searchId'
export const profileCursorOffsetPartPath = 'searcherOffset/:searcherOffset'
export const profileCursorTypePartPath = 'type/:type'

export const profileSearchFullPath = mergeAllUrls(
  searchPath,
  profileCursorNegativesPartPath,
  profileCursorSearchIdPartPath,
  profileCursorOffsetPartPath,
  profileCursorTypePartPath
)
