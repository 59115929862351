import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { RootState } from 'reducers/RootState'

/**
 * @info Реализация для редакса
 */
export const hasUserRestrictions = (state: RootState) => {
  const {
    errorReducer: {
      profileBanned,
      trackBlocked,
      ipBlocked,
      profilePersonalBanned,
    },
  } = state

  return profileBanned || trackBlocked || ipBlocked || profilePersonalBanned
}

/**
 * @info Реализация для реакта
 */
export const useHasUserRestrictions = () => {
  return useShallowEqualSelector(hasUserRestrictions)
}
