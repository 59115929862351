import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

export const GeoPermissionUnavailableText: FC = () => {
  return (
    <FormattedMessage
      id="geolocation.unavailable"
      defaultMessage="Похоже, вы отключили передачу геоданных.{br}Включите их, если хотите продолжить поиск."
      values={{ br: <br /> }}
    />
  )
}
