import React from 'react'

import { Switch, useParams } from 'react-router'

import { LOCALES_REGEXP } from 'common-constants/locale'
import { installMobileAppBannerLayerPath } from 'components/banner/InstallAppBanner/InstallMobileAppBannerLayer.paths'
import { AppRoute, LocaleParams } from 'components/components.types'
import { mailRuTokenParam } from 'components/page/Boarding/Boarding.constants'
import { NotFoundPageLoadable } from 'components/page/NotFoundPage/NotFoundPageLoadable'
import { oneTimePasswordPath } from 'components/page/WebView/WebView.paths'
import defaultRoutes from 'components/routes'
import { CheckConditionRoute } from 'components/system/CheckConditionRoute'
import { PwaCommunicationLoadable } from 'components/system/pwa/PwaCommunicationLoadable'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { isEmailPath } from 'components/system/route/IsEmailPath'
import { SentryRoute } from 'components/system/SentryRoute'
import { removeLocaleFromPath2 } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { isPwaSupported } from 'functions/pwa/isPwaSupported'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const resolveRoutes = (): AppRoute[] => {
  return defaultRoutes
}

const routes = resolveRoutes()

const deprecatedLandingUrlRegExp = new RegExp(
  `^/landing/(${LOCALES_REGEXP})/.*?`
)

/**
 * Старый tips, еще где осталось и мы должны ловить для правильного перехода.
 */
const systemUrlsPath = ['/tips', installMobileAppBannerLayerPath]

export const RouteSwitch = () => {
  const { locale } = useParams<LocaleParams>()

  const {
    language,
    authorized,
    pathname,
    search,
    showNotFound,
    query,
  } = useShallowEqualSelector(
    ({
      systemReducer: { language, showNotFound, query },
      authorizationReducer: { authorized },
      router: {
        location: { pathname, search },
      },
    }) => ({
      language,
      authorized,
      pathname,
      search,
      showNotFound,
      query,
    })
  )

  const mistakenLocale = locale && !LOCALES_REGEXP.includes(locale)
  const _pathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname

  const exceptionPath = isEmailPath(pathname)

  /**
   * Системные url, для которых,
   * в случае неавторизованным пользователем, не выставлять локаль.
   */
  const isExceptionUrlsWithoutRedirectLocale = systemUrlsPath.find((url) =>
    pathname.includes(url)
  )

  /**
   * Локаль может быть кривая, тип - Ru.
   * Но она будет, поэтому проверяем на вхождение в наш массив.
   * Если она есть и она не входит в наш массив, то
   * убираем эту локаль из пути, берем локаль из reducer и все хорошо
   * тем самым у нас будет только один redirect c 301 статусом.
   */
  if (
    !authorized &&
    (!locale || mistakenLocale) &&
    // https://redmine.mamba.ru/issues/100437 костыль для кривого старого урла лендингов
    !deprecatedLandingUrlRegExp.test(_pathname) &&
    !isExceptionUrlsWithoutRedirectLocale &&
    !exceptionPath
  ) {
    /**
     * Если локаль кривая, функция вернет путь без локали.
     * Если её нет, то просто вернет /pathname
     */
    const pathnameWithoutLocale = removeLocaleFromPath2(_pathname)

    if (mistakenLocale) {
      return (
        <RedirectWithStatus
          uid="redirect-mistaken-locale"
          status={301}
          push={false}
          to={
            mergeAllUrls(locale.toLowerCase(), pathnameWithoutLocale) + search
          }
        />
      )
    }

    /**
     * https://redmine.mamba.ru/issues/99811
     * У неавторизованного юзера всегда есть локаль
     */
    return (
      <RedirectWithStatus
        uid="redirect-add-locale"
        status={301}
        push={false}
        to={mergeAllUrls(language?.code!, pathnameWithoutLocale) + search}
      />
    )
  }

  const skipRedirect = pathname.endsWith(oneTimePasswordPath)

  /**
   * Авторизованный, с локалью редиректится на без локали.
   * Кроме старого веба и авторизации mail.ru
   * https://redmine.mamba.ru/issues/115427
   */
  if (authorized && locale && !query[mailRuTokenParam] && !skipRedirect) {
    return (
      <RedirectWithStatus
        uid="redirect-remove-locale"
        status={302}
        push={false}
        to={mergeAllUrls(removeLocaleFromPath2(_pathname)) + search}
      />
    )
  }

  /**
   * Если slash будет в конце и мы не попали ни в одно из правил выше,
   * то мы уберем slash в данном месте
   * Если же мы попадем в одно из правил выше, то _pathname уже не будет содержать
   * slash в конце.
   */
  if (pathname.length > 1 && pathname.endsWith('/')) {
    const _locale = !authorized ? locale : ''

    const uid = !authorized
      ? 'redirect-remove-last-slash-not-auth'
      : 'redirect-remove-last-slash-auth'

    return (
      <RedirectWithStatus
        uid={uid}
        status={302}
        push={false}
        to={mergeAllUrls(_locale, removeLocaleFromPath2(_pathname)) + search}
      />
    )
  }

  // console.log('RouteSwitch render', { showNotFound })
  if (showNotFound) {
    return <NotFoundPageLoadable />
  }

  return (
    // Ломает авторизованного
    // <CreateBaseUrl uid="app" contextId={defaultContextId} baseUrl={url}>
    <>
      {isPwaSupported() && <PwaCommunicationLoadable />}
      <Switch>
        {/* TODO объединить с src/common/components/system/LocaleSwitch.js:12 */}
        {routes.map(({ path, ...route }) => {
          /**
           * Вместо реальной локали, нужно указать параметр локаль,
           * чтобы иметь возможность ее получить
           */
          const newPath = mergeAllUrls(
            locale ? `:locale(${LOCALES_REGEXP})` : '',
            path
          )

          if (
            route.authorizedOnly ||
            route.unauthorizedOnly ||
            route.shouldCheck
          ) {
            return (
              <CheckConditionRoute
                {...(route as any)}
                path={newPath}
                key={newPath}
                authorizedOnly={route.authorizedOnly}
                unauthorizedOnly={route.unauthorizedOnly}
              />
            )
          }
          return (
            <SentryRoute path={newPath} {...(route as any)} key={newPath} />
          )
        })}
      </Switch>
    </>
  )
}
