import { FC, useEffect, useState } from 'react'

import { defineMessages, useIntl } from 'react-intl'
import { useLocation } from 'react-router'

import { fetchCustomMetaDataAction } from 'actions/metaData/metaDataAction'
import { switchOfUpdateTitleNewMessageAction } from 'actions/metaData/toggleSwitchTitleNewMessage'
import { fetchMetaDataAction } from 'actions/system/fetchMetaDataAction'
import { CustomMetaResult } from 'api/meta/metaDataApi'
import { chatPath } from 'components/paths'
import {
  noticePath,
  systemRedirectPath,
} from 'components/system/redirect/NoticeEmail/paths'
import { removeLocaleFromPath2 } from 'functions/language'
import {
  clearCustomMetaData,
  createCustomMetaData,
  updateCommonMetaData,
} from 'functions/metaData'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const TIME_TO_UPDATE_DOCUMENT_TITLE_WHEN_NEW_MESSAGE = 1000

const messages = defineMessages({
  hitListTitle: {
    id: 'hitlist.title',
    defaultMessage: 'Кто смотрел мою анкету — {partnerName}',
  },
  newMessageTitle: {
    id: 'new.message.title',
    defaultMessage: 'Новое сообщение - {siteName}',
  },
  newMessageTitlePlain: {
    id: 'new.message.title.plain',
    defaultMessage: 'Новое сообщение',
  },
})

export const MetaTags: FC = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const { pathname } = useLocation()

  const { authorized, meta, metaData, partner } = useShallowEqualSelector(
    ({
      systemReducer: { partner, meta },
      authorizationReducer: { authorized },
      metaData,
    }) => ({
      authorized,
      meta,
      metaData,
      partner,
    })
  )

  const [newMessageTitle, setNewMessageTitle] = useState<boolean>(false)
  const [intervalId, setIntervalId] = useState<number>(0)

  useEffect(() => {
    const createNewTitle = (): string => {
      if (metaData.hasInfo) {
        createCustomMetaData((metaData as unknown) as CustomMetaResult)

        return metaData.title as string
      }

      updateCommonMetaData(meta!.description)

      return meta!.title
    }

    const newTitle = createNewTitle()

    /**
     * В каких то случаях мы вызываем setCustomMetaDataAction
     * но не все данные передаются для update
     * Поэтому может случится https://youtrack.mamba.ru/issue/M-7493
     * Пример был src/common/components/page/PhotoViewer/Panel/PanelBottom/PanelBottom.tsx, но удален
     * так как не используется
     * В втором случае, надо убедится что есть title и обновить его. Если нет ничего делать не нужно.
     * TODO: нужна единая схема, либо на сервере либо на клиенте. Размызывание логики не очень хорошо.
     */

    if (newMessageTitle && metaData.updateTitleNewMessage) {
      if (newTitle) {
        document.title = intl.formatMessage(messages.newMessageTitle, {
          siteName: newTitle,
        })
      } else {
        document.title = intl.formatMessage(messages.newMessageTitlePlain)
      }
    } else {
      if (newTitle) {
        document.title = newTitle
      }
    }
  }, [intl, metaData, meta, partner, pathname, newMessageTitle, authorized])

  useEffect(() => {
    const pathWithoutLocale = removeLocaleFromPath2(pathname)
    const filterPage =
      pathWithoutLocale.startsWith(systemRedirectPath) ||
      pathWithoutLocale.startsWith(noticePath)

    if (!filterPage && !authorized) {
      dispatch(fetchCustomMetaDataAction(pathname)).catch(console.error)
    }
    dispatch(fetchMetaDataAction(pathname)).catch(console.error)
  }, [dispatch, pathname, authorized])

  useEffect(() => {
    clearCustomMetaData(metaData)
  }, [metaData])

  useEffect(() => {
    if (
      metaData.updateTitleNewMessage &&
      !pathname.includes(chatPath) &&
      !intervalId
    ) {
      setIntervalId(
        window.setInterval(
          () => setNewMessageTitle((value) => !value),
          TIME_TO_UPDATE_DOCUMENT_TITLE_WHEN_NEW_MESSAGE
        )
      )
    }

    if (pathname.includes(chatPath) && intervalId) {
      dispatch(switchOfUpdateTitleNewMessageAction())
      setNewMessageTitle(false)
      clearInterval(intervalId)
    }
  }, [dispatch, intervalId, metaData.updateTitleNewMessage, pathname])

  return null
}
