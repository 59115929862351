import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

import { DefaultLoader } from '../../../presentational/AnimatedWatch/AnimatedWatch'

export default loadable(() => import('./SupportFormIndex'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('form support split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
