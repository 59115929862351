import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const VerifyRealUsingSocialNetworkLoadable = loadable(
  () => import('./VerifyRealUsingSocialNetworkIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Verify real using social split error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
