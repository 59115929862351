import cookie from 'js-cookie'

import { createLocaleInApiQuery } from 'api/functions'
import {
  fetchApi as fetchApiDeprecated,
  fetchApi5,
  createLocaleQuery,
  createLocaleQueryApi5,
} from 'api/index'
import { NodeHeaders } from 'api/NodeHeaders'

export const sendInteractionApi = (anketaId, service) => {
  const s_post = cookie.get('s_post')
  return fetchApi5(`/users/${anketaId}/${service}`, {
    method: 'POST',
    body: JSON.stringify({
      anketaId,
      s_post,
    }),
  })
}

export const fetchComplainsUserApi = (
  userId,
  headers: NodeHeaders,
  locale = ''
) => {
  return fetchApiDeprecated(
    `/complaints/general_causes/${userId}/anketa/${userId}?${createLocaleQuery(
      locale
    )}`,
    {
      headers,
    }
  )
}

export const fetchAllPhotosFromAlbumsApi = (
  userId,
  headers = {},
  locale = ''
) => {
  const url = `/${userId}/albums/photos?limit=-1`
  const _locale = locale ? `&${createLocaleQueryApi5(locale)}` : ''
  return fetchApi5(`/users${url}${_locale}`, {
    headers,
  })
}

export const setMainPhotoApi = (photoId) => {
  const s_post = cookie.get('s_post')
  return fetchApi5('/profile/main_photo', {
    method: 'POST',
    body: JSON.stringify({
      photoId,
      s_post,
    }),
  })
}

export function saveInterestApi(text: string) {
  return fetchApiDeprecated('/interests/addInterest', {
    method: 'POST',
    body: JSON.stringify({
      text,
    }),
  })
}

export function fetchSearchInterestApi(text) {
  return fetchApiDeprecated(`/interests/search?partialName=${text}`)
}

export const fetchMyInterestApi = (headers?: NodeHeaders) => {
  return fetchApiDeprecated('/interests/my', { headers })
}

export function fetchDeleteMyInterestApi(interestId) {
  return fetchApiDeprecated('/interests/removeInterest', {
    method: 'POST',
    body: JSON.stringify({
      interestId,
    }),
  })
}

export const fetchPopularInterestApi = (locale) =>
  fetchApiDeprecated(`/interests/popular${createLocaleInApiQuery(locale)}`)

export const fetchOtherUserInterestApi = (userId, headers) => {
  return fetchApiDeprecated(`/interests/${userId}`, { headers })
}

export const addUserToIgnoreFolderApi = (userId) => {
  const s_post = cookie.get('s_post')
  return fetchApi5(`/users/${userId}/ignore/`, {
    method: 'POST',
    body: JSON.stringify({
      anketaId: userId,
      s_post,
    }),
  })
}
