import React, { FC, useEffect } from 'react'

import loadable from '@loadable/component'
import { useLocation } from 'react-router'

import { useShallowEqualSelector } from 'common/hooks/useShallowEqualSelector'
import { LandingCommonLoadable } from 'components/landing/LandingCommonLoadable'
import {
  pathsWithMambaLayout,
  unauthorizedRoutes,
} from 'components/layout/allRoutes'
import { authorizedRoutes } from 'components/layout/authorizedRoutes'
import { DropSupportOldBrowserLoadable } from 'components/page/DropSupportOldBrowser/DropSupportOldBrowserLoadable'
import { withLoader } from 'components/system/loadable/withLoader'
import { isIeBrowser } from 'functions/isIeBrowser'
import { removeLocaleFromPath } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useRedesign } from 'hooks/useRedesign'

const MambaLayoutLoadable = loadable(
  () => import('components/layout/MambaLayout/MambaLayoutSwitch'),
  withLoader
)
const OnboardingSwitchLoadable = loadable(
  async () =>
    (await import('components/page/Onboarding/OnboardingSwitch'))
      .OnboardingSwitch
)

export const IndexSwitcher: FC = () => {
  const redesign = useRedesign()

  /**
   * TODO: Не успеваем поменяться путь и достается старое значение,
   * при разблокировке пользователя.
   * (уже rating а отдает user-banned)
   */
  const { pathname } = useLocation()

  const {
    authorized,
    browserName,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { browserName },
    }) => ({ authorized, browserName })
  )

  if (isIeBrowser(browserName!)) {
    return <DropSupportOldBrowserLoadable />
  }

  const url = mergeAllUrls(removeLocaleFromPath(pathname))
  const hasRoute = pathsWithMambaLayout.some((path) => url.indexOf(path) === 0)

  if (redesign && !authorized) {
    return hasRoute ? (
      // Все что не онбоардинг для неавторизованного. В основном нужно для SEO
      <MambaLayoutLoadable routes={unauthorizedRoutes} />
    ) : (
      <OnboardingSwitchLoadable />
    )
  }

  if (authorized) {
    return <MambaLayoutLoadable routes={authorizedRoutes} />
  }

  if (hasRoute) {
    return <MambaLayoutLoadable routes={unauthorizedRoutes} />
  }

  return <LandingCommonLoadable />
}
