import React, { FC, useEffect } from 'react'

import { updateRedirectAfterAuthPath } from 'actions/authorization/updateRedirectAfterAuthPath'
import { useAppDispatch } from 'hooks/useAppDispatch'

import { Replace } from '../Replace'

export const RedirectAfterAuth: FC<{
  redirectAfterAuthPath: string
  uid: string
}> = ({ redirectAfterAuthPath, uid }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Очистим значение, чтобы не попасть в бесконечную петлю
    dispatch(updateRedirectAfterAuthPath(null))
  }, [dispatch])

  return <Replace uid={uid} to={redirectAfterAuthPath} />
}
