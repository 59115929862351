import React, { FC } from 'react'

import { useParams } from 'react-router'

import { LocaleParams } from 'components/components.types'
import { profilePath } from 'components/paths'
import { OldLoginRedirectParams } from 'components/routes/oldLoginRoutes'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const OldLoginRedirect: FC = () => {
  const { locale, profileId } = useParams<
    OldLoginRedirectParams & LocaleParams
  >()

  return (
    <RedirectWithStatus
      uid="old login redirect"
      to={mergeAllUrls(locale, profilePath, profileId)}
      status={301}
    />
  )
}
