import React, { FC } from 'react'

import {
  UniNoticeButtonData,
  UniNoticeSecondaryHandler,
} from 'api/uninotice/uninotice.types'
import {
  primaryUniNoticeLinkType,
  secondaryUniNoticeLinkType,
} from 'common/constants'
import {
  SecondaryButton,
  TransparentButton,
} from 'components/presentational/button'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'

export const UniNoticeLinkWrap: FC<{
  onClick?: UniNoticeSecondaryHandler
  secondaryButton: UniNoticeButtonData
}> = ({
  onClick,
  secondaryButton: {
    title,
    action: { actionId, data },
    type,
  },
}) => {
  if (type === primaryUniNoticeLinkType) {
    return (
      <TransparentButton
        data-name={`uninotice-${actionId}-secondary-action`}
        onClick={() => onClick?.(actionId, data!)}
      >
        <span>{title}</span>
      </TransparentButton>
    )
  }

  if (type === secondaryUniNoticeLinkType) {
    if (actionId === UniNoticeType.external_link) {
      return (
        <SecondaryButton
          data-name={`uninotice-${actionId}-secondary-action`}
          href={data!.link}
        >
          {title}
        </SecondaryButton>
      )
    }

    return (
      <SecondaryButton
        data-name={`uninotice-${actionId}-secondary-action`}
        onClick={() => onClick?.(actionId, data!)}
      >
        <span>{title}</span>
      </SecondaryButton>
    )
  }

  return null
}
