import React from 'react'

import loadable from '@loadable/component'

import { AppRoute } from 'components/components.types'
import { registrationPath } from 'components/page/Boarding/boarding.paths'
import {
  legacyAccessPath,
  legacyGdprPath,
} from 'components/page/Legacy/Legacy.paths'
import { LegacyAccessLoadable } from 'components/page/Legacy/LegacyAccessLoadable'
import {
  AgreementVipPage,
  AgreementVipPageMobile,
  PlainAgreementPage,
} from 'components/page/Legal'
import { restorePasswordPath } from 'components/page/RestorePassword/RestorePasswordLoadable'
import { searchPath } from 'components/page/Search/Search.paths'
import { SettingsRedirect } from 'components/page/Settings/SettingsRedirect'
import {
  agreementIndexPath,
  appAgreementVipAppPath,
  agreementVipPathMobile,
  profilePath,
  ratingPath,
  supportFormExtendPath,
  supportPath,
  indexPath,
} from 'components/paths'
import { LegacyMessengerRedirect } from 'components/system/LegacyMessengerRedirect'
import { LegacyProfileRedirect } from 'components/system/LegacyProfileRedirect'
import { SupportRedirect } from 'components/system/redirect/SupportRedirect'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { RedirectWithStatusAndLocale } from 'components/system/RedirectWithStatusAndLocale'
import { Replace } from 'components/system/Replace'

const LegacyPartnerLinkLoadable = loadable(() =>
  import('components/page/Legacy/LegacyPartnerLink')
)

export const legacySupportRoutes: AppRoute[] = [
  {
    path: agreementVipPathMobile,
    component: AgreementVipPageMobile,
  },
  {
    path: agreementIndexPath,
    component: PlainAgreementPage,
  },
  {
    path: appAgreementVipAppPath,
    component: AgreementVipPage,
  },
  /**
   * Удалил appConfidentialityPath, т.к. из-за этого было не в фоне:
   * https://youtrack.mamba.ru/issue/M-8202
   *
   * appAgreementIndexPath так же была удалена по этой же причине.
   */
  {
    path: supportFormExtendPath,
    component: SupportRedirect,
    shouldCheck: true,
  },
  {
    path: supportPath,
    component: SupportRedirect,
  },
  {
    path: '/rating/parni',
    component: () => <Replace to={ratingPath} uid="/rating/parni" push />,
    shouldCheck: true,
  },
  {
    path: '/my/messages.phtml',
    component: LegacyMessengerRedirect,
    exact: false,
  },
  {
    path: '/search.phtml',
    component: () => <Replace to={searchPath} uid="/search.phtml" />,
  },
  {
    path: '/search/list',
    component: () => (
      <RedirectWithStatusAndLocale
        uid="/search/list"
        to={searchPath}
        status={301}
      />
    ),
  },
  {
    path: profilePath,
    component: LegacyProfileRedirect,
    exact: true,
  },
  {
    path: 'restore_password',
    component: () => (
      <RedirectWithStatus
        status={301}
        to={restorePasswordPath}
        uid="restore_password"
      />
    ),
    exact: false,
  },
  {
    // Не делаем редирект на основные, т.к. иначе не попадем в корень настроек
    path: '/my/edit.phtml',
    component: SettingsRedirect,
  },
  {
    path: '/my/reg.phtml',
    component: () => <Replace to={indexPath} uid="/my/reg.phtml" />,
  },
  {
    path: '/partner/link.phtml',
    component: LegacyPartnerLinkLoadable,
  },
  {
    path: '/link.phtml',
    component: LegacyPartnerLinkLoadable,
  },
  {
    path: legacyAccessPath,
    component: LegacyAccessLoadable,
    exact: false,
  },

  {
    path: legacyGdprPath,
    component: loadable(() =>
      import('components/page/Gdpr/GdprDataDownloadInsruction')
    ),
  },
]
