import {
  fetchLocaleAction,
  fetchPartnerDataAction,
} from 'actions/system/systemAction'
import { AsyncThunkAction } from 'actions/types'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'

export const postLoginAction = (): AsyncThunkAction => (dispatch) => {
  dispatch(fetchMiniIfNeededAction())
  dispatch(fetchPartnerDataAction())
  dispatch(fetchLocaleAction())
}
