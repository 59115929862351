import { fetchQueryApi } from 'api/fetchApi'
import { paths, definitions } from 'api/generated/partner'
import { parameterMainPage, parameterUserPage } from 'common/constants'
import { userProfilePath } from 'components/paths'
import { findMatchUrl } from 'functions/findMatch'

type Method = paths['/partner/page_meta']['get']
export type MetaApiResult = definitions['PageMetaInfo']
type Query = Method['parameters']['query']

export const fetchMetaDataApi = (path: string) => {
  return fetchQueryApi<MetaApiResult, Query>('/partner/page_meta', {
    ...createParameters(path),
  })
}

export const createParameters = (path: string): Query => {
  const userProfileMatch = findMatchUrl(
    path,
    userProfilePath,
    false,
    false,
    false
  )
  const userId =
    userProfileMatch &&
    userProfileMatch.params &&
    userProfileMatch.params.userId

  if (userId) {
    return { page: parameterUserPage, pageParams: userId }
  }

  return { page: parameterMainPage }
}
