import { definitions } from 'api/generated/uniweb'
import { DEFAULT_LOCALE, sPostCookie } from 'common/constants'
import { StartScreen } from 'common/constantsStartScreen'
import { Slot } from 'common/types'
import {
  BrowserList,
  OperationSystem,
  PlatformType,
} from 'common-constants/bowser'
import { Cookies } from 'common-constants/Cookies'
import { OsTypes } from 'common-constants/userAgent'
import { installMobileAppBannerCookie } from 'components/banner/InstallAppBanner/InstallAppBanner.constants'
import { threeFeaturesLandingType } from 'components/landing/landing.constants'
import { modalServerDummy } from 'components/layout/ModalLayout/modalOpen'
import { Api6Platform } from 'functions/createApi6Header'
import { resolveExternalLocaleId } from 'functions/locale'
import { BannerDownloadApp } from 'server/bannerStaticInfo'

export interface CookiesMap {
  appBannerPopUpRulesCookie?: boolean
  start_with_auth_form?: string
  [Cookies.promoServiceAvailable]?: boolean
  [installMobileAppBannerCookie]?: boolean
  [sPostCookie]?: string
}

export interface Language {
  id: number
  code: string
  nativeName: string
  engName: string
  isCyrillic: boolean
  locale: string
  localeWithEnc: string
}

/** @deprecated uniweb/definitions['PageMetaInfo'] */
export interface PageMeta {
  title: string
  description: string
  keywords?: string[]
}

export type FeaturedPhotosPromoX3 =
  | {
      isActive: true
      until: number
      secondsLeft: number
    }
  | {
      isActive: false
      until: null
      secondsLeft: null
    }

export type ServicesInfo = {
  featuredPhotosPromoX3?: FeaturedPhotosPromoX3
  photoline?: {
    enabled: boolean
  }
}

export interface SystemState {
  touch: boolean
  web: boolean
  locale: string
  languageId: number
  rtl: boolean
  partnerId: number
  ip: string
  hostname: string
  query: Record<string, string>
  api6Platform: Api6Platform | null
  api5Platform: number | null
  partner: definitions['PartnerShortInfo'] | null
  referrer?: string
  baseUrl: string
  basePath: string
  contextId: string | null
  secondary: boolean
  cookies: CookiesMap | Record<string, string>
  bannerAppInfo: BannerDownloadApp | null
  osType: OsTypes | null
  startScreen: StartScreen | null
  isWindowsXp: boolean
  isIE: boolean
  isEdge: boolean | null
  isIpad: boolean
  isSafari: boolean | null
  isLandingPage: boolean
  metaLoading: boolean
  statusCode: number
  showNotFound: boolean
  language: null | definitions['Language']
  javaScriptEnabled: boolean
  platformType: PlatformType
  osName: OperationSystem
  osVersion: string | undefined
  ios: boolean | null
  iosVersion: number | null
  meta: definitions['PageMetaInfo'] | null
  staticMeta: definitions['PageMetaInfo'] | null
  shouldReloadOnNextPush: boolean
  landingType: string
  browserName: BrowserList | undefined
  browserVersion: string | undefined
  slots: Slot[] | undefined
  yandexTurboApp: boolean | undefined
  /** Включена ли страница "Лента событий" */
  feedEnabled: boolean
  localeLocked: boolean
  showAdblockBanner: boolean
  clientBundleName: string
  /** Id карты лояльности MnogoCard */
  mnogoCardId: string | null | undefined
  /** настройка доступности сервисов */
  services: ServicesInfo | null

  /** Ключ - номер фичи, значение - details, что является деталью фичи(модификатор, версия и т.п.) */
  features: Partial<Record<number, number>> | null

  redesign: boolean
  animationsDisabled: boolean | null
}

export const initialState: SystemState = {
  language: null, // TODO на legacy web не должно быть редиректа языка
  locale: DEFAULT_LOCALE,
  languageId: resolveExternalLocaleId(DEFAULT_LOCALE),
  rtl: false,
  localeLocked: false,
  partner: null,
  // приходит заголовком из nginx или из query string или берется по умолчанию: 3
  partnerId: 0,
  query: {},
  ip: '',
  hostname: '',
  referrer: '',
  meta: null,
  staticMeta: null,
  web: false,
  touch: false,
  isIE: false,
  osType: null,
  isIpad: false,
  isSafari: null,
  isEdge: null,
  isWindowsXp: false,
  cookies: {},
  showAdblockBanner: false,
  landingType: threeFeaturesLandingType,
  bannerAppInfo: null,
  javaScriptEnabled: true,
  isLandingPage: false,
  contextId: null,
  baseUrl: modalServerDummy,
  basePath: '',
  startScreen: null,
  platformType: PlatformType.Desktop,
  osName: OperationSystem.Windows,
  browserName: BrowserList.Chrome,
  browserVersion: '',
  clientBundleName: process.env.CLIENT_BUNDLE_NAME,
  shouldReloadOnNextPush: false,
  secondary: true,
  osVersion: '',
  ios: null,
  iosVersion: null,
  metaLoading: false,
  statusCode: 200,
  showNotFound: false,
  slots: [],
  feedEnabled: true,
  api6Platform: null,
  api5Platform: null,
  yandexTurboApp: false,
  mnogoCardId: null,
  services: null,
  features: null,
  redesign: true,
  animationsDisabled: false,
}
