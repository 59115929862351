import React from 'react'

import { useParams } from 'react-router'

import { userProfileAlbumPartialPath } from 'components/page/UserProfile/UserProfileAlbum/UserProfileAlbum.paths'
import { ProfileRouteParams } from 'components/paths'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const profileAlbumRedirectPath =
  '/users/:userId(\\d+)/albums/:albumId(\\d+)'

export const ProfileAlbumRedirect = () => {
  const { userId } = useParams<ProfileRouteParams>()

  return (
    <RedirectWithStatus
      status={301}
      to={mergeAllUrls(userProfileAlbumPartialPath, userId)}
      uid="ProfileAlbumRedirect"
    />
  )
}
