import React, { FC } from 'react'

import loadable from '@loadable/component'
import { useRouteMatch } from 'react-router-dom'

import { mambaLayoutContextId } from 'components/layout/MambaLayout/Context'
import { CreateBaseUrl } from 'components/layout/MambaLayout/CreateContext'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ConfidentialityLoadable = loadable(
  () => import('./ConfidentialityIndex'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Confidentiality split error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)

export const ConfidentialityPage: FC = () => {
  const match = useRouteMatch()

  return (
    <CreateBaseUrl
      uid="confidentiality"
      baseUrl={match.url}
      basePath={match.path}
      contextId={mambaLayoutContextId}
    >
      <ConfidentialityLoadable />
    </CreateBaseUrl>
  )
}
