import styled, { css } from 'styled-components'

import { Colors } from 'common-constants/colors'
import { flexBoxGrowCss } from 'common-constants/flex'
import { HeaderHeight } from 'common-constants/header.height'
import {
  desktopModalPaddingSize,
  media,
  mobileModalPaddingSize,
} from 'components/presentational'
import { linkDefaultCss } from 'components/presentational/link'
import { ViewContent } from 'components/presentational/modal'

export const phoneIconSize = 80

const viewContentNoticeCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: ${desktopModalPaddingSize}px;
  border-radius: 10px;

  ${media.phone`    
    padding: ${mobileModalPaddingSize}px;    
  `};
`
/**
 * Поставил везде flex-grow: 1, не только на телефоне.
 * https://youtrack.mamba.ru/issue/M-6200
 *
 * Выглядит логично, не понятно почему раньше было по-другому.
 */
export const viewContentInlineCss = css`
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
  flex-grow: 1;

  ${viewContentNoticeCss};

  ${media.phone`
    padding: ${mobileModalPaddingSize}px;
  `};
`

/**
 * Удалил минимальную высоту 530, т.к. в рейтинге не вмещается кнопка.
 * https://youtrack.mamba.ru/issue/M-5857
 */
export const ViewContentInlineNotice = styled(ViewContent)`
  ${viewContentInlineCss};
`

/**
 * https://redmine.mamba.ru/issues/113327
 * Нужен notice без min-height
 * зачем flex-grow растянется, а в photorating ограничение по высоте 800px
 */
export const ViewContentInlineNoticePhotoRating = styled(ViewContent)`
  ${viewContentInlineCss};
  flex-grow: 1;
  background-color: ${Colors.white};
  width: 100%;
  box-sizing: border-box;
`

export const ViewContentModalNotice = styled(ViewContent)`
  ${viewContentNoticeCss};
`

export const Image = styled.img<{ $borderRadius?: string }>`
  max-width: 100%;
  max-height: 100%;
  border-radius: ${(props) =>
    props.$borderRadius ? props.$borderRadius : '0'};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`

export const ImageContainer = styled.div`
  margin: 0 0 20px 0;
  text-align: center;

  // https://redmine.mamba.ru/issues/113414
  @media screen and (max-height: 800px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`

export const ImageInner = styled.div`
  display: flex;
  width: 124px;
  height: 124px;
  margin: auto;

  // https://redmine.mamba.ru/issues/113414
  @media screen and (max-height: 800px) and (orientation: landscape) {
    width: ${phoneIconSize}px;
    height: ${phoneIconSize}px;
  }

  ${media.phone`
    width: ${phoneIconSize}px;
    height: ${phoneIconSize}px;
  `};
`

export const FooterNote = styled.div`
  margin: 20px 0 30px 0;
  font-size: 11px;
  line-height: 1.27;
  text-align: center;
  color: ${Colors.noteColor};

  a {
    text-decoration: none;
    color: ${Colors.link};
  }
`

export const ImageContainerComponent = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  flex-shrink: 0;
`

export const InlineViewAligner = styled.div`
  max-width: 400px;
  padding: 0 20px;
  text-align: center;
`

const cssTextMaxWidth = css`
  max-width: 325px;
  width: 100%;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: ${(props) => props.color};
  margin: 0 0 20px 0;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  // https://redmine.mamba.ru/issues/113414
  @media screen and (max-height: 800px) and (orientation: landscape) {
    margin-bottom: 10px;
  }
`

export const Text = styled.div`
  font-size: 13px;
  line-height: 1.31;
  color: ${(props) => props.color};

  // Приходится задавать default для legacyWeb чтобы появились стили
  ul {
    padding: 0;
    margin: 1em 0;
  }

  ul li {
    margin: 3px 0;
    list-style-type: disc;
    list-style-position: inside;
  }

  a {
    ${linkDefaultCss};
  }
`

const flexBoxCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  ${media.phone`
    flex-grow: 1;        
  `};
`

export const FlexBox = styled.div`
  ${flexBoxCss};
`

export const FlexBoxInline = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`

export const FlexBoxModal = styled.div`
  ${flexBoxCss};

  ${media.phone`                       
    height: calc(var(--vh, 1vh) * 100 - ${HeaderHeight.common}px);
`};
`

export const FlexChild = css`
  display: flex;
  flex-direction: column;
  ${cssTextMaxWidth};
`

export const FlexChildContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  ${cssTextMaxWidth};

  ${media.phone`
    padding: ${mobileModalPaddingSize}px 0;
  `}
`

export const FlexChildContentModal = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

  ${media.phone`    
    padding: ${mobileModalPaddingSize}px 0;
  `};
`

const FlexChildButtonCss = css<{ $isUseGradient?: boolean }>`
  position: relative;
  margin: 20px 0 0 0;

  &::before {
    display: none;
    position: absolute;
    content: '';
    height: 15px;
    width: 100%;
    left: 0;
    bottom: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  }

  ${media.phone<{ $isUseGradient?: boolean }>`     
    margin-top: 5px;
    
    &::before{
       display: ${(props) => (props.$isUseGradient ? 'block' : 'none')}
    }
  `};
`

export const FlexChildButton = styled.div<{ $isUseGradient?: boolean }>`
  ${FlexChild};
  ${FlexChildButtonCss};
`

export const FlexChildButtonModal = styled.div<{ $isUseGradient: boolean }>`
  display: flex;
  flex-direction: column;
  ${FlexChildButtonCss};
  width: 100%;
`

export const FlexChildInlineButton = styled.div`
  margin-top: 20px;

  ${media.phone`
    margin-top: 10px;
  `};
`

export const ViewContentInlineNoticeCss = css`
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  flex-grow: 0;

  ${media.phone`
    flex-grow: 1;
  `};

  ${viewContentNoticeCss};
`

export const ViewContentInlineFlexFull = styled(ViewContent)`
  ${ViewContentInlineNoticeCss};
  flex-grow: 1;
`

/**
 * В любом случае растягиваем по высоте.
 * Раньше было только для мобилок.
 */
export const BoxNotice = styled.div`
  ${flexBoxGrowCss}
`
