import React, { FC } from 'react'

import { useAddClientRoutingToServerHtml } from 'hooks/useAddClientRoutingToServerHtml'

import { FooterNote } from './UniNoticeView.styled'

export const UniNoticeFooter: FC<{ htmlString: string }> = ({ htmlString }) => {
  const { setRef } = useAddClientRoutingToServerHtml()

  return (
    <FooterNote
      ref={setRef}
      dangerouslySetInnerHTML={{ __html: htmlString }}
      data-name="uninotice-footer"
    />
  )
}
