import { AsyncAction } from 'actions/actions.types'
import { paths as user_settings } from 'api/generated/user_settings'
import { messageSoundEnabledApi } from 'api/settings/settingsApi'

export const FETCH_SOUND_NOTIFICATION_STATUS = 'FETCH_SOUND_NOTIFICATION_STATUS'

export interface IsEnabledSoundNotificationAction
  extends AsyncAction<
    user_settings['/settings/incoming_messages_sound/enabled']['get']['responses']['200']['schema']
  > {
  type: typeof FETCH_SOUND_NOTIFICATION_STATUS
}

export const isEnabledSoundNotificationAction = () => ({
  type: FETCH_SOUND_NOTIFICATION_STATUS,
  promise: () => messageSoundEnabledApi(),
})
