import {
  ADD_CLIENT_STATISTIC,
  SAVE_CLIENT_STATISTIC,
  ClientStatisticTypes,
} from 'actions/clientStatisticAction'
import { ClientTimer } from 'api/clientStatisticApi'

export interface ClientStatisticState {
  timers: ClientTimer[]
}

const initialState: ClientStatisticState = {
  timers: [],
}

export const clientStatisticReducer = (
  state = initialState,
  action: ClientStatisticTypes
): ClientStatisticState => {
  switch (action.type) {
    case ADD_CLIENT_STATISTIC:
      return {
        ...state,
        timers: [...state.timers, action.payload],
      }
    case SAVE_CLIENT_STATISTIC:
      if (action.ready) {
        return {
          ...state,
          timers: [],
        }
      }
      return state
    default:
      return state
  }
}
