import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const RegistrationLoadable = loadable(() => import('./Registration'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Registration split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
