import React, { useEffect } from 'react'

import { isLoveMailru } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const counterId: number = 12470
const typeGoal = 'reachGoal'
const typeSetUserId = 'setUserID'
const nameGoalRegistration = 'mt_registration'
const nameGoalLogin = 'mt_login'

const createEventId = (
  userId: number | null,
  typeGoal: string,
  nameGoal?: string
) => {
  const dataSend = { id: counterId, type: typeGoal, userid: userId }

  if (typeGoal === typeSetUserId) {
    return dataSend
  }
  return {
    ...dataSend,
    goal: nameGoal,
  }
}

export const UpdateSlots = () => {
  const { authorized, registrated, userId } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized, registrated },
      userReducer: { userId },
    }) => ({
      authorized,
      userId,
      registrated,
    })
  )

  /**
   * https://redmine.mamba.ru/issues/105006#note-21
   * надо выяснить если ли данный счетчик на love.mail.ru
   */
  useEffect(() => {
    const _tmr = window._tmr || (window._tmr = [])
    if (registrated || authorized) {
      _tmr.push(createEventId(userId, typeSetUserId))
    }
    if (registrated) {
      _tmr.push(createEventId(userId, typeGoal, nameGoalRegistration))
    }
    if (authorized) {
      _tmr.push(createEventId(userId, typeGoal, nameGoalLogin))
    }
  }, [authorized])

  return null
}
