import React, { FC } from 'react'

import { indexPath, profilePath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const LegacyProfileRedirect: FC = () => {
  const { authorized, profileId } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized, profile } }) => ({
      authorized,
      profileId: profile?.id,
    })
  )

  // profileId нет, например, в случае пользователя авторизованного, но не успевшего принять GDPR
  if (authorized && profileId) {
    return (
      <Replace
        to={mergeAllUrls(profilePath, profileId)}
        uid="LegacyProfileRedirect-authorized"
      />
    )
  }

  return <Replace to={indexPath} uid="LegacyProfileRedirect" />
}
