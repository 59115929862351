import { internalApiPath, InternalPath } from 'api/internal/internal.constants'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { buildFullQuery } from 'functions/queryString'

export interface InternalError {
  error: boolean
}

export const fetchInternalApi = async <Q, R>(
  url: InternalPath,
  query?: Q,
  options: RequestInit = {}
): Promise<R | InternalError> => {
  try {
    const response = await fetch(
      `${mergeAllUrls(internalApiPath, url)}${buildFullQuery(query)}`,
      options
    )

    const json = await response.json()

    return json
  } catch (error) {
    console.error(`Internal API error ${url}`, error)
    return { error: true }
  }
}
