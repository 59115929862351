import React, { FC, useEffect, useMemo } from 'react'

import { ApolloClient, ApolloProvider, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { withProfiler } from '@sentry/react'
import { History } from 'history'
import cookie from 'js-cookie'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import { checkCookiesAction } from 'actions/system/checkCookiesAction'
import { createApolloHeaders, createApolloUrl } from 'api/apollo/createApollo'
import { createInMemoryCache } from 'api/apollo/createInMemoryCache'
import { errorLink } from 'api/apollo/errorLink'
import { sPostCookie } from 'common/constants'
import { App } from 'components/App'
import { handleIntlError } from 'functions/handleIntlError'
import { resolveDeviceId } from 'functions/localStorage'

import { internalLocale } from './client'
import { store } from './configureClientStore'

// #_=_ приходит после верификации через фейсбук и приводит в разнице при запуске роутера
// в итоге добавляется событие PUSH, которое ломает навигацию назад
if (location.hash === '_=_') {
  location.hash = ''
}

const resolveToken = (): string | null => {
  const sPost = cookie.get(sPostCookie)

  if (!sPost) {
    // Не будем жестко падать
    console.error(`Csrf-Token from s_post is not defined "${sPost}".`)
    return null
  }

  return sPost
}

export const InternalStart: FC<{ history: History }> = ({ history }) => {
  const deviceId = resolveDeviceId()

  /**
   * Нужно для обновление s_post, токена Csrf-Token
   * на каждый запрос.
   * https://redmine.mamba.ru/issues/116221
   */
  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            ['Csrf-Token']: resolveToken(),
          },
        }
      }),
    []
  )

  useEffect(() => {
    store.dispatch(checkCookiesAction())
  }, [])

  if (!cookie.get(sPostCookie)) {
    // Просто ничего не покажем, пока не обновим куку
    console.error('Can not render: cookie not found: s_post')
    return null
  }

  const httpLink = new HttpLink({
    uri: createApolloUrl(window.__INITIAL_STATE__.systemReducer.locale),
    credentials: 'same-origin',
    headers: createApolloHeaders(
      resolveToken(),
      JSON.stringify(window.API_6_CLIENT),
      deviceId
    ),
  })

  const client = new ApolloClient({
    cache: createInMemoryCache().restore(window.__APOLLO_STATE__),
    link: from([errorLink(store), authLink, httpLink]),
    ssrForceFetchDelay: 150,
  })

  const SentryApp = withProfiler(App)

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <IntlProvider
          locale={internalLocale}
          messages={window.__MESSAGES__}
          onError={handleIntlError}
        >
          <Router history={history}>
            <SentryApp store={store} history={history} />
          </Router>
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  )
}
