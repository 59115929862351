import React, {
  createContext,
  cloneElement,
  useEffect,
  FC,
  ChangeEventHandler,
  FormEvent,
  DetailedReactHTMLElement,
  HTMLAttributes,
  PropsWithChildren,
} from 'react'

import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import {
  makeDirtyAction,
  resetFormAction,
  toggleValidateOnSubmitAction,
} from 'actions/formAction'
import { WithClassName, WithStyle } from 'common/types'
import { Colors } from 'common-constants/colors'
import { useIsFormDirty } from 'components/presentational/Form/Form.hooks'
import {
  FormHandler,
  handleBlur,
  handleInvalid,
} from 'components/presentational/Form/From.functions'

import { media } from '.'
import { Dirty } from './input'

export const FormContext = createContext('defaultFormId')
export const { Provider, Consumer } = FormContext

export const FormElement = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  ${media.phone`
    flex-grow: 1;
  `};
`

/**
 * Обычный SPA-submit
 * @param event
 * @param onSubmit
 */
const handleSubmit = (
  event: FormEvent<HTMLFormElement>,
  onSubmit: Function
) => {
  event.preventDefault()

  if ((event.target as HTMLFormElement).checkValidity()) {
    onSubmit(event)
  }
}

/**
 * Обработка формы с полным редиректом
 * @param event
 * @param onSubmit
 */
const handleActionSubmit = (
  event: FormEvent<HTMLFormElement>,
  onSubmit?: Function
) => {
  if ((event.target as HTMLFormElement).checkValidity()) {
    onSubmit?.()
  } else {
    event.preventDefault()
  }
}

export const Form: FC<
  {
    id: string
    onSubmit?: Function
    action?: string
    autoComplete?: string
  } & WithClassName &
    WithStyle &
    PropsWithChildren
> = ({ id, className, children, onSubmit, action, ...rest }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(toggleValidateOnSubmitAction(id, true))
    return () => {
      dispatch(resetFormAction(id))
    }
  }, [dispatch, id])

  if (action) {
    return (
      <FormElement
        className={className}
        data-name={id}
        method={'POST'}
        action={action}
        onSubmit={(event: FormEvent<HTMLFormElement>) =>
          handleActionSubmit(event, onSubmit)
        }
        {...rest}
      >
        <Provider value={id}>{children}</Provider>
      </FormElement>
    )
  }

  return (
    <FormElement
      className={className}
      onSubmit={(event: FormEvent<HTMLFormElement>) =>
        handleSubmit(event, onSubmit!)
      }
      data-name={id}
      method={'POST'}
      {...rest}
    >
      <Provider value={id}>{children}</Provider>
    </FormElement>
  )
}

export const Field = styled.div<{ flexGrow?: number; justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;

  flex-grow: ${(props) => props.flexGrow};
  justify-content: ${(props) => props.justifyContent};
`
export interface FormNoteProps {
  size?: number
}
export const FormNote = styled.div<FormNoteProps>`
  margin-top: 4px;
  color: ${Colors.noteColor};
  font-size: ${(props) => props.size}px;
  line-height: 1.27;
  cursor: default;
`
FormNote.defaultProps = {
  size: 11,
}

export const CheckBoxLoginFormWrapper = styled.div`
  margin: 8px 0;
`

export const resolveFormFieldValue = (
  // target: HTMLInputElement | HTMLSelectElement | EventTarget
  // TODO пока так, так как куча ошибок типов
  target: any
) => {
  const {
    dataset = { name: null },
    name,
    type,
    value,
  } = target as HTMLInputElement
  const newValue =
    type === 'checkbox' ? (target as HTMLInputElement).checked : value
  const dataName = dataset.name
  return { name, value: newValue, dataName }
}

export const ValidatableElement: FC<
  {
    name: string
    onInvalid?: FormHandler
    onBlur?: ChangeEventHandler<HTMLTextAreaElement>
  } & PropsWithChildren
> = ({ name, children, onInvalid, onBlur }) => (
  <Consumer>
    {(formId) => (
      <Element
        formId={formId}
        name={name}
        onInvalid={onInvalid!}
        onBlur={onBlur!}
      >
        {children}
      </Element>
    )}
  </Consumer>
)

const Element: FC<
  {
    formId: string
    name: string
    onInvalid: FormHandler
    onBlur: (event: unknown) => void
  } & PropsWithChildren
> = ({
  formId,
  name,
  children,
  onInvalid = handleInvalid,
  onBlur = handleBlur,
}) => {
  const dispatch = useDispatch()
  const dirty = useIsFormDirty(name)

  if (dirty) {
    return (
      <Dirty>
        {cloneElement(
          (children as unknown) as DetailedReactHTMLElement<
            HTMLAttributes<unknown>,
            HTMLFormElement
          >,
          {
            name,
            onInvalid,
          }
        )}
      </Dirty>
    )
  }

  const handleMarkDirty = (event: FormEvent) => {
    event.preventDefault()
    dispatch(makeDirtyAction(formId, name))
    onBlur(event)
  }

  return (
    <>
      {cloneElement(
        (children as unknown) as DetailedReactHTMLElement<
          HTMLAttributes<unknown>,
          HTMLFormElement
        >,
        {
          name,
          onBlur: handleMarkDirty,
          onInvalid: (event: FormEvent) => {
            dispatch(toggleValidateOnSubmitAction(formId, false))
            handleMarkDirty(event)
            onInvalid(event)
          },
        }
      )}
    </>
  )
}

export const FormAction = styled.div<{ margin?: string }>`
  margin-top: 20px;
  // margin-bottom: 20px;

  ${(props) => props.margin && `margin: ${props.margin} `};

  &:empty {
    display: none;
  }
`
