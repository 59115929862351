import { definitions } from 'api/generated/photo'
import { ChatMessagePhoto } from 'reducers/messenger/chatMessage.types'

export const mapAttachPhotos = (
  photos: ChatMessagePhoto[]
): definitions['OmniAlbumPhoto'][] => {
  return photos.map(({ photoId, hugePhotoUrl }) => {
    return {
      photoId,
      urls: { huge: hugePhotoUrl },
      /**
       * TODO: Пока оставил так, надо будет сделать свою модель
       * только с нужными полями
       */
    } as definitions['OmniAlbumPhoto']
  })
}
