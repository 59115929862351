import React, { FC, ReactNode } from 'react'

import {
  UniNoticeButtonData,
  UniNoticePrimaryHandler,
  UniNoticeSecondaryHandler,
} from 'api/uninotice/uninotice.types'
import { VoidHandler } from 'common/types'
import { UniNoticeButtonSwitcher } from 'components/uninotice/button/UniNoticeButtonSwitcher'
import { UniNoticeLinkWrap } from 'components/uninotice/UniNoticeLinkWrap'

import { UniNoticeFooter } from './UniNoticeFooter'

interface UniNoticeButtonProps {
  onPrimaryClick?: UniNoticePrimaryHandler
  onSecondaryClick?: UniNoticeSecondaryHandler
  primaryComponent?: ReactNode
  secondaryComponent?: ReactNode
  primaryButton?: UniNoticeButtonData
  secondaryLink?: UniNoticeButtonData
  secondaryText?: string
}

export const UniNoticeButtonColumn: FC<UniNoticeButtonProps> = ({
  primaryButton,
  primaryComponent,
  secondaryComponent,
  secondaryLink,
  secondaryText,
  onPrimaryClick,
  onSecondaryClick,
}) => (
  <>
    {primaryButton && (
      <UniNoticeButtonSwitcher
        onClick={onPrimaryClick}
        buttonProperties={primaryButton}
      />
    )}
    {primaryComponent}
    {secondaryLink && (
      <UniNoticeLinkWrap
        onClick={onSecondaryClick}
        secondaryButton={secondaryLink}
      />
    )}
    {secondaryComponent}
    {secondaryText && <UniNoticeFooter htmlString={secondaryText} />}
  </>
)
