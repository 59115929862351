import { AppRoute } from 'components/components.types'

import { promoCodeAcceptedPath, promoCodePath } from './promoCode.paths'
import { PromoCodeAcceptedLoadable } from './PromoCodeAcceptedLoadable'
import { PromoCodeLoadable } from './PromoCodeLoadable'

export const promoCodeRoutes: AppRoute[] = [
  {
    path: promoCodePath,
    component: PromoCodeLoadable,
    exact: false,
  },
  {
    path: promoCodeAcceptedPath,
    component: PromoCodeAcceptedLoadable,
    exact: false,
  },
]

export const promoCodeAuthorizedRoutes: AppRoute[] = promoCodeRoutes.map(
  (route) => ({ ...route, authorizedOnly: true })
)
