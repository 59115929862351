import React, { FC } from 'react'

import { Location } from 'history'
import { useRouteMatch } from 'react-router'

import {
  indexPath,
  profilePath,
  ProfileRouteParams,
  userProfilePath,
} from 'components/paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useLocale } from 'hooks/useLocale'

import { Replace } from './Replace'

export const UnauthorizedRedirect: FC<{ uid: string }> = ({ uid }) => {
  const locale = useLocale()
  const match = useRouteMatch<ProfileRouteParams>(
    mergeAllUrls(locale, userProfilePath)
  )

  if (match?.params) {
    return (
      <Replace
        to={mergeAllUrls(locale, profilePath, match.params.userId)}
        uid={uid}
      />
    )
  }

  /** Убрал from, т.к. типизация говорит об ошибки, по-идее не нужно */
  return <Replace to={indexPath} uid={uid} />
}
