import { captureException } from '@sentry/browser'

import { fetchQueryApi } from 'api/fetchApi'
import { definitions, paths } from 'api/generated/uniweb'
import { NodeHeaders } from 'api/NodeHeaders'
import { encodeUtmList } from 'api/statisticApi'
import { createQueryMap } from 'functions/http/createQueryMap'
import { StatisticsValue } from 'server/functions/statisticsDataQuery'

import { defaultNodeOptions, fetchApi } from '../index'

export const partnerDataApi = (locale: string, headers = {}) =>
  fetchApi(`/partner/short_info?&_loc[locale]=${locale}`, {
    ...defaultNodeOptions,
    headers,
  } as RequestInit)

export const sendCubeStatApi = (testName: string, testGroup: string) =>
  fetchApi('/cubstat/abtest_user', {
    method: 'POST',
    body: JSON.stringify({
      testName,
      testGroup,
    }),
  })

type Method = paths['/bootstrap']['get']

export const bootstrapApi = (
  localeFromPath: string,
  utmList: StatisticsValue[],
  headers: NodeHeaders,
  path: string,
  yandexTurbo: boolean
) => {
  const parameters = createQueryMap(path)
  const values: StatisticsValue[] = [...parameters, ...encodeUtmList(utmList)]

  const query = values.reduce(
    (acc, { name, value }) => ({ ...acc, [name]: value }),
    {}
  ) as Method['parameters']['query']

  if (localeFromPath) {
    /** Проставляем локаль для первого запроса */
    headers['X-ApiLocale'] = localeFromPath
  }
  if (yandexTurbo) {
    // Не правильно описано в пыхе
    // @ts-ignore
    query['slots[]'] = 'YandexApp'
  }

  query['pageUrl'] = path

  try {
    return fetchQueryApi<
      definitions['BootstrapContainer'],
      Method['parameters']['query']
    >('/bootstrap' as keyof paths, { ...query }, { headers })
  } catch (error) {
    console.error('Bootstrap API error', error)
    captureException(error)
    return Promise.resolve({
      result: undefined,
      headers: {},
    })
  }
}
