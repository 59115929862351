import { AsyncThunkAction } from 'actions/types'
import { displayUniNoticeAction } from 'actions/uninotice/displayUniNoticeAction'
import { decodeUniNotice } from 'components/uninotice/function/serialization'
import { serverUniNoticeParameter } from 'components/uninotice/UniNotice.constants'

export const insertUniNoticeFromQueryAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    systemReducer: {
      query: { [serverUniNoticeParameter]: rawNotice },
    },
  } = getState()

  if (rawNotice) {
    const notice = decodeUniNotice(rawNotice)

    if (notice) {
      dispatch(displayUniNoticeAction(notice))
    }
  }
}
