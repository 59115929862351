import React, { FC, useEffect, useState } from 'react'

import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { Logo } from './Logo'
import { WavesSvg } from './WavesSvg'

export const SplashScreen: FC<WithChildren> = ({ children }) => {
  const { javaScriptEnabled, animationsDisabled } = useShallowEqualSelector(
    ({ systemReducer: { javaScriptEnabled, animationsDisabled } }) => ({
      javaScriptEnabled,
      animationsDisabled,
    })
  )
  const redesign = useRedesign()
  const [ready, setReady] = useState(false)
  const [show, setShow] = useState(!animationsDisabled)

  useEffect(() => {
    if (!redesign) {
      return
    }

    // Перестрахуемся на случай, если будем ждать DOMContentLoaded, но он не вызовется.
    // Подождем максимум 2 секунды и покажем контент.
    const timer = window.setTimeout(() => {
      setReady(true)
    }, 2000)

    const handleLoad = () => {
      setReady(true)
      clearTimeout(timer)
    }

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleLoad)
    } else {
      handleLoad()
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleLoad)
    }
  }, [redesign])

  const onAnimationEnd = () => {
    setShow(false)
  }

  // На данный момент это, как минимум, страница профиля для неавторизованного
  if (!javaScriptEnabled) {
    return children
  }

  return (
    <>
      {children}
      {redesign && !animationsDisabled && show && (
        <Logo ready={ready}>
          {ready && (
            <Content>
              <SplashAnimation onAnimationEnd={onAnimationEnd}>
                <StyledWavesSvg />
              </SplashAnimation>
            </Content>
          )}
        </Logo>
      )}
    </>
  )
}

const Content = styled.div`
  height: 200%;
  width: 200%;
  transform: translateY(25%);
`
const StyledWavesSvg = styled(WavesSvg)`
  position: relative;
`
const SplashAnimation = styled.div`
  @keyframes splash {
    0% {
      transform: translate(10%, 10%) translateZ(0);
    }
    50% {
      transform: translate(10%, -45%) rotate(115deg) scale(2) translateZ(0);
    }
    100% {
      transform: translate(10%, -100%) rotate(230deg) scale(4) translateZ(0);
    }
  }

  will-change: transform;
  animation-duration: 2s;
  animation-name: splash;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
`
