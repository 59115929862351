import React, { FC } from 'react'

import { TeamoUniNoticeLink } from 'components/uninotice/TeamoUniNoticeLink'
import { UniNoticeType } from 'components/uninotice/UniNotice.constants'

export const UniNoticeButtonActionIdCustomSwitcher: FC<{
  actionId: UniNoticeType
  title: string
}> = ({ actionId, title }) => {
  switch (actionId) {
    case UniNoticeType.login_to_teamo:
      return <TeamoUniNoticeLink title={title} />

    default:
      return null
  }
}
