import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const ShortcutListLoadable = loadable(
  async () => (await import('./ShortcutList')).ShortcutList,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Shortcut split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
