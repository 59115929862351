import React from 'react'

import { AppRoute } from 'components/components.types'
import { OldAutoPortraitRedirect } from 'components/system/redirect/OldAutoPortretRedirect'
import { OldLoginRedirect } from 'components/system/redirect/OldLoginRedirect'

export interface OldLoginRedirectParams {
  profileId: string
}

/**
 * Корневые роуты для старого логина
 * Важно перехватить в конце, когда другие варианты испробованы
 */
export const oldLoginRoutes: AppRoute[] = [
  {
    path: '/mb:profileId([0-9]+)/albums',
    component: OldLoginRedirect,
    exact: true,
  },
  {
    path: '/mb:profileId([0-9]+)/self',
    component: OldAutoPortraitRedirect,
    exact: true,
  },
  {
    path: '/mb:profileId([0-9]+)',
    component: OldLoginRedirect,
    exact: true,
  },
  // Пока оставляю, после тестов и релиза надо будет подчистить.
  // {
  //   path: '/:login',
  //   component: () => <div>Login 2</div>,
  //   exact: true,
  // },
]

/**
 * Модальные роуты для старого логина.
 * Важно перехватить в конце, когда другие варианты испробованы.
 * Пока оставляю, после тестов и релиза надо будет подчистить.
 */
export const oldLoginModalRoutes: AppRoute[] = [
  {
    path: '/:login',
    component: () => <div>Login</div>,
    exact: true,
  },
]
