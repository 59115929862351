import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { clientLogoutAction } from 'actions/authorization/logoutAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

/**
 * https://redmine.mamba.ru/issues/120333
 * При сбросе пароля, или каких-то других ситуациях
 * Авторизованный пользователь может получить ошибку auth
 * Это значит что сервер считает данного пользователя неавторизованным.
 * Поэтому нам надо выполнить на клиенте logout без запроса к серверу.
 * И привести состояние системы, как для неавторизованного пользователя.
 */
export const useIsAuthorizedResetByServer = () => {
  const { isAuthorizedResetByServerNeedLogout } = useShallowEqualSelector(
    ({ errorReducer: { isAuthorizedResetByServerNeedLogout } }) => ({
      isAuthorizedResetByServerNeedLogout,
    })
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuthorizedResetByServerNeedLogout) {
      dispatch(clientLogoutAction())
    }
  }, [dispatch, isAuthorizedResetByServerNeedLogout])
}
