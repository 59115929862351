import { PhoneVerificationCodeResendAction } from 'actions/settings/phoneVerificationCodeResendAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

import { SettingsReducerState } from './SettingsReducerState'

export const getPhoneVerificationCodeResendCaseHandler = (
  state: SettingsReducerState,
  action: PhoneVerificationCodeResendAction
): SettingsReducerState => {
  return defaultPromiseReducer(
    state,
    action,
    (): Partial<SettingsReducerState> => ({
      settingsLoading: true,
      errorCode: null,
    }),
    (): Partial<SettingsReducerState> => ({
      settingsLoading: false,
    }),
    (): Partial<SettingsReducerState> => ({
      settingsLoading: false,
      errorCode: action.error!.code,
    })
  )
}
