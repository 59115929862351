import React, { FC } from 'react'

import { useParams } from 'react-router'

import { searchPath } from 'components/page/Search/Search.paths'
import { profilePath } from 'components/paths'
import { OldLoginRedirectParams } from 'components/routes/oldLoginRoutes'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useAuthorized } from 'hooks/useAuthorized'

export const OldAutoPortraitRedirect: FC = () => {
  const { profileId } = useParams<OldLoginRedirectParams>()
  const authorized = useAuthorized()

  if (authorized) {
    return (
      <Replace
        to={mergeAllUrls(profilePath, profileId)}
        uid="auto portrait redirect"
      />
    )
  }

  return <Replace to={searchPath} uid="auto portrait redirect" />
}
