import React, { FC } from 'react'

import {
  UniNoticeButtonData,
  UniNoticeSecondaryHandler,
} from 'api/uninotice/uninotice.types'
import {
  primaryUniNoticeLinkType,
  primaryUniNoticeType,
  secondaryUniNoticeType,
} from 'common/constants'
import { Button, OrdinaryButton } from 'components/presentational/button'
import {
  uniNoticeIdTypesLink,
  UniNoticeType,
} from 'components/uninotice/UniNotice.constants'
import { UniNoticeButtonActionIdCustomSwitcher } from 'components/uninotice/UniNoticeButtonActionIdCustomSwitcher'

import { PrimaryLink } from './PrimaryLink'

export const UniNoticeButtonSwitcher: FC<{
  onClick?: UniNoticeSecondaryHandler
  buttonProperties: UniNoticeButtonData
}> = ({
  onClick,
  buttonProperties: { title, action: { actionId, data } = {}, type },
}) => {
  const handleClick = () => onClick?.(actionId!, data!)

  if (type === primaryUniNoticeType) {
    return (
      <Button onClick={handleClick} data-name={`uninotice-${actionId}-action`}>
        {title}
      </Button>
    )
  }

  if (
    type === secondaryUniNoticeType &&
    uniNoticeIdTypesLink.includes(actionId as UniNoticeType)
  ) {
    return (
      <UniNoticeButtonActionIdCustomSwitcher
        actionId={actionId as UniNoticeType}
        title={title}
      />
    )
  }

  if (type === secondaryUniNoticeType) {
    return (
      <OrdinaryButton
        onClick={handleClick}
        data-name={`uninotice-${actionId}-action`}
      >
        {title}
      </OrdinaryButton>
    )
  }

  if (type === primaryUniNoticeLinkType) {
    return (
      <PrimaryLink
        actionId={actionId!}
        title={title}
        data={data!}
        onClick={onClick!}
      />
    )
  }

  console.warn(`UniNotice type not found for "${type}"`)
}
