import { AsyncAction } from 'actions/actions.types'
import { LogoutAction } from 'actions/authorization/logoutAction'
import {
  fetchAbTestsGroupsListApi,
  AbTestName,
  fetchAbTestsGroupApi,
  fetchAbTestsMyGroupApi,
} from 'api/abTestApi'
import { Api6NodeHeaders } from 'api/createApiNodeHeaders'
import { definitions } from 'api/generated/abtests'

export const FETCH_AB_TEST_GROUP_LIST = 'FETCH_AB_TEST_GROUP_LIST' as const

export interface FetchAbTestGroupListAction
  extends AsyncAction<definitions['AbTestsGroupsList']> {
  type: typeof FETCH_AB_TEST_GROUP_LIST
}

export const fetchAbTestsGroupListAction = (
  testNames: AbTestName[],
  headers?: Api6NodeHeaders
) => ({
  type: FETCH_AB_TEST_GROUP_LIST,
  promise: () => fetchAbTestsGroupsListApi(testNames, headers),
})

export const fetchAbTestsMyGroupPlainAction = (
  testName: AbTestName,
  headers?: Api6NodeHeaders
) => ({
  type: FETCH_AB_TEST_GROUP,
  promise: () => fetchAbTestsMyGroupApi(testName, headers),
  testName,
})

export const FETCH_AB_TEST_GROUP = 'FETCH_AB_TEST_GROUP' as const

export interface FetchAbTestGroupAction
  extends AsyncAction<definitions['AbTestsGroup']> {
  type: typeof FETCH_AB_TEST_GROUP
  testName: AbTestName
}

export const fetchAbTestsGroupAction = (
  testName: AbTestName,
  headers?: Api6NodeHeaders
) => ({
  type: FETCH_AB_TEST_GROUP,
  promise: () => fetchAbTestsGroupApi(testName, headers),
  testName,
})

export type AbTestActionTypes =
  | FetchAbTestGroupAction
  | FetchAbTestGroupListAction
  | LogoutAction
