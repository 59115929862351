import { Api5ClientType } from 'api/api.constants'
import { definitions } from 'api/generated/abtests'
import { ClientType } from 'common/types'
import { LOCALES, rtlDirection } from 'common-constants/locale'
import { Api6Platform, createApi6Header } from 'functions/createApi6Header'
import { resolveExternalLocaleId } from 'functions/locale'

export const reduceClient = (
  clientType: ClientType
): {
  web: boolean
  touch: boolean
  api5Platform: number
  api6Platform: Api6Platform
} => {
  return {
    web: clientType === 'web',
    touch: clientType === 'touch',
    api5Platform: Api5ClientType.web,
    api6Platform: createApi6Header(),
  }
}

export const reduceLocale = (
  localeCode: string
): {
  locale: string
  languageId: number
  rtl: boolean
} => {
  const locale = LOCALES.find(({ code }) => code === localeCode)
  return {
    locale: localeCode,
    languageId: resolveExternalLocaleId(localeCode),
    rtl: locale ? locale.direction === rtlDirection : false,
  }
}

export const reduceAbTestList = (
  abTestList: definitions['AbTestsGroup'][]
): Record<string, string> => {
  if (!abTestList.length) {
    return {}
  }

  return abTestList.reduce<Record<string, string>>((acc, item) => {
    acc[item.testName] = item.name
    return acc
  }, {})
}
