import { LegalDocument } from 'actions/completeTextAction'
import {
  huaweiMamba,
  isLoveMailru,
  isMamba,
  mambaLite,
  wambaLite,
} from 'common/constants'
import { androidOsType, iOsOsType, OsTypes } from 'common-constants/userAgent'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import {
  androidFolder,
  huaweiFolder,
  iOsFolder,
  legalHost,
  liteFolder,
  loveMailRuFolder,
} from './Agreement/agreement.paths'

const createPlatformAndPartnerPath = (
  name: LegalDocument,
  partnerId: number,
  osType: OsTypes | null
): string => {
  if (name === LegalDocument.agreement) {
    if (osType === androidOsType && isMamba(partnerId)) {
      return androidFolder
    }

    if (osType === iOsOsType && isMamba(partnerId)) {
      return iOsFolder
    }

    if (osType === androidOsType && isLoveMailru(partnerId)) {
      return mergeAllUrls(loveMailRuFolder, androidFolder)
    }

    if (osType === iOsOsType && isLoveMailru(partnerId)) {
      return mergeAllUrls(loveMailRuFolder, iOsFolder)
    }

    if (isLoveMailru(partnerId)) {
      return loveMailRuFolder
    }

    switch (partnerId) {
      case mambaLite:
      case wambaLite:
        return liteFolder

      case huaweiMamba:
        return huaweiFolder

      default:
        return '/'
    }
  } else if (
    name === LegalDocument.confidentiality &&
    [mambaLite, wambaLite].includes(partnerId)
  ) {
    return liteFolder
  }

  return '/'
}

export const createLegalPath = (
  locale: string,
  partnerId: number,
  name: LegalDocument,
  osType: OsTypes | null
): string => {
  return (
    legalHost +
    mergeAllUrls(
      locale,
      createPlatformAndPartnerPath(name, partnerId, osType),
      name
    )
  )
}
