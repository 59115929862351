import { definitions } from 'api/generated/photo'

import { reducePhotoLike } from '../function/reducePhotoLike'

export const changeVotedPhoto = (
  photos: definitions['OmniAlbumPhoto'][],
  photoId: number
): definitions['OmniAlbumPhoto'][] => {
  return photos.map((item) => {
    if (item.photoId === photoId) {
      return reducePhotoLike(item)
    }
    return item
  })
}
