import { fetchQueryApi } from 'api/fetchApi'
import { definitions, paths } from 'api/generated/photo'
import { NodeHeaders } from 'api/NodeHeaders'

/**
 * Александр Шустов:speech_balloon:  3:35 PM
 * в дефолтном может быть 100 фоток максимум, да
 * но еще есть режим ожидания, там больше
 * но там по идее тебе не нужно начинать с произвольной фотки
 */
export const LIMIT_PHOTOS = 100
export const LIMIT_PHOTOS_ALBUMS = 30

export interface PhotosProfileResult {
  photos: definitions['OmniAlbumPhoto'][]
  totalPhotos: number
}

type Method = paths['/users/{userId}/photos/omnialbum']
type Query = Method['get']['parameters']['query']
/** Бекенд пока не починил ответы от сервера 200 */
export type FetchUserPhotosResult = definitions['OmniAlbum']

export const fetchUserPhotosApi = (
  userId: number,
  offset = 0,
  limit = LIMIT_PHOTOS,
  headers?: NodeHeaders
) => {
  return fetchQueryApi<FetchUserPhotosResult, Query>(
    `/users/${userId}/photos/omnialbum` as keyof paths,
    {
      'paging[offset]': offset,
      'paging[limit]': limit,
    },
    {
      headers,
    }
  )
}
