import photo1Url from 'components/page/Landing/images/banners/banner1@2x.jpg'
import photo1WebpUrl from 'components/page/Landing/images/banners/banner1@2x.webp'
import photo2Url from 'components/page/Landing/images/banners/banner2@2x.jpg'
import photo2WebpUrl from 'components/page/Landing/images/banners/banner2@2x.webp'
import photo3Url from 'components/page/Landing/images/banners/banner3@2x.jpg'
import photo3WebpUrl from 'components/page/Landing/images/banners/banner3@2x.webp'
import photo4Url from 'components/page/Landing/images/banners/banner4@2x.jpg'
import photo4WebpUrl from 'components/page/Landing/images/banners/banner4@2x.webp'
import photo5Url from 'components/page/Landing/images/banners/banner5@2x.jpg'
import photo5WebpUrl from 'components/page/Landing/images/banners/banner5@2x.webp'
import photo6Url from 'components/page/Landing/images/banners/banner6@2x.jpg'
import photo6WebpUrl from 'components/page/Landing/images/banners/banner6@2x.webp'
import photo7Url from 'components/page/Landing/images/banners/banner7@2x.jpg'
import photo7WebpUrl from 'components/page/Landing/images/banners/banner7@2x.webp'
import photo8Url from 'components/page/Landing/images/banners/banner8@2x.jpg'
import photo8WebpUrl from 'components/page/Landing/images/banners/banner8@2x.webp'
import { createRandomNumber } from 'functions/createRandomNumber'

export const findBannerIndex = <T>(array: T[]) =>
  createRandomNumber(0, array.length - 1)

const spliceElementFromArray = <T>(array: T[], spliceIndex: number) => {
  const newArray = [...array]
  newArray.splice(spliceIndex, 1)
  return newArray
}

const arrayPhoto = [
  photo1Url,
  photo2Url,
  photo3Url,
  photo4Url,
  photo5Url,
  photo6Url,
  photo7Url,
  photo8Url,
]

const arrayPhotoWebp = [
  photo1WebpUrl,
  photo2WebpUrl,
  photo3WebpUrl,
  photo4WebpUrl,
  photo5WebpUrl,
  photo6WebpUrl,
  photo7WebpUrl,
  photo8WebpUrl,
]

export const calculateBannerPhotos = () => {
  const bannerIndex = findBannerIndex(arrayPhoto)
  const bannerPhotoUrl = arrayPhoto[bannerIndex]

  const anotherBannerPhotoUrls = spliceElementFromArray(arrayPhoto, bannerIndex)

  const anotherBannerIndex = findBannerIndex(anotherBannerPhotoUrls)

  const anotherBannerPhotoUrl = anotherBannerPhotoUrls[anotherBannerIndex]

  const anotherBannerPhotoWepUrls = spliceElementFromArray(
    arrayPhotoWebp,
    bannerIndex
  )

  const bannerPhotoWebpUrl = arrayPhotoWebp[bannerIndex]
  const anotherBannerPhotoWebpUrl =
    anotherBannerPhotoWepUrls[anotherBannerIndex]

  return {
    bannerPhotoUrl,
    anotherBannerPhotoUrl,
    bannerPhotoWebpUrl,
    anotherBannerPhotoWebpUrl,
  }
}
