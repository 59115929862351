import { FC, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import {
  pushSocketMessageAction,
  subscribeChannelsAction,
  unsubscribeChannelsAction,
} from 'actions/webSocketAction'
import { consoleLog } from 'common/consoleLog'
import { CometMethod } from 'common-constants/comet'
import { AppChannel } from 'components/system/AppChannel'
import { usePrevious } from 'hooks/usePrevious'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { webSocketReducer } from 'reducers/webSocketReducer'

export const channelNotEqual = (previous: AppChannel, next: AppChannel) =>
  next.channel !== previous.channel || next.cursor !== previous.cursor

export const channelNotEqualWithoutCursor = (
  previous: AppChannel,
  next: AppChannel
) => next.channel !== previous.channel

interface WebSocketChannelsSubscriberProps {
  channels: AppChannel[]
  notEqualFunction?: (previous: AppChannel, next: AppChannel) => boolean
}

export const WebSocketChannelsSubscriber: FC<WebSocketChannelsSubscriberProps> = ({
  channels,
  notEqualFunction = channelNotEqual,
}) => {
  useReducersInsert({ webSocket: webSocketReducer })
  const dispatch = useDispatch()
  const {
    socketId,
  } = useShallowEqualSelector(({ webSocket: { socketId } }) => ({ socketId }))
  const previousChannels = usePrevious<AppChannel[]>(channels)
  const previousSocketId = usePrevious<number>(socketId)

  // TODO REMOVE
  // console.info('WebSocketChannelsSubscriber', {
  //   socketId,
  //   previousSocketId,
  //   channels,
  // })

  useEffect(() => {
    if (previousSocketId !== socketId) {
      dispatch(subscribeChannelsAction(channels))
      consoleLog('Restore subscribe', { channels })
    }
  }, [socketId])

  useEffect(() => {
    const updateAllChannels = (isSubscribe = true) => {
      const message = {
        method: isSubscribe ? CometMethod.subscribe : CometMethod.unsubscribe,
        params: channels,
      }

      dispatch(pushSocketMessageAction(message))
    }

    updateAllChannels(true)
    return () => {
      updateAllChannels(false)
    }
  }, [])

  useEffect(() => {
    const subscribeList: AppChannel[] = []
    const unsubscribeList: AppChannel[] = []

    channels.forEach((next, index) => {
      const previous = previousChannels[index]
      if (previous) {
        if (notEqualFunction(previous, next)) {
          subscribeList.push(next)
          unsubscribeList.push(previous)
        }
      } else {
        subscribeList.push(next)
      }
    })

    if (subscribeList.length) {
      dispatch(unsubscribeChannelsAction(unsubscribeList))
      dispatch(subscribeChannelsAction(subscribeList))
    }
  }, [channels])

  return null
}
