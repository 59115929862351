import { Reducer } from 'redux'

import { CompleteTextAction, FETCH_TEXT } from 'actions/completeTextAction'
import { RESET_TEXT, resetTextAction } from 'actions/text/resetTextAction'

import { defaultPromiseReducer } from './defaultPromiseReducer'

export interface TextState {
  text: string | null
  textLoading: boolean
}

const initialState: TextState = {
  text: null,
  textLoading: true,
}

export const textReducer: Reducer<
  TextState,
  CompleteTextAction | ReturnType<typeof resetTextAction>
> = (state = initialState, action): TextState => {
  switch (action.type) {
    case FETCH_TEXT:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          textLoading: true,
        }),
        (result) => {
          if (result) {
            return {
              textLoading: false,
              text: result,
            }
          }

          return state
        },
        () => ({
          textLoading: false,
        })
      )

    case RESET_TEXT:
      return { ...initialState }

    default:
      return state
  }
}
