import { History, createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'

import configureStore from 'common/store/configureStore'
import {
  mergeStateWithStateLocalStorage,
  saveStateToLocalStorage,
} from 'common/store/localStorage'
import { saveStateToSessionStorage } from 'common/store/sessionStorage'
import { AppStore } from 'common/store/store.types'
import { clientThrottle } from 'functions/performance'
import { staticReducers } from 'reducers/index'
import { RootState } from 'reducers/RootState'

const TIME_TO_SAVE_STORE_IN_STORAGE = 1000

export const createHistory = (): History => {
  const history = window.browserHistory || createBrowserHistory()
  // Фикс обновления истории после хот релоада
  // https://github.com/supasate/connected-react-router/issues/121#issuecomment-530340947
  if (process.env.NODE_ENV === 'development' && !window.browserHistory) {
    window.browserHistory = history
  }

  /** Для отладки */
  // history.listen((location, action) => {
  //   console.info('listen', action, location.pathname, location.state)
  //   if (action === 'PUSH') {
  //     // debugger
  //   }
  // })

  return history
}

/**
 * TODO логика создания двойного store необходима так как на старом вебе
 * не генерируется store на сервере и поэтому не прокидывается
 * в window.__INITIAL_STATE__ начальное состояние reducer
 */
export const history = createHistory()

const initialState = window.__INITIAL_STATE__ || {}
const staticReducersInitialState = Object.keys(initialState).reduce(
  (result, reducerName) => {
    if (staticReducers[reducerName]) {
      return { ...result, [reducerName]: initialState[reducerName] }
    }
    return result
  },
  {} as RootState
)

const stateInitial: RootState = staticReducersInitialState

const initialStateWithLocalStorage = mergeStateWithStateLocalStorage(
  stateInitial
)

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history,
})

const createStore = (): AppStore => {
  const store =
    window.browserStore ||
    configureStore(
      initialStateWithLocalStorage,
      true,
      routerMiddleware,
      routerReducer
    )
  if (process.env.NODE_ENV === 'development' && !window.browserStore) {
    window.browserStore = store
  }
  return store as AppStore
}

export const store = createStore()

export const historyFirstRedux = createReduxHistory(store)

store.subscribe(
  clientThrottle(() => {
    /** Пишем в стор асинхронно, чтобы вся синхронщина успела отработать */
    setTimeout(() => {
      const state = store.getState()

      saveStateToLocalStorage(state)
      saveStateToSessionStorage(state)
    })
  }, TIME_TO_SAVE_STORE_IN_STORAGE)
)
