import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { localeOnlyRegExp, localeRegExp } from 'server/legacyProxy'

const resolvePathLocale = (path, query) => {
  if (path === shellPath && query.forceLocale) {
    return query.forceLocale
  }

  const localeOnlyResult = path.match(localeOnlyRegExp)

  if (localeOnlyResult) {
    return localeOnlyResult[1]
  }

  const pathWithLocaleResult = path.match(localeRegExp)

  if (pathWithLocaleResult) {
    return pathWithLocaleResult[1]
  }

  return null
}

export default resolvePathLocale
