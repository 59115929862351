import { disableJavaScriptAction } from 'actions/system/systemAction'
import { InitialFetch } from 'server/InitialFetch'

export const dropSupportOldBrowserInitialComponentData: InitialFetch<{}> = ({
  store: { dispatch },
}) => {
  dispatch(disableJavaScriptAction())

  return Promise.resolve()
}
