import { fetchApi } from 'api/fetchApi'
import { definitions } from 'api/generated/search'
import { Constitution, DatingGoals, Gender } from 'common-constants/api6.types'

export const fetchSearchSettingsListApi = () =>
  fetchApi<definitions['SearchFormResultWithRequest']>('/search/form/options')

export interface FieldVariant<V> {
  name: string
  value: V
}

interface FieldDescription {
  name: string
  formName: string
  isEnabled: boolean
}

interface FieldDescriptionWithRange extends FieldDescription {
  min: number
  max: number
  step: number
}

export interface FieldDescriptionWithVariants<V> extends FieldDescription {
  name: string
  formName: string
  isEnabled: boolean
  variants: FieldVariant<V>[]
}

export interface SearchFields {
  whoIsLooking: FieldDescriptionWithVariants<Gender>
  lookFor: FieldDescriptionWithVariants<Gender>
  withPhoto: FieldDescriptionWithVariants<boolean>
  ageFrom: FieldDescription
  ageTo: FieldDescription
  target: FieldDescriptionWithVariants<DatingGoals>
  whoAreNear: FieldDescription
  periodType: FieldDescriptionWithVariants<unknown>
  period: FieldDescriptionWithVariants<unknown>
  replyRate: FieldDescription
  heightFromSm: FieldDescriptionWithRange
  heightToSm: FieldDescriptionWithRange
  heightFromInch: FieldDescriptionWithRange
  heightToInch: FieldDescriptionWithRange
  heightUnit: FieldDescriptionWithVariants<unknown>
  weightFromKg: FieldDescriptionWithRange
  weightToKg: FieldDescriptionWithRange
  weightFromLb: FieldDescriptionWithRange
  weightToLb: FieldDescriptionWithRange
  weightUnit: FieldDescriptionWithVariants<unknown>
  lang: FieldDescriptionWithVariants<unknown>
  signExtended: FieldDescriptionWithVariants<unknown>
  education: FieldDescriptionWithVariants<unknown>
  constitution: FieldDescriptionWithVariants<Constitution>
  race: FieldDescriptionWithVariants<string>
  smoke: FieldDescriptionWithVariants<unknown>
  drink: FieldDescriptionWithVariants<unknown>
  orientation: FieldDescriptionWithVariants<unknown>
  circumstance: FieldDescriptionWithVariants<unknown>
  home: FieldDescriptionWithVariants<unknown>
  location: FieldDescriptionWithVariants<unknown>
  children: definitions['SearchFormField']
}

export interface SearchSettingsResult {
  formSettings: {
    fields: SearchFields
    additionalParams: string[]
    brandingResult: {
      isBranded: boolean
    }
    lexemes: Record<string, string>
  }
  formOptions: {
    myGender: Gender
    lookFor: string
    ageFrom: number
    ageTo: number
    country: number
    cursor: {
      type: string
      noid: number
      nchanged: number
      nactive: number
      offset: number
      searchId: number
      negativeTags: boolean
      searcherOffset: number
    }
    region: number
    city: number
    metro: number
    target: string[]
    sign: string[]
    whoAreNear: boolean
    navActive: number | null
    withPhoto: boolean
    withCompatibleSign: boolean
    periodType: string
    period: string
    replyRate: number
    children: string[]
    weightFrom: number
    weightTo: number
    weightUnit: string
    heightFrom: number
    heightTo: number
    heightUnit: string
    education: string[]
    constitution: Constitution[]
    race: string[]
    lang: string[]
    smoke: string[]
    drink: string[]
    circumstance: string[]
    home: string[]
    limit: number
    offset: number
    orientation: string[]
    navChanged: number
    navOid: number
    isExtended: boolean
    isRestored: boolean
    location: string
    statusNames: string
    requestSearchSource: number
    searchId: number
    negativeInterests: boolean
    searcherOffset: number
    interests: number[]
  }
  selectedLocation: {
    country: number
    region: number
    city: number
    metro: number
    countryName: string
    regionName: string
    cityName: string
    metroName: string
  }
  currentLocation: {
    country: number
    region: number
    city: number
    metro: number
    countryName: string
    regionName: string
    cityName: string
    metroName: string
  }
  interests: [
    {
      id: number
      text: string
    }
  ]
  showInterests: boolean
}
