import { useMemo } from 'react'

import { wambaColor } from 'common-constants/partnerColors'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const resolveBoardingColor = (partnerId: number) => {
  switch (partnerId) {
    case 3:
    case 81449119:
      return '#ed6b0b' // mamba

    case 958134831:
    case 963463432:
      return wambaColor

    case 132113273:
    case 475306:
      return '#005ff9' // mailru

    case 1374393173:
    case 1374393182:
      return '#ed6b0b' // yonja

    default:
      return '#ed6b0b'
  }
}

export const useBoardingColor = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  return useMemo(() => resolveBoardingColor(partnerId), [partnerId])
}
