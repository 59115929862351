import styled, { css } from 'styled-components'

import { mobileModalPaddingSize } from 'components/storefront/StoreFrontFeaturedPhotos/FeaturedPhotos.constants'

import { media, desktopModalPaddingSize } from './index'
import { PlainLink, RouterLink } from './link'
import { isLoveMailru } from '../../constants'

interface ContentProps {
  $flexGrow?: number
  minHeight?: string
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 460px;

  ${media.phone<ContentProps>`
    width: 100%;
    flex-grow: ${(props) => props.$flexGrow};
  `};
`
Content.defaultProps = {
  $flexGrow: 1,
}

/**
 * TODO: оставлено для обратной совместимости. Потом надо убрать и заменить референсы
 * @deprecated
 */
export const ModalContent = Content

export const viewContentCss = css<{ $flexGrow?: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: ${(props) => props.$flexGrow || 1};

  padding-left: ${desktopModalPaddingSize}px;
  padding-right: ${desktopModalPaddingSize}px;
  padding-bottom: ${desktopModalPaddingSize}px;

  ${media.phone`
    padding: ${mobileModalPaddingSize}px;
  `};
`

export const ViewContent = styled.main<{ $flexGrow?: number }>`
  ${viewContentCss};
  text-align: left; // TODO или убрать или переделать на props.theme.left, но нужно проверить чтобы нигде не сломать
`

export const ViewContentCustom = ViewContent

export const ModalList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;

  ${media.phone`
      padding-left: 20px;
      padding-right: 20px;
  `};
`
export const ModalPlainLink = styled(PlainLink)`
  padding: 15px 0;
  text-decoration: none;
  opacity: 0.5;

  &[href] {
    opacity: 1;
  }
`

export const ModalRouterLink = styled(RouterLink)`
  padding: 15px 0;
  text-decoration: none;
`

export const ContentLogo = styled(Content)`
  padding: 0 30px 10px 30px;

  ${media.phone`
    padding-left: 20px;
    padding-right: 20px;
  `};

  @media (max-width: 880px) {
    ${(props) =>
      isLoveMailru(props.theme.partnerId)
        ? ` background-color: #005ff9;
            position: relative;
            padding: 0;
            height: 48px;
            justify-content: center;
            border-radius: 0`
        : ''};
  }
`
