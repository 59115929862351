import { NoticeEmails } from 'components/system/redirect/NoticeEmail/constants'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import {
  loginParameterPath,
  loginPath,
  mailStatisticParameterPath,
  mailStatisticPath,
  noticePath,
  systemRedirectPath,
} from './paths'

export const createNoticeEmailsClientPath = (notice: NoticeEmails) =>
  mergeAllUrls(notice, mailStatisticPath, mailStatisticParameterPath)

export const createNoticeEmailsServerPath = (notice: NoticeEmails) =>
  mergeAllUrls(
    systemRedirectPath,
    noticePath,
    createNoticeEmailsClientPath(notice)
  )

export const createNoticeRecoveryPasswordsClientPath = () =>
  mergeAllUrls(
    NoticeEmails.PasswordRecovery,
    loginPath,
    loginParameterPath,
    mailStatisticPath,
    mailStatisticParameterPath
  )

export const createNoticeRecoveryPasswordsServerPath = () =>
  mergeAllUrls(
    systemRedirectPath,
    noticePath,
    createNoticeRecoveryPasswordsClientPath()
  )

export const createExceptionServerPath = (notice: NoticeEmails) =>
  mergeAllUrls(systemRedirectPath, noticePath, notice)
