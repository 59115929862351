import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { updateAuthorizedAction } from 'actions/authorizationAction'
import { updateShellAction } from 'actions/pwa/updateShellAction'
import { fetchMiniIfNeededAction } from 'actions/user/fetchMiniIfNeededAction'
import { Api5AuthResponse, Api5Result } from 'common-constants/api5.types'
import { useAuthorized } from 'hooks/useAuthorized'

/**
 * Нужно учитывать ответ с ошибкой.
 * @param response
 */
const findAuthField = (
  response: Api5Result<Api5AuthResponse>
): boolean | undefined => {
  if ('isAuth' in response.result) {
    return response.result.isAuth
  }

  return response.result.internalError?.isAuth
}

/**
 * Если есть несоответствие изначального статуса авторизации шелл:
 * 1. Обновляет статус авторизованности при загрузке из шелл ПВА
 * 2. Обновляет саму шелл
 */
export const useCheckAuthorization = () => {
  const dispatch = useDispatch()
  const authorized = useAuthorized()

  useEffect(() => {
    const checkAuthorization = async () => {
      const response = await ((dispatch(
        fetchMiniIfNeededAction()
      ) as unknown) as Promise<Api5Result<Api5AuthResponse>>)

      /**
       * Если авторизован, а shell неавторизованная,
       * то надо обновить авторизацию.
       **/
      if (response.result) {
        const isAuth = findAuthField(response)!
        if (isAuth !== authorized) {
          console.info('Need to update authorization')
          dispatch(updateAuthorizedAction(isAuth))
          /** Неверные стартовые значения из шелл, надо обновить */
          dispatch(updateShellAction())
        }
      }
    }

    checkAuthorization().catch(console.error)
  }, [authorized, dispatch])
}
