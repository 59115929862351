import { definitions } from 'api/generated/seo'
import { createLocaleQuery, defaultNodeOptions, fetchApi } from 'api/index'
import { NodeHeaders } from 'api/NodeHeaders'

export const fetchSeoSnippetApi = (
  locationId: string,
  locationType: string,
  lookFor: string,
  headers?: NodeHeaders,
  locale?: string
) =>
  fetchApi(
    `/seo/snippet?seoLocationId[type]=${locationType}&seoLocationId[locationId]=${locationId}&lookFor=${lookFor}&${createLocaleQuery(
      locale
    )}`,
    {
      ...defaultNodeOptions,
      headers,
    } as RequestInit
  )

export const fetchAdsLinksApi = (
  locationId: string,
  locationType: string,
  lookFor: string,
  headers?: NodeHeaders,
  locale?: string
) =>
  fetchApi(
    `/seo/ads-links?seoLocationId[type]=${locationType}&seoLocationId[locationId]=${locationId}&lookFor=${lookFor}&${createLocaleQuery(
      locale
    )}`,
    {
      ...defaultNodeOptions,
      headers,
    } as RequestInit
  )

export const resolveSeoLocationApi = (
  locationId: string,
  locationType: string,
  lookFor: string,
  headers?: NodeHeaders,
  locale?: string
) =>
  fetchApi(
    `/seo/resolve/location?seoLocationId[type]=${locationType}&seoLocationId[locationId]=${locationId}&lookFor=${lookFor}&${createLocaleQuery(
      locale
    )}`,
    { ...defaultNodeOptions, headers } as RequestInit
  )

export const fetchTopLocationLinksApi = (
  locationId: number | string,
  locationType: string,
  lookFor: string,
  limit: number,
  headers?: NodeHeaders,
  locale?: string
): Promise<definitions['SeoTopLocationsLinks']> =>
  fetchApi(
    `/seo/top-locations-links?seoLocationId[type]=${locationType}&seoLocationId[locationId]=${locationId}&lookFor=${lookFor}${
      limit ? `&limit=${limit}` : ''
    }&${createLocaleQuery(locale)}`,
    { ...defaultNodeOptions, headers } as RequestInit
  )

/**
 * Зачем был нужен этот метод?
 * @param locationId
 * @param locationType
 * @returns {Promise<*|*|*|*|*|undefined>}
 */
export const resolveTopLocationsApi = (
  locationId: string,
  locationType: string
) =>
  fetchApi(
    `/seo/resolve/top-locations?seoLocationId[type]=${locationType}&seoLocationId[locationId]=${locationId}`
  )
