import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'

import { updateIsMobileViewAction } from './updateIsMobileViewAction'

export const SAVE_SCROLL_Y = 'SAVE_SCROLL_Y' as const

export interface SaveScrollYAction {
  type: typeof SAVE_SCROLL_Y
  value: number
}

export const saveScrollYAction = (value: number) => (
  dispatch: AppDispatchNext,
  getState: AppGetState
) => {
  if (getState().systemView.savedScrollYValue !== value) {
    dispatch({
      type: SAVE_SCROLL_Y,
      value,
    })
  }
}

export const SAVE_COMPONENT_SCROLL_Y = 'SAVE_COMPONENT_SCROLL_Y'

export interface SaveComponentHorizontalScrollAction {
  type: typeof SAVE_COMPONENT_SCROLL_Y
  id: string
  value: number
}

export const saveComponentHorizontalScrollAction = (
  id: string,
  value: number
) => (dispatch: AppDispatchNext, getState: AppGetState) => {
  const { systemView } = getState()
  const previousValue = systemView.componentVerticalScrollValue[id]

  if (previousValue !== value) {
    dispatch({
      type: SAVE_COMPONENT_SCROLL_Y,
      id,
      value,
    })
  }
}

export const TOGGLE_RESTORE_COMPONENT_SCROLL_Y =
  'TOGGLE_RESTORE_COMPONENT_SCROLL_Y'

export interface ToggleNeedRestoreComponentScrollAction {
  type: typeof TOGGLE_RESTORE_COMPONENT_SCROLL_Y
  id: string
  value: boolean
}

export const toggleNeedRestoreComponentScrollAction = (
  id: string,
  needRestore: boolean
) => (dispatch: AppDispatchNext, getState: AppGetState) => {
  const previousValue = getState().systemView.componentNeedRestoreScroll[id]
  if (previousValue !== needRestore) {
    dispatch({
      type: TOGGLE_RESTORE_COMPONENT_SCROLL_Y,
      id,
      value: needRestore,
    })
  }
}
export const UPDATE_PAGE_VISIBILITY = 'UPDATE_PAGE_VISIBILITY'

interface UpdatePageVisibilityAction {
  type: typeof UPDATE_PAGE_VISIBILITY
  value: boolean
}

export const updatePageVisibilityAction = (value: boolean) => ({
  type: UPDATE_PAGE_VISIBILITY,
  value,
})

export type ViewActionTypes =
  | SaveScrollYAction
  | SaveComponentHorizontalScrollAction
  | ToggleNeedRestoreComponentScrollAction
  | UpdatePageVisibilityAction
  | ReturnType<typeof updateIsMobileViewAction>
