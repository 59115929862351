import React, { FC } from 'react'

import { FormattedMessage } from 'react-intl'

export const GeoPermissionTitle: FC = () => {
  return (
    <FormattedMessage
      id="geolocation.your_location"
      defaultMessage="Ваше местоположение"
    />
  )
}
