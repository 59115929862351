import { v1 } from 'uuid'

import { DEVICE_STORAGE_KEY } from 'common-constants/clientInfo'

export const resolveDeviceId = () => {
  try {
    return localStorage.getItem(DEVICE_STORAGE_KEY)
  } catch (ignored) {
    return v1()
  }
}

/**
 * @deprecated src/common/store/localStorage.ts
 */
function hasSupportLocalStorage() {
  try {
    localStorage.setItem('test', '1')
    localStorage.removeItem('test')
    return true
  } catch (error) {
    return false
  }
}

const isSupportLocalStorage = hasSupportLocalStorage()

/**
 * @deprecated src/common/store/localStorage.ts
 * @param key
 */
export function getFromLocalStorage(key: string) {
  return (
    process.env.browser && isSupportLocalStorage && localStorage.getItem(key)
  )
}

/**
 * @deprecated src/common/store/localStorage.ts
 * @param key
 */
export const removeFromLocalStorage = (key: string) => {
  return (
    process.env.browser && isSupportLocalStorage && localStorage.removeItem(key)
  )
}

/**
 * @deprecated src/common/store/localStorage.ts
 * @param key
 * @param value
 */
export function setKeyValue(key: string, value: unknown) {
  if (isSupportLocalStorage) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

/**
 * @deprecated src/common/store/localStorage.ts
 * @param key
 */
export function getKeyValue(key: string) {
  if (isSupportLocalStorage) {
    return localStorage.getItem(key)
  }
}
