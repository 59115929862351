import { Action } from 'redux'

import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { removeListComplainAction } from 'actions/complaints/complaintsStreamActions'
import { hideTooltipsAction } from 'actions/tooltipAction'
import {
  complaintListApi,
  ComplaintsEntityType,
  ComplaintsType,
  ProfileId,
  saveComplaintApi,
} from 'api/complaints/complaintsApi'
import { definitions } from 'api/generated/complaints'
import { UniNotice } from 'api/uninotice/UniNotice'
import { VoidHandler } from 'common/types'

export const FETCH_COMPLAIN_LIST = 'FETCH_COMPLAIN_LIST'

interface FetchComplaintListAction
  extends AsyncAction<definitions['ComplaintType']> {
  type: typeof FETCH_COMPLAIN_LIST
}

export const fetchComplaintListAction = (
  violatorId: ProfileId,
  typeOfEntity: ComplaintsType,
  entityId: ComplaintsEntityType
) => ({
  type: FETCH_COMPLAIN_LIST,
  promise: () => complaintListApi(violatorId, typeOfEntity, entityId),
})

export const SAVE_COMPLAINT = 'SAVE_COMPLAINT'

interface SaveComplaintAction extends AsyncAction {
  type: typeof SAVE_COMPLAINT
}

export const saveComplaintAction = (
  violatorId: ProfileId,
  type: ComplaintsType,
  cause: number,
  entityId: ComplaintsEntityType,
  actionAfterComplainSend?: VoidHandler
): AsyncThunkAction<Action<string> | Promise<void>> => async (dispatch) => {
  const { result: json } = ((await dispatch({
    type: SAVE_COMPLAINT,
    promise: () => saveComplaintApi(violatorId, type, cause, entityId),
  })) as unknown) as { result: { notice: UniNotice } }

  if (json.notice) {
    dispatch(hideTooltipsAction())
    dispatch(removeListComplainAction())
    if (typeof actionAfterComplainSend === 'function') {
      actionAfterComplainSend()
    }
  }
}

export type ComplainsType = FetchComplaintListAction | SaveComplaintAction
