import React, { Component } from 'react'

import { connect } from 'react-redux'
import { matchPath } from 'react-router-dom'

import { sendStatisticsAction } from 'actions/analytics/sendStatisticsAction'
import { metaphorNameAdblock } from 'common/common-constants/metaphor'
import { activityType } from 'common/constants'
import { withLayoutContext } from 'components/layout/MambaLayout/Context'
import { eventListPath } from 'components/page/EventList/EventList.paths'
import { photoRatingSettingsPath } from 'components/page/PhotoRating/constants'
import { searchPath } from 'components/page/Search/Search.paths'
import { findProfileUserId } from 'components/page/UserProfile/functions'
import {
  storeFrontAnalyticPath,
  storeFrontStartPath,
  settingsPath,
  searchSettingsPath,
  profilePath,
  ratingPath,
  contactPath,
  storeFrontStartPathSlideWithParametersAutoStart,
} from 'components/paths'
import { StorefrontService } from 'components/storefront/StoreFront.constants'
import {
  storeFrontGiftsPath,
  storeFrontGiftsUserPath,
  storeFrontPath,
  vipPresentPath,
} from 'components/storefront/StoreFront.paths'
import { mergeAllUrls } from 'functions/mergeAllUrls'

const openEventType = 'open'

// https://redmine.mamba.ru/issues/92837
const eventIds = {
  maketop: 'up_showcase',
  ['featured-photos']: 'likes_showcase',
  gifts: 'gifts_showcase',
  photoline: 'photoline_showcase',
  vip: 'vip_showcase',
  topup: 'coins_showcase',
}

@withLayoutContext
@connect((state) => ({
  router: state.router,
  userId: state.userReducer.userId,
}))
export default class Analytics extends Component {
  componentDidUpdate(prevProps) {
    const {
      layoutContext: { baseUrl },
      router,
      dispatch,
      userId,
    } = this.props
    if (
      process.env.browser &&
      router.location.pathname !== prevProps.router.location.pathname &&
      router.action !== 'POP'
    ) {
      if (isStoreFrontStart(router, baseUrl)) {
        const eventId = findEventId(router, baseUrl)
        const fromId = findFromId(prevProps, baseUrl, userId)

        const extendedFromId = addAdditionalInfo(fromId, baseUrl, router)

        if (eventId) {
          dispatch(sendStatisticsAction(openEventType, eventId, extendedFromId))
        }
      }
    }
  }

  render() {
    return null
  }
}

export function findMatchRoute(router, pathUrl, exact = false, strict = false) {
  return matchPath(router.location.pathname, {
    path: pathUrl,
    exact,
    strict,
  })
}

export const defineStoreFrontLocation = (router, baseUrl) => {
  const pathWithoutSlideUrl = mergeAllUrls(baseUrl, storeFrontPath)
  const pathWithSlideUrl = mergeAllUrls(
    baseUrl,
    storeFrontPath,
    storeFrontStartPath,
    storeFrontStartPathSlideWithParametersAutoStart
  )

  const pathWithGiftUrl = mergeAllUrls(
    baseUrl,
    storeFrontGiftsPath,
    storeFrontGiftsUserPath
  )

  const matchWithSlidePath = findMatchRoute(router, pathWithSlideUrl, true)
  const matchWithoutSlidePath = findMatchRoute(
    router,
    pathWithoutSlideUrl,
    true
  )

  const matchGiftPath = findMatchRoute(router, pathWithGiftUrl, true)

  if (matchWithSlidePath) {
    return matchWithSlidePath.path === pathWithSlideUrl
  } else if (matchWithoutSlidePath) {
    return matchWithoutSlidePath.path === pathWithoutSlideUrl
  } else if (matchGiftPath) {
    return matchGiftPath.path === pathWithGiftUrl
  } else {
    return false
  }
}

export function isStoreFrontStart(router, baseUrl) {
  return defineStoreFrontLocation(router, baseUrl)
}

export function findEventId(router, baseUrl) {
  const pathUrl = mergeAllUrls(baseUrl, storeFrontPath)
  const {
    params: { serviceType },
  } = findMatchRoute(router, pathUrl)

  const eventId = eventIds[serviceType]
  if (eventId) {
    if (
      serviceType === StorefrontService.gifts &&
      router.location.pathname.includes(vipPresentPath)
    ) {
      // https://redmine.mamba.ru/issues/93670
      return 'vipgift_showcase'
    }
    return eventId
  }
  console.error(`EventId not found for service type "${serviceType}"`)
  return null
}

export function findFromId({ router }, baseUrl, currentUserId) {
  const pathWithSlideUrl = mergeAllUrls(
    baseUrl,
    storeFrontPath,
    storeFrontStartPath,
    storeFrontAnalyticPath
  )
  const pathWithoutSlide = mergeAllUrls(
    baseUrl,
    storeFrontPath,
    storeFrontAnalyticPath
  )
  const matchWithSlideUrl = findMatchRoute(router, pathWithSlideUrl)
  const matchWithoutSlideUrl = findMatchRoute(router, pathWithoutSlide)

  const matchUrl = matchWithSlideUrl ? matchWithSlideUrl : matchWithoutSlideUrl

  let routeUrl = router.location.pathname

  if (matchUrl && matchUrl.params && matchUrl.params.placeName) {
    routeUrl = '/' + matchUrl.params.placeName
  }

  const isUserIdFound = findProfileUserId(router.location.pathname)

  if (routeUrl.includes(searchSettingsPath)) {
    return 'search_filters'
  } else if (routeUrl.includes(contactPath)) {
    return 'contact_list'
  } else if (routeUrl.includes(settingsPath)) {
    return 'settings'
  } else if (routeUrl.includes('/bbs_add')) {
    return 'add_announcement'
  } else if (routeUrl.includes('/bbs')) {
    return 'announcement'
  } else if (routeUrl.includes(photoRatingSettingsPath)) {
    return 'encounters_filters'
  } else if (routeUrl.includes(ratingPath)) {
    return 'encounters'
  } else if (routeUrl.includes(searchPath)) {
    return 'search'
  } else if (routeUrl.includes(eventListPath)) {
    return 'events'
  } else if (routeUrl.includes(activityType)) {
    return activityType
  } else if (
    isUserIdFound &&
    Number(isUserIdFound.params.userId) === currentUserId
  ) {
    return 'my_profile'
  } else if (routeUrl.includes(profilePath)) {
    return 'profile'
  } else {
    return 'direct'
  }
}

export const addAdditionalInfo = (fromId, baseUrl, router) => {
  /**
   * https://youtrack.mamba.ru/issue/M-4641
   * добавим adv
   */
  const pathWithSlideUrl = mergeAllUrls(
    baseUrl,
    storeFrontPath,
    storeFrontStartPath,
    storeFrontStartPathSlideWithParametersAutoStart
  )

  const matchWithSlidePath = findMatchRoute(router, pathWithSlideUrl, true)

  if (
    matchWithSlidePath &&
    matchWithSlidePath.url.includes(metaphorNameAdblock)
  ) {
    return `adv_${fromId}`
  }

  return fromId
}
