import {
  ComplainsType,
  FETCH_COMPLAIN_LIST,
} from 'actions/complaints/complaintsAction'
import {
  ComplaintsStreamTypes,
  REMOVE_LIST_COMPLAIN,
} from 'actions/complaints/complaintsStreamActions'
import { definitions } from 'api/generated/complaints'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface ComplaintState {
  complaintListReal: definitions['ComplaintCause'][]
  complaintListNotReal: definitions['ComplaintCause'][]
}

const initialState: ComplaintState = {
  complaintListReal: [],
  complaintListNotReal: [],
}

export const complaintReducer = (
  state = initialState,
  action: ComplainsType | ComplaintsStreamTypes
) => {
  switch (action.type) {
    case FETCH_COMPLAIN_LIST:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        if (result) {
          return {
            complaintListReal: result.causes.filter(
              ({ realComplaint }) => realComplaint
            ),
            complaintListNotReal: result.causes.filter(
              ({ realComplaint }) => !realComplaint
            ),
          }
        }
        return state
      })

    case REMOVE_LIST_COMPLAIN:
      return {
        ...state,
        complaintListReal: [],
        complaintListNotReal: [],
      }

    default:
      return state
  }
}
