import { Reducer } from 'redux'

import {
  CLEAN_PHOTO_LINE,
  FETCH_PHOTO_LINE,
  LOCK_PHOTO_LINE,
  MOVE_PHOTO_LINE,
  PhotoLineTypes,
  POP_DELAYED_PHOTO_LINE,
  RESET_PHOTO_LINE,
  STOP_PHOTO_LINE,
  UPDATE_PHOTO_LINE_ITEM_WIDTH,
} from 'actions/photoLineAction'
import {
  PhotoLinePhotoComet,
  SOCKET_RECEIVE_DATA,
  SocketContent,
  SocketData,
  SocketReceiveDataAction,
} from 'actions/socket/socketReceiveDataAction'
import { definitions } from 'api/generated/photoline'
import { limitArray } from 'functions/limitArray'
import { GQLOrientation } from 'generated-graphql/graphql'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { filterCometMessages } from 'reducers/functions'
import { cometReducer } from 'reducers/photoLine/cometReducer'
import { fromComet } from 'reducers/photoLine/fromComet'
import { multiply } from 'reducers/photoLine/multiply'
import {
  byAction,
  equalId,
  fromFetch,
} from 'reducers/photoLine/photoLine.functions'
import { PhotoLineItem } from 'reducers/photoLine/photoLine.types'

const limit = 20

export interface PhotoLineState {
  photoLoading: boolean
  photos: PhotoLineItem[]
  delayedPhotos: PhotoLineItem[]
  lockedPhotos: PhotoLineItem[]
  cometChannelName: string | null
  photoLineClassId: 'regular' | 'gay' | GQLOrientation | null
  movingPhotos: boolean
  locked: boolean
  lastModified: number
  photoWidth: number
  deleted: Record<number, boolean>
  onlineCount: number | null
}

const initialState: PhotoLineState = {
  photoLoading: true,
  photos: [],
  delayedPhotos: [],
  lockedPhotos: [],
  cometChannelName: null,
  photoLineClassId: null,
  movingPhotos: false,
  locked: false,
  lastModified: 0,
  photoWidth: 106,
  deleted: {},
  onlineCount: null,
}

export const photoLineReducer: Reducer<
  PhotoLineState,
  PhotoLineTypes | SocketReceiveDataAction
> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PHOTO_LINE:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ photoLoading: true }),
        (result) => {
          const {
            items,
            lineId: { cometChannelName },
            photolineClass,
            online,
          } = result!

          const photos = (items as (definitions['PhotolinePostWithAuthor'] & {
            authorId: number
          })[]).map(fromFetch)

          return {
            photos: limitArray(multiply(photos), limit),
            cometChannelName,
            photoLineClassId: photolineClass.id,
            photoLoading: false,
            onlineCount: online.users,
          }
        },
        () => ({ photoLoading: false })
      )

    case SOCKET_RECEIVE_DATA:
      return defaultPromiseReducer(state, action, undefined, (result) => {
        const messages = filterCometMessages(
          (result as unknown) as SocketData<SocketContent>[],
          state.cometChannelName!
        )
        const newPhotos = multiply(
          messages.filter(byAction('publish')).map(fromComet)
        )
        const modifications = messages.filter(byAction('modify')).map(fromComet)
        const removed = messages
          .filter(byAction('remove'))
          .map(({ identity }) => Number(identity))
        return cometReducer(state, action, newPhotos, modifications, removed)
      })

    case MOVE_PHOTO_LINE:
      return {
        ...state,
        movingPhotos: true,
        lastModified: new Date().getTime(),
        lockedPhotos: [],
      }

    case STOP_PHOTO_LINE:
      return { ...state, movingPhotos: false }

    case POP_DELAYED_PHOTO_LINE:
      const lockedPhoto = state.delayedPhotos.pop()
      const [...rest] = state.delayedPhotos
      // console.log('pop delayed', new Date())
      return {
        ...state,
        lockedPhotos: lockedPhoto ? [lockedPhoto] : [],
        photos: limitArray(
          lockedPhoto ? [lockedPhoto, ...state.photos] : state.photos,
          limit
        ),
        delayedPhotos: limitArray(rest),
        lastModified: new Date().getTime(),
      }

    case LOCK_PHOTO_LINE:
      return {
        ...state,
        locked: action.locked,
        lastModified: new Date().getTime(),
      }

    case CLEAN_PHOTO_LINE:
      const { photoId } = action
      const deletedPhoto = state.photos.find(equalId(photoId))
      const { deleted } = state
      if (deletedPhoto && deleted[photoId]) {
        delete deleted[photoId]
        return {
          ...state,
          photos: state.photos.filter(({ id }) => id !== photoId),
          deleted: { ...deleted },
        }
      }
      return state

    case UPDATE_PHOTO_LINE_ITEM_WIDTH:
      return { ...state, photoWidth: action.width }

    case RESET_PHOTO_LINE:
      return initialState

    default:
      return state
  }
}
