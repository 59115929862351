import { PhoneVerificationAlternativeMethodAction } from 'actions/settings/phoneVerificationAlternativeMethodAction'
import {
  PhoneVerificationAction,
  PhoneVerificationMethod,
} from 'components/page/EnterVerificationCode/constants'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

import { SettingsReducerState } from './SettingsReducerState'

export const getPhoneVerificationAlternativeMethodCaseHandler = (
  state: SettingsReducerState,
  action: PhoneVerificationAlternativeMethodAction
): SettingsReducerState => {
  return defaultPromiseReducer(
    state,
    action,
    () => ({
      settingsLoading: true,
      errorCode: null,
    }),
    (result) => ({
      settingsLoading: false,
      phoneVerificationMethod: result!.method as PhoneVerificationMethod,
      phoneVerificationAction: result!.action as PhoneVerificationAction,
    }),
    (result, state, error): Partial<SettingsReducerState> => ({
      settingsLoading: false,
      errorCode: error.code,
    })
  )
}
