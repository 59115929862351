import { matchPath } from 'react-router-dom'

import { AppRoute } from 'components/components.types'
import { commonRoutes } from 'components/layout/commonRoutes'
import routes from 'components/routes'
import { removeLocaleFromPath } from 'functions/language'

const allRoutes: AppRoute[] = commonRoutes.concat(routes)

const fixPath = (path: string) => {
  // чтобы не делать лишних вычислений через mergeAllUrls
  if (path[0] !== '/') {
    return '/' + path
  }
  return path
}

const removeRouterParams = (path: string) => {
  const index = path.indexOf(':')
  if (index > -1) {
    return path.slice(0, index)
  }
  return path
}

export const findPathContext = (path: string) => {
  const pathWithoutLocale = fixPath(removeLocaleFromPath(path))
  const result = allRoutes.find((startPath) =>
    matchPath(pathWithoutLocale, {
      path: startPath.path,
      exact: startPath.exact,
    })
  )
  if (result) {
    return removeRouterParams(result.path)
  }
}
