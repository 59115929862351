import { ModerationStatus } from 'api/photo/photo.types'
import { ProfileApi5 } from 'reducers/profile/ProfileApi5.types'
import { UserState } from 'reducers/user/UserState'

export const profileReducer = ({
  id,
  squarePhotoUrl,
  photo,
  unreadMessages,
  accountBalance,
  isVip,
  login,
  incognitoIsOn,
  unreadNotifications,
  unreadSupportMessages,
  gender,
  hasDefaultPhoto,
  name,
  autoDetectEnabled,
  location,
}: ProfileApi5): Partial<UserState> => {
  const { squarePhotoUrlMasked, albumId, status } = photo || {}

  // TODO не всегда приходит albumId. Если у пользователя нету фотографий в главном альбоме то albumId = undefined
  return {
    userId: id,
    albumId: albumId,
    smallPhoto: squarePhotoUrl,
    smallPhotoMasked: squarePhotoUrlMasked || squarePhotoUrl,
    messagesCounter: unreadMessages,
    accountBalance,
    photoStatus: (status?.toLocaleLowerCase() as ModerationStatus) || '',
    vip: isVip ? isVip : false, // Затирается значение после блокировки, так как приходит ограниченный набор значений
    gender,
    login,
    incognito: incognitoIsOn,
    notifications: unreadNotifications + unreadSupportMessages,
    hasDefaultPhoto,
    name,
    autoDetectEnabled,
    countryId: location?.country_id,
  }
}
