import { verifyPhonePlainAction } from 'actions/settings/verifyPhoneAction'
import {
  PhoneVerificationMethod,
  PhoneVerificationAction,
} from 'components/page/EnterVerificationCode/constants'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

import { SettingsReducerState } from './SettingsReducerState'

export const getVerifyPhoneCaseHandler = (
  state: SettingsReducerState,
  action: ReturnType<typeof verifyPhonePlainAction>
): SettingsReducerState => {
  return defaultPromiseReducer(
    state,
    action,
    (): Partial<SettingsReducerState> => ({
      settingsLoading: true,
      phoneVerificationMethod: null,
      phoneVerificationAction: null,
      errorCode: null,
    }),
    (result): Partial<SettingsReducerState> => ({
      settingsLoading: false,
      phoneVerificationMethod: result.method as PhoneVerificationMethod,
      phoneVerificationAction: result.action as PhoneVerificationAction,
    }),
    (result, state, error): Partial<SettingsReducerState> => ({
      settingsLoading: false,
      errorCode: error.code,
    })
  )
}
