import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const DeleteContactsModalLoadable = loadable(
  () => import('./DeleteContactsModal'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Delete contacts modal split error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
