import { AsyncAction } from 'actions/actions.types'
import { goBackAction } from 'actions/route/routeAction'
import { findToggleApiMethod } from 'actions/settings/findToggleMethodApiAction'
import { AsyncThunkAction } from 'actions/types'
import { updateFieldAction } from 'actions/user/updateFieldAction'
import { SettingsForm } from 'reducers/settings/SettingsReducerState'

export interface ToggleSettingsCommonAction extends AsyncAction {
  type: typeof TOGGLE_SETTING_ENABLED
  field: string
  enabled: boolean
}

export const toggleSettingsCommonAction = (
  field: keyof SettingsForm,
  enabled: boolean
) => ({
  type: TOGGLE_SETTING_ENABLED,
  field,
  enabled,
  promise: () => findToggleApiMethod(field, enabled),
})

export const toggleSettingEnabledAction = (
  field: keyof SettingsForm,
  enabled: boolean,
  disabledGoBack?: boolean
): AsyncThunkAction => async (dispatch, getState) => {
  const { settingsReducer } = getState()

  if (
    settingsReducer &&
    !settingsReducer.lockUpdates[field] &&
    enabled !== settingsReducer.form[field]
  ) {
    dispatch(toggleSettingsCommonAction(field, enabled))
  }

  dispatch(updateFieldAction(field, enabled))

  if (!disabledGoBack) {
    dispatch(goBackAction())
  }
}

export const TOGGLE_SETTING_ENABLED = 'TOGGLE_SETTING_ENABLED'
