import cookie from 'js-cookie'

import { DEFAULT_LOCALE } from '../../constants'
import { fetchApi, fetchApi5 } from '../index'

export const saveProfileApi = (tag, settings, locale = DEFAULT_LOCALE) => {
  const s_post = cookie.get('s_post')
  return fetchApi5(`/settings/${tag}?lang_id=${locale}`, {
    method: 'PUT',
    body: JSON.stringify({
      secure: { s_post },
      ...settings,
      s_post,
    }),
  })
}

export const profilePersonalApi = (locale) =>
  fetchApi5(`/settings/personal/edit?lang_id=${locale}`)

export const eroticPhotosEnabledApi = () =>
  fetchApi('/settings/erotic_photos/enabled')

export const toggleEroticPhotosApi = (enabled) =>
  fetchApi('/settings/erotic_photos/switch', {
    method: 'POST',
    body: JSON.stringify({ enabled }),
  })

export const toggleSearchVisibilityApi = (on) =>
  fetchApi('/settings/searchable_profile', {
    method: 'POST',
    body: JSON.stringify({ on }),
  })

export const messageSoundEnabledApi = () =>
  fetchApi('/settings/incoming_messages_sound/enabled')

export const fetchVipSettingsApi = () => fetchApi('/settings/vip/settings')

export const hideOrRevealLastAccessTimeApi = (hidden) =>
  fetchApi('/settings/vip/hideOrRevealLastAccessTime', {
    method: 'POST',
    body: JSON.stringify({ hidden }),
  })

export const incomingMessagesSoundApi = (enabled) =>
  fetchApi('/settings/incoming_messages_sound/switch', {
    method: 'POST',
    body: JSON.stringify({ enabled }),
  })

export const switchInvisibleModeApi = (enabled) =>
  fetchApi('/settings/vip/switchInvisibleMode', {
    method: 'POST',
    body: JSON.stringify({ enabled }),
  })

export const hideOrRevealAgeApi = (hidden) =>
  fetchApi('/settings/vip/hideOrRevealAge', {
    method: 'POST',
    body: JSON.stringify({ hidden }),
  })

export const fetchWhoCanFindApi = () =>
  fetchApi('/settings/profile_visibility/status')

export const fetchPhoneCodeListApi = (locale) =>
  fetchApi(`/real_user/phone_prefixes?_loc[locale]=${locale}`)

export const isPasswordRequiredApi = (context) =>
  fetchApi(`/real_user/passwordRequirement?context=${context}`)

export const fetchSocialVerifyApi = async (provider) => {
  const [url, password] = await Promise.all([
    fetchApi(`/real_user/oauth_url?provider=${provider}`),
    isPasswordRequiredApi('socialNetworkBinding'),
  ])

  return {
    ...url,
    ...password,
  }
}

export const fetchExternalVerifyApi = async (method) =>
  fetchApi(`/real_user/external_confirmation_url?method=${method}`)

export const verifySocialByCodeApi = (state, provider, code) =>
  fetchApi('/real_user/verifySocialAccountByCode', {
    method: 'POST',
    body: JSON.stringify({ state, provider, code }),
  })

export const requestGestureApi = () =>
  fetchApi('/photo_verification/requestGesture', {
    method: 'POST', // ?
  })

export const uploadVerificationPhotoApi = (photo) =>
  fetchApi('/photo_verification/uploadPhoto', {
    method: 'POST',
    body: JSON.stringify({ photo }),
  })

export const updateEmailSubscriptionApi = (notificationId, subscribe) => {
  if (subscribe) {
    return fetchApi('/notifications/subscribeOnEmailNotification', {
      method: 'POST',
      body: JSON.stringify({ notificationId }),
    })
  }

  return fetchApi('/notifications/unsubscribeFromEmailNotification', {
    method: 'POST',
    body: JSON.stringify({ notificationId }),
  })
}

export const fetchIncognitoApi = () => fetchApi('/incognito')

export const switchIncognitoApi = (enabled) => {
  if (enabled) {
    return fetchApi('/incognito', { method: 'PUT' })
  }
  return fetchApi('/incognito', { method: 'DELETE' })
}

export const fetchAtlasApi = () => fetchApi('/settings/geomatch/enabled')

export const switchAtlasApi = (enabled) =>
  fetchApi('/settings/geomatch/switch', {
    method: 'POST',
    body: JSON.stringify({ enabled }),
  })

export const allowedToDeleteApi = () =>
  fetchApi('/settings/delete_account/is_allowed_to_delete', {
    method: 'POST',
  })

export const requestRemovalApi = (reasonId, reason) =>
  fetchApi('/settings/delete_account/requestAccountRemoval', {
    method: 'POST',
    body: JSON.stringify({ reasonId, reason }),
  })

/**
 * В будущем может быть удален в пользу src/common/api/settings/removeAccountApi.ts
 * https://redmine.mamba.ru/issues/118883#note-15
 */
export const deleteAccountApi = () =>
  fetchApi('/settings/delete_account/deleteAccount', {
    method: 'POST',
  })

export const renameUserNameApi = (userName) =>
  fetchApi('/settings/renameUser', {
    method: 'POST',
    body: JSON.stringify({ userName }),
  })
