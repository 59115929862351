import {
  ToggleHitListCounterTopBarUpdateAction,
  ToggleHitListNeedsUpdateAction,
  TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE,
  TOGGLE_HIT_LIST_NEEDS_UPDATE,
} from 'actions/hitListAction'
import {
  SocketReceiveDataAction,
  SOCKET_RECEIVE_DATA,
  SocketContent,
  SocketData,
} from 'actions/socket/socketReceiveDataAction'
import { TimeRange } from 'common-constants/timeRange'
import {
  createHitListChannel,
  createHitListUpdatedSignalChannel,
} from 'functions/comet'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { filterCometMessages } from 'reducers/functions'

export interface HitListState {
  needHitListUpdate?: boolean
  needTopBarUpdate?: boolean
  timeStamp: number
}

export const hitListReducer = (
  state: HitListState = {
    needHitListUpdate: false,
    needTopBarUpdate: false,
    timeStamp: 0,
  },
  action:
    | SocketReceiveDataAction
    | ToggleHitListNeedsUpdateAction
    | ToggleHitListCounterTopBarUpdateAction
): HitListState => {
  switch (action.type) {
    case SOCKET_RECEIVE_DATA:
      return defaultPromiseReducer(state, action, undefined, () => {
        if (action.userId) {
          const messages = filterCometMessages<SocketContent>(
            action.result as SocketData<SocketContent>[],
            createHitListChannel(action.userId)
          )
          const messagesSignal = filterCometMessages<SocketContent>(
            action.result as SocketData<SocketContent>[],
            createHitListUpdatedSignalChannel(action.userId)
          )

          if (
            (messages.length || messagesSignal.length) &&
            Date.now() - state.timeStamp > TimeRange.second * 4
          ) {
            return {
              needHitListUpdate: true,
              needTopBarUpdate: true,
              timeStamp: Date.now(),
            }
          }
        }
        return null
      })

    case TOGGLE_HIT_LIST_NEEDS_UPDATE:
      return {
        ...state,
        needHitListUpdate: action.value,
      }

    case TOGGLE_HIT_LIST_COUNTER_TOP_BAR_UPDATE:
      return {
        ...state,
        needTopBarUpdate: action.value,
      }

    default:
      return state
  }
}
