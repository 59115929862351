import { Reducer } from 'redux'

import {
  DeleteProfileActionType,
  REQUEST_REMOVAL,
} from 'actions/deleteProfileAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export interface DeleteProfileReducerState {
  hasRequestRemovalOk: boolean
  loadedRequestRemoval: boolean
}

const defaultState: DeleteProfileReducerState = {
  hasRequestRemovalOk: false,
  loadedRequestRemoval: false,
}

export const deleteProfileReducer: Reducer<
  DeleteProfileReducerState,
  DeleteProfileActionType
> = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_REMOVAL:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          loadedRequestRemoval: false,
        }),
        () => ({
          hasRequestRemovalOk: true,
          loadedRequestRemoval: true,
        }),
        () => ({
          hasRequestRemovalOk: false,
          loadedRequestRemoval: true,
        })
      )

    default:
      return state
  }
}
