import { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'

import { updateMnogoCardIdAction } from 'actions/system/updateMnogoCardIdAction'
import { linkCardToUserApi } from 'api/mnogocard/linkCardToUserApi'
import { isMamba, isMheart } from 'common/constants'
import { PartialRootState } from 'common/store/store.types'
import { RootState } from 'reducers/RootState'

import { useIsAuthUserReady } from './useIsAuthUserReady'
import { useShallowEqualSelector } from './useShallowEqualSelector'

/** Связывает анкету пользователя с картой лояльности MnogoCard */
export const useLinkUserToMnogoCard = () => {
  useReadAndStoreMnogoCardId()
  useActivateStoredMnogoCardId()
}

const useReadAndStoreMnogoCardId = () => {
  const dispatch = useDispatch()
  const { mnogoCardId, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { mnogoCardId, partnerId } }) => ({
      mnogoCardId,
      partnerId,
    })
  )

  useEffect(() => {
    if (!isSupportedPartner(partnerId)) {
      return
    }

    const urlSearchParams = new URLSearchParams(location.search)

    const utmSourceFromQs = urlSearchParams.get('utm_source')
    const mnogoCardIdFromQs = urlSearchParams.get('mnogocard')

    if (
      utmSourceFromQs === 'mnogocard' &&
      mnogoCardIdFromQs &&
      mnogoCardIdFromQs !== mnogoCardId
    ) {
      dispatch(updateMnogoCardIdAction(mnogoCardIdFromQs))
    }
  }, [dispatch, mnogoCardId, partnerId])
}

const useActivateStoredMnogoCardId = () => {
  const dispatch = useDispatch()
  const { mnogoCardId, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { mnogoCardId, partnerId } }) => ({
      mnogoCardId,
      partnerId,
    })
  )

  const authUserReady = useIsAuthUserReady()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (
      !authUserReady ||
      !mnogoCardId ||
      loading ||
      !isSupportedPartner(partnerId)
    ) {
      return
    }

    ;(async () => {
      try {
        setLoading(true)
        await linkCardToUserApi(mnogoCardId)
        dispatch(updateMnogoCardIdAction(undefined))
      } catch (error) {
        console.error('Failed to link mnogocard to user', error)
      } finally {
        setLoading(false)
      }
    })()
  }, [dispatch, authUserReady, loading, mnogoCardId, partnerId])
}

const isSupportedPartner = (partnerId: number) => {
  return isMamba(partnerId) || isMheart(partnerId)
}

export const mutatePartialRootStateWithMnogoCardIdState = (
  reducerState: RootState,
  partialRootState: PartialRootState
): void => {
  if (reducerState.systemReducer?.mnogoCardId !== null) {
    partialRootState.systemReducer = {
      mnogoCardId: reducerState.systemReducer.mnogoCardId,
    }
  }
}
