import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { updateQueryAction } from 'actions/system/updateQueryAction'
import { insertUniNoticeFromQueryAction } from 'actions/uninotice/insertUniNoticeFromQueryAction'
import { serverUniNoticeParameter } from 'components/uninotice/UniNotice.constants'
import { replace } from 'functions/router'
import { useQueryParams } from 'hooks/useQueryParams'

export const useUrlHasNoticeInQueryParameters = () => {
  const dispatch = useDispatch()

  const noticeSearchParameters = useQueryParams().get(serverUniNoticeParameter)

  /**
   * Если в url query parameters есть notice.
   * Мы должны.
   * 1. Добавить в systemReducer в виде query
   * 2. Убрать из url query полностью(чтобы при перезагрузке снова не вызвать данный хук, и не показать notice)
   * 3. Вызвать стандартный action для notice. При этом вытащив данные из systemReducer
   */
  useEffect(() => {
    if (noticeSearchParameters) {
      dispatch(updateQueryAction())
      dispatch(replace(`${window.location.origin}${window.location.pathname}`))
      dispatch(insertUniNoticeFromQueryAction())
    }
  }, [dispatch, noticeSearchParameters])
}
