import {
  ADD_NOTICE_SHORT_CUT_TO_COLLECTION,
  NoticeShortCutTypes,
  PUSH_SHORTCUT_NOTIFICATION,
  REMOVE_SHORTCUT_NOTIFICATION,
} from 'actions/uninotice/noticeShortCutAction'
import { UniNotice } from 'api/uninotice/UniNotice'
import { UniNoticeId } from 'components/uninotice/uninotice.types'

/**
 * Для Toast Notification
 * Всплывающих справа сверху.
 */
export interface NotificationItem {
  id: UniNoticeId
  type: string
}

export interface NoticeShortCutState {
  collection: Record<UniNoticeId | string, UniNotice>
  notificationsQueue: NotificationItem[]
}

export const noticeShortCut = (
  state: NoticeShortCutState = {
    notificationsQueue: [],
    collection: {},
  },
  action: NoticeShortCutTypes
): NoticeShortCutState => {
  switch (action.type) {
    case ADD_NOTICE_SHORT_CUT_TO_COLLECTION:
      return {
        ...state,
        collection: {
          ...state.collection,
          [action.id]: {
            noticeId: action.result.noticeId,
            type: action.result.type,
            errorCode: action.result.errorCode,
            payload: action.result.payload,
            targetScreen: undefined, // ?
          },
        },
      }

    case PUSH_SHORTCUT_NOTIFICATION:
      return {
        ...state,
        notificationsQueue: [...state.notificationsQueue, action.notification],
      }

    case REMOVE_SHORTCUT_NOTIFICATION:
      delete state.collection[action.id]

      return {
        ...state,
        notificationsQueue: state.notificationsQueue.filter(
          (item) => item.id !== action.id
        ),
      }

    default:
      return state
  }
}
