import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

import { DefaultLoader } from '../presentational/AnimatedWatch/AnimatedWatch'

const StoreFrontLoadable = loadable(() => import('./StoreFrontRoutes'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('storefront routes', error)
    }
    return undefined
  })(defaultLoadableProps),
})

export default StoreFrontLoadable
