import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

const DatingProfileLoadable = loadable(() => import('./DatingProfile'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('dating profile split error', error)
    }

    return <DefaultLoader />
  })(defaultLoadableProps),
})

export default DatingProfileLoadable
