import { features, details } from 'api/api.constants'
import { MAMBA_FEATURES } from 'common/constants'

export const createApolloUrl = (locale: string | null): string => {
  if (locale) {
    return `${process.env.graphql_url}?_loc[locale]=${locale}`
  }
  return process.env.graphql_url as string
}

export const createApolloHeaders = (
  csrfToken: string | null,
  mambaClient: string,
  deviceId: string | null
) => {
  const headers: Record<string, string> = {
    ['Csrf-Token']: csrfToken!,
    ['Mamba-Client']: mambaClient,
    [MAMBA_FEATURES]: JSON.stringify({
      features,
      details,
    }),
  }
  if (deviceId) {
    headers['Mamba-Device-Id'] = deviceId
  }

  if (process.env.browser) {
    headers['X-Requested-With'] = 'XMLHttpRequest'
  }

  return headers
}
