import styled, { css } from 'styled-components'

import { flexGrowCss } from 'common-constants/flex'

export const paperRadius = 10

export const PaperCss = css`
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: ${paperRadius}px;
  padding: ${paperRadius}px;
  box-sizing: border-box;
`

export const Paper = styled.div`
  ${PaperCss};
`

/** @deprecated Используйте SpaceSeparatorNext */
export const SpaceSeparator = styled.span<{
  size?: number
}>`
  width: ${(props) => props.size}px;
  flex-shrink: 0;
`

SpaceSeparator.defaultProps = {
  size: 10,
}

export const SpaceSeparatorNext = styled.div<{
  width?: string
  height?: string
}>`
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
  flex-shrink: 0;
`

export const SpaceGrow = styled.div`
  ${flexGrowCss};
  min-width: 0;
`

export const bottomSeparatorCss = css`
  border-bottom: 1px solid #f2eeee;
`
