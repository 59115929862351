import React from 'react'

import { useRouteMatch } from 'react-router'

import { WrapperLayout } from 'components/layout/WrapperLayout'
import { Replace } from 'components/system/Replace'
import { defineStartScreenPath } from 'functions/defineStartScreenPath'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { AgreementLoadable } from '../Legal/Agreement/AgreementLoadable'

export const AgreementPage = () => {
  const match = useRouteMatch()

  const {
    authorized,
    startScreen,
    profileBanned,
    ipBlocked,
    trackBlocked,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      systemReducer: { startScreen },
      errorReducer: { profileBanned, ipBlocked, trackBlocked },
    }) => ({
      authorized,
      startScreen,
      profileBanned,
      ipBlocked,
      trackBlocked,
    })
  )

  if (authorized && !profileBanned && !ipBlocked && !trackBlocked) {
    return (
      <Replace
        to={mergeAllUrls(defineStartScreenPath(startScreen), match.url)}
        uid="AgreementPageAddBaseUrlRedirect"
      />
    )
  }

  return (
    <WrapperLayout>
      <AgreementLoadable redesign />
    </WrapperLayout>
  )
}
