import loadable from '@loadable/component'

import { AppRoute } from 'components/components.types'
import { withLoader } from 'components/system/loadable/withLoader'
import { mergeAllUrls } from 'functions/mergeAllUrls'

import { supportEdnaRedirectPath } from './SupportEdna.paths'

export const supportEdnaRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(supportEdnaRedirectPath),
    component: loadable(
      async () =>
        (await import('components/page/SupportEdna/SupportEdnaRedirect'))
          .SupportEdnaRedirect,
      withLoader
    ),
    exact: true,
    authorizedOnly: true,
  },
]
