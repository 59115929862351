import { changeLanguageApi } from 'api/settings/changeLanguageApi'
import { changeProfileVisibilityApi } from 'api/settings/changeProfileVisibilityApi'
import { Fields } from 'components/page/Settings/Fields'

export const findUpdateApiMethod = (field: string, value: string) => {
  switch (field) {
    case Fields.ProfileVisibilityStatus:
      return changeProfileVisibilityApi(value)
    case 'languageId':
      return changeLanguageApi(value)
  }
  throw new Error(`Can't find toggle API method for field "${field}"`)
}
