import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/ratings_v2.voting'

type Method = paths['/ratings/v2/voting/photos/{photoId}/like']
export type Result = Method['post']['responses']['200']['schema']
type Body = Method['post']['parameters']['body']['body']

export const photoLikeApi = (photoId: number, voteSource: number) =>
  postApi<Result, Body>(
    `/ratings/v2/voting/photos/${photoId}/like` as keyof paths,
    {
      voteSource: voteSource,
    }
  )
