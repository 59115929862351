import { fetchApi } from 'api/fetchApi'
import { paths } from 'api/generated/incognito'
import { NodeHeaders } from 'api/NodeHeaders'

type Method = paths['/incognito/{aid}/relation']['get']
export type Result = Method['responses']['200']['schema']

export const fetchIncognitoRelationStatusApi = (
  userId: number,
  headers?: NodeHeaders
) => {
  return fetchApi<Result>(`/incognito/${userId}/relation` as keyof paths, {
    headers,
  })
}
