import { Reducer } from 'redux'

import {
  requestOpenChatSupportFromSettingsAction,
  requestOpenChatSupportFromRedirectAction,
  REQUEST_OPEN_CHAT_SUPPORT_FROM_REDIRECT,
  REQUEST_OPEN_CHAT_SUPPORT_FROM_SETTINGS,
} from 'actions/edna/requestEdnaChatAction'
import {
  ADD_APPS_FLYER_USER_ID,
  addAppsFlyerUserIdAction,
} from 'actions/miscellaneous/addAppsFlyerUserIdAction'
import {
  SETTINGS_FILTER_PHOTO_VERIFICATION_ANNOUNCEMENT,
  settingsFilterPhotoVerificationAnnouncementAction,
} from 'actions/miscellaneous/settingsFilterPhotoVerificationAnnouncementAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

export const INITIAL_STATE = {
  lastAppsFlyerUserIdSentToServer: null as string | null,
  settingsFilterPhotoVerificationAnnouncement: null as Record<
    number,
    boolean
  > | null,
  openChatSupportFromSettings: false as boolean,
  openChatSupportFromRedirect: false as boolean,
} as const

export type MiscellaneousState = typeof INITIAL_STATE

/**
 * Для разных данных, которые сложно или нет смысла классифицировать.
 */
export const miscellaneousReducer: Reducer<
  MiscellaneousState,
  | ReturnType<typeof addAppsFlyerUserIdAction>
  | ReturnType<typeof settingsFilterPhotoVerificationAnnouncementAction>
  | ReturnType<typeof requestOpenChatSupportFromSettingsAction>
  | ReturnType<typeof requestOpenChatSupportFromRedirectAction>
> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_APPS_FLYER_USER_ID:
      return defaultPromiseReducer(state, action, undefined, () => ({
        lastAppsFlyerUserIdSentToServer: action.webId,
      }))

    case SETTINGS_FILTER_PHOTO_VERIFICATION_ANNOUNCEMENT:
      return {
        ...state,
        settingsFilterPhotoVerificationAnnouncement: {
          ...state.settingsFilterPhotoVerificationAnnouncement,
          [action.profileId]: true,
        },
      }

    case REQUEST_OPEN_CHAT_SUPPORT_FROM_SETTINGS:
      return {
        ...state,
        openChatSupportFromSettings: action.value,
      }

    case REQUEST_OPEN_CHAT_SUPPORT_FROM_REDIRECT:
      return {
        ...state,
        openChatSupportFromRedirect: action.value,
      }

    default:
      return state
  }
}
