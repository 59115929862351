import React from 'react'

import { useParams } from 'react-router'

import { profilePath, ProfileRouteParams } from 'components/paths'
import RedirectWithStatus from 'components/system/RedirectWithStatus'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const profileRedirectPath = '/users/:userId(\\d+)'

export const ProfileRedirect = () => {
  const { userId } = useParams<ProfileRouteParams>()

  return (
    <RedirectWithStatus
      uid="profile-redirect-with-status"
      status={301}
      to={mergeAllUrls(profilePath, userId)}
    />
  )
}
