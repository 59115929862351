import { postApi } from 'api/fetchApi'
import { paths } from 'api/generated/auth'
import { NodeHeaders } from 'api/NodeHeaders'

type Method = paths['/auth/mailrutoken']['post']

export const authorizeByTokenAtMailruApi = async (
  token: string,
  headers?: NodeHeaders
) => {
  return await postApi<void, Method['parameters']['body']['body']>(
    '/auth/mailrutoken',
    {
      token,
    },
    { headers }
  )
}
