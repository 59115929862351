import React, { FC } from 'react'

import { ModalLayoutIndex } from 'components/layout/ModalLayout/ModalLayoutIndex'

import { GeoPermissionRequest } from './GeoPermissionRequest'

export const GeoPermissionRequestModal: FC = () => {
  return (
    <ModalLayoutIndex>
      <GeoPermissionRequest
        // Хардкод.
        // На данный момент, этот модал можно открыть только на странице профиля
        pageName="profile"
      />
    </ModalLayoutIndex>
  )
}
