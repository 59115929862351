import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { fetchWebSocketUrlAction } from 'actions/webSocketAction'
import { hasWebSocketSupport } from 'common/constants'
import { WebSocketConnection } from 'components/system/WebSocketConnection'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { clientStatisticReducer } from 'reducers/clientStatisticReducer'
import { RootState } from 'reducers/RootState'
import { webSocketReducer } from 'reducers/webSocketReducer'

export const WebSocketStart = () => {
  useReducersInsert({
    webSocket: webSocketReducer,
    clientStatistic: clientStatisticReducer,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchWebSocketUrlAction())
  }, [dispatch])

  const { urlWebSocket } = useShallowEqualSelector(
    ({ webSocket: { urlWebSocket } }: RootState) => ({
      urlWebSocket,
    })
  )

  if (hasWebSocketSupport() && urlWebSocket) {
    return <WebSocketConnection />
  }

  return null
}
