import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const LoveMailRuTokenLoadable = loadable(
  async () => (await import('./LoveMailRuToken')).LoveMailRuToken,
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Love Mail Ru split error', error)
      }

      return undefined
    })(defaultLoadableProps),
  }
)
