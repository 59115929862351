import { AppRoute } from 'components/components.types'
import { PwaOfflineLoadable } from 'components/page/Pwa/OfflinePageLoadable'
import { shellPath } from 'components/page/Pwa/Pwa.paths'
import { PwaSettingsLoadable } from 'components/page/Pwa/PwaSettingsLoadable'
import { ShellLoadable } from 'components/page/Pwa/ShellLoadable'
import { pwaOfflinePath, pwaSettingsPath } from 'components/routes/pwa.path'

/**
 * Роуты связанные с PWA.
 */
export const pwaRoutes: AppRoute[] = [
  {
    path: shellPath,
    component: ShellLoadable,
  },
  {
    path: pwaOfflinePath,
    component: PwaOfflineLoadable,
  },
  {
    path: pwaSettingsPath,
    component: PwaSettingsLoadable,
  },
]
