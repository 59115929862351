import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { logoutAction } from 'actions/authorization/logoutAction'
import { authorizeBySecretApi } from 'api/authorization/authorizeBySecretApi'

export const AUTHORIZE_BY_SECRET = 'AUTHORIZE_BY_SECRET' as const

/**
 * Неподдержанный предположительно редкий сценарий:
 * 1. Разлогиниваемся, потому что мы авторизованы другим пользователем
 * 2. Не авторизовываемся из-за какой-либо ошибки.
 * Остаемся неавторизованными.
 *
 * @param uid
 * @param secret
 * @param manual Нужно для того чтобы вручную обрабатывать ошибку, не писать в стейт.
 * Иначе происходил рейз кондишн редиректов: либо в корень, либо реальная ошибка.
 */
export const authorizeBySecretAction = (
  uid: string,
  secret: string,
  manual: boolean = false
) => async (dispatch: AppDispatchNext, getState: AppGetState) => {
  const { authorizationReducer } = getState()

  /**
   * Не выполняем логаут
   * если id из письма совпадает с авторизованным.
   * https://youtrack.mamba.ru/issue/M-4322#focus=Comments-4-33397.0-0
   */
  if (
    authorizationReducer.authorized &&
    authorizationReducer.profile?.id === Number(uid)
  ) {
    return Promise.resolve()
  }

  if (
    authorizationReducer.authorized &&
    authorizationReducer.profile?.id !== Number(uid)
  ) {
    await dispatch(logoutAction())
  }

  return dispatch(authorizeBySecretPlainAction(uid, secret, manual))
}

export const authorizeBySecretPlainAction = (
  uid: string,
  secret: string,
  manual: boolean
) => ({
  type: AUTHORIZE_BY_SECRET,
  promise: () => authorizeBySecretApi(uid, secret),
  manual,
})
