import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const YandexAdsLoadable = loadable(
  () =>
    import('./YandexAds').catch((ignoredError) => {
      console.info('Yandex not loaded')

      /**
       * У части юзеров режется модуль YandexAds блок как реклама.
       * Повторить в AdBlock, AdBlock Plus, uBlock Origin не получилось.
       * Повторил только блокировкой ресурса через отладку Хрома.
       *
       * Нормального способа как перехватить ошибку не нашел:
       *
       * 1. Вебпак не ругается, в доках нет описание по ошибке (видимо не нужно,
       * т.к. промис)
       *
       * 2. В loadable похоже случай с ошибкой сети не предусмотрен,
       * в доке так же не нашел.
       *
       * В итоге имитирую модуль для лоадабла:
       */
      return {
        __esModule: true,
        default: () => null,
      }
    }),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('YandexAds split error', error)
      }

      return undefined
    })(defaultLoadableProps),
  }
)
