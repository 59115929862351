import {
  PhotoLinePhotoComet,
  SocketData,
} from 'actions/socket/socketReceiveDataAction'
import { PhotoLineItem } from 'reducers/photoLine/photoLine.types'

let uniqueKey = 0

export const fromComet = ({
  content,
}: SocketData<PhotoLinePhotoComet>): PhotoLineItem => {
  const { id, user, message, photo, num } = content

  if (user) {
    const {
      id: authorId,
      name,
      age,
      isVip,
      photoCount,
      geo,
      isOnline,
      hasVerifiedPhoto,
    } = user

    return {
      id: `${++uniqueKey}-${id}`,
      authorId,
      name: name.orig,
      age,
      photoCount,
      vip: isVip,
      photo: photo.urlSquareSmall,
      message: message.orig,
      amount: num,
      locationWithLocales: geo,
      online: isOnline,
      hasVerifiedPhoto: hasVerifiedPhoto,
    }
  }

  // изменение текста
  return { id, message: message.orig }
}
