import { STATE } from 'common/constants'
import { AuthorizationForm } from 'reducers/authorization/AuthorizationForm'
import { RootState } from 'reducers/RootState'

import { loadStateFromGivenStorage } from './functions'
import { PartialRootState } from './store.types'

export const loadLocalStorageState = (): PartialRootState => {
  try {
    return loadStateFromGivenStorage(localStorage)
  } catch (error) {
    console.error('Local storage disabled', error)

    return {}
  }
}

export const saveStateToLocalStorage = (state: RootState) => {
  try {
    const partialState: PartialRootState = loadLocalStorageState()
    if (state.storeFrontGifts) {
      partialState.storeFrontGifts = {
        selectedGiftId: state.storeFrontGifts.selectedGiftId,
      }
    }
    if (state.comment) {
      partialState.comment = {
        commentText: state.comment.commentText,
        // commentIdTextMap: state.comment.commentIdTextMap,
      }
    }
    if (state.stepRegistration) {
      const {
        form,
        targetGender,
        age,
        gender,
        personHeight,
        personWeight,
        constitutions,
        datingGoal,
      } = state.stepRegistration
      partialState.stepRegistration = {
        form,
        gender,
        targetGender,
        age,
        personHeight,
        personWeight,
        constitutions,
        datingGoal,
      }
    }
    if (state.boarding) {
      const { startRegistrationButtonName } = state.boarding
      partialState.boarding = {
        startRegistrationButtonName,
      }
    }

    if (state.settingsReducer) {
      const { form } = state.settingsReducer
      const { phone } = form
      // Вытащим phoneNumberFull, чтобы не писать его в хранилище, потому что нам это не нужно
      const { phoneNumberFull, ..._phone } = phone

      partialState.settingsReducer = {
        form: {
          ...form,
          phone: { ..._phone },
          // Не пишем в хранилище все что связано с паролем
          password: '',
          passwordCheck: '',
          passwordNewCheck: '',
        },
      }
    }

    if (state.metric) {
      partialState.metric = state.metric
    }

    if (state.bannerReducer?.installAppBannerClosedDate) {
      partialState.bannerReducer = {
        ...partialState.bannerReducer,
        installAppBannerClosedDate:
          state.bannerReducer.installAppBannerClosedDate,
      }
    }

    if (state.bannerReducer?.installPwaBannerClosedDate) {
      partialState.bannerReducer = {
        ...partialState.bannerReducer,
        installPwaBannerClosedDate:
          state.bannerReducer.installPwaBannerClosedDate,
        installPwaBannerClosedTimes:
          state.bannerReducer.installPwaBannerClosedTimes,
      }
    }

    if (state.photoRatingReducer) {
      const {
        pastVotingPhotos,
        votedCounter,
        revertCounter,
        currentPromo,
        currentPromoIndex,
        likedCounter,
        lastIncognitoNoticeShownAtLikedCount,
        votingPhotos,
      } = state.photoRatingReducer

      if (!state.authorizationReducer.authorized) {
        partialState.photoRatingReducer = {
          pastVotingPhotos: [],
          votedCounter: 0,
          revertCounter: null,
          votingPhotos: [],
          currentPromoIndex: 0,
          currentPromo: null,
          likedCounter: 0,
          lastIncognitoNoticeShownAtLikedCount: 0,
          cachePastVotingPhotos: [],
        }
      } else {
        /**
         * Если мы ушли на несколько фотографии назад, то эти фотографии были добавлены в общий стек.
         * Если мы перезагружаем страницу, то нужно сохранить только те, на которые мы вернулись.
         * 1. Проверяем чему равен revertCounter
         * 2. Срезаем с votingPhotos, и сохраняем в localStorage
         * 3. После восстановление получим старые фото сразу + новые из запроса.
         */
        const _votingPhotos =
          revertCounter !== null ? votingPhotos.slice(0, revertCounter) : []

        partialState.photoRatingReducer = {
          pastVotingPhotos,
          votedCounter,
          revertCounter,
          votingPhotos: _votingPhotos,
          currentPromoIndex,
          currentPromo,
          likedCounter,
          lastIncognitoNoticeShownAtLikedCount,
          cachePastVotingPhotos: pastVotingPhotos,
        }
      }
    }

    partialState.promoCode = { aboutCode: state.promoCode.aboutCode }

    partialState.authorizationReducer = {
      lastAuthMethod: state.authorizationReducer.lastAuthMethod,
      lastClickedAuthVendor: state.authorizationReducer.lastClickedAuthVendor,
      form: {
        email: state.authorizationReducer.form.email,
      } as AuthorizationForm,
      redirectAfterAuthPath: state.authorizationReducer.redirectAfterAuthPath,
    }

    partialState.promo = {
      popupFeaturedPhotosX3ShownDate:
        state.promo.popupFeaturedPhotosX3ShownDate,
    }

    if (state.pushNotifications) {
      const {
        pushNotifications: {
          pushNotificationsToken,
          pushNotificationsRequestModalHiddenTillDate,
        },
      } = state

      partialState.pushNotifications = {
        pushNotificationsToken,
        pushNotificationsRequestModalHiddenTillDate,
      }
    }

    if (state.datingProfileReducer) {
      partialState.datingProfileReducer = {
        fieldNameForHistoryBack:
          state.datingProfileReducer.fieldNameForHistoryBack,
      }
    }

    if (state.draftMessages) {
      partialState.draftMessages = { ...state.draftMessages }
    }

    if (state.contactList) {
      partialState.contactList = {
        bannerDeletingTimeoutTs: state.contactList.bannerDeletingTimeoutTs,
      }
    }

    if (state.miscellaneous.lastAppsFlyerUserIdSentToServer) {
      partialState.miscellaneous = {
        ...partialState.miscellaneous,
        lastAppsFlyerUserIdSentToServer:
          state.miscellaneous.lastAppsFlyerUserIdSentToServer,
      }
    }

    if (state.geolocation.geolocationRequestHiddenDateTs) {
      partialState.geolocation = {
        ...partialState.geolocation,
        geolocationRequestHiddenDateTs:
          state.geolocation.geolocationRequestHiddenDateTs,
      }
    }

    if (
      state.geolocation.analyticsGeolocationAbTestRestrictionShown ||
      state.geolocation.analyticsGeolocationAbTestPermissionGranted
    ) {
      partialState.geolocation = {
        ...partialState.geolocation,
        analyticsGeolocationAbTestRestrictionShown:
          state.geolocation.analyticsGeolocationAbTestRestrictionShown,
        analyticsGeolocationAbTestPermissionGranted:
          state.geolocation.analyticsGeolocationAbTestPermissionGranted,
      }
    }

    if (state.miscellaneous.settingsFilterPhotoVerificationAnnouncement) {
      partialState.miscellaneous = {
        ...partialState.miscellaneous,
        settingsFilterPhotoVerificationAnnouncement:
          state.miscellaneous.settingsFilterPhotoVerificationAnnouncement,
      }
    }

    const serializedState = JSON.stringify(partialState)
    localStorage.setItem(STATE, serializedState)
  } catch (error) {
    // prettier-ignore
    console.error('Can\'t save state to local storage')
  }
}

export const mergeStateWithStateLocalStorage = (initialState: RootState) => {
  const {
    storeFrontGifts,
    stepRegistration,
    boarding,
    comment,
    foreignProfile,
    settingsReducer,
    metric,
    authorizationReducer,
    promoCode,
    pushNotifications,
    draftMessages,
    contactList,
    miscellaneous,
  } = initialState
  const partialState = loadLocalStorageState()
  const newState = {
    ...initialState,
  }
  if (storeFrontGifts && partialState.storeFrontGifts) {
    newState.storeFrontGifts = {
      ...storeFrontGifts,
      ...partialState.storeFrontGifts,
    }
  }

  if (stepRegistration && partialState.stepRegistration) {
    newState.stepRegistration = {
      ...stepRegistration,
      ...partialState.stepRegistration,
    }
  }

  if (boarding && partialState.boarding) {
    newState.boarding = {
      ...boarding,
      ...partialState.boarding,
    }
  }

  if (foreignProfile && partialState.foreignProfile) {
    newState.foreignProfile = {
      ...foreignProfile,
      ...partialState.foreignProfile,
    }
  }

  if (settingsReducer && partialState.settingsReducer) {
    newState.settingsReducer = {
      ...settingsReducer,
      ...partialState.settingsReducer,
      form: {
        ...partialState.settingsReducer.form!,
        // Не восстанавливаем все что связано с паролем, если вдруг что-то есть в хранилище
        password: '',
        passwordCheck: '',
        passwordNewCheck: '',
      },
    }
  }

  if (metric && partialState.metric) {
    newState.metric = {
      ...metric,
      ...partialState.metric,
    }
  }

  newState.authorizationReducer = {
    ...authorizationReducer,
    ...partialState.authorizationReducer,
    form: {
      ...authorizationReducer.form,
      ...(partialState.authorizationReducer?.form
        ? partialState.authorizationReducer.form
        : {}),
    },
  }

  if (partialState.promoCode?.aboutCode) {
    newState.promoCode = {
      ...promoCode,
      aboutCode: partialState.promoCode.aboutCode,
    }
  }

  if (partialState.promo) {
    newState.promo = {
      ...newState.promo,
      popupFeaturedPhotosX3ShownDate: partialState.promo
        .popupFeaturedPhotosX3ShownDate!,
    }
  }

  if (partialState.pushNotifications) {
    const {
      pushNotifications: {
        pushNotificationsToken,
        pushNotificationsRequestModalHiddenTillDate,
      },
    } = partialState

    newState.pushNotifications = {
      ...pushNotifications,
      pushNotificationsToken: pushNotificationsToken!,
      pushNotificationsRequestModalHiddenTillDate: pushNotificationsRequestModalHiddenTillDate!,
    }
  }

  if (draftMessages && partialState.draftMessages) {
    newState.draftMessages = {
      ...draftMessages,
      ...partialState.draftMessages,
    }
  }

  if (contactList && partialState.contactList) {
    newState.contactList = {
      ...contactList,
      ...partialState.contactList,
    }
  }

  if (miscellaneous && partialState.miscellaneous) {
    newState.miscellaneous = {
      ...miscellaneous,
      ...partialState.miscellaneous,
    }
  }

  if (
    partialState.geolocation?.analyticsGeolocationAbTestPermissionGranted ||
    partialState.geolocation?.analyticsGeolocationAbTestRestrictionShown
  ) {
    newState.geolocation = {
      ...initialState.geolocation,
      analyticsGeolocationAbTestPermissionGranted: partialState.geolocation
        .analyticsGeolocationAbTestPermissionGranted!,
      analyticsGeolocationAbTestRestrictionShown: partialState.geolocation
        .analyticsGeolocationAbTestRestrictionShown!,
    }
  }

  return newState
}
