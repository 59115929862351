import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

import { DefaultLoader } from '../../presentational/AnimatedWatch/AnimatedWatch'

export const ChatRedirectEntryLoadable = loadable(
  () => import('./ChatRedirectEntry'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Chat redirect Entry split error', error)
      }
      return <DefaultLoader />
    })(defaultLoadableProps),
  }
)
