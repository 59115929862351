import { AppRoute } from 'components/components.types'
import {
  eventListFilterPath,
  eventListPath,
} from 'components/page/EventList/EventList.paths'
import { EventListLoadable } from 'components/page/EventList/EventListLoadable'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const eventListRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(eventListPath, eventListFilterPath),
    component: EventListLoadable,
    authorizedOnly: true,
    exact: false,
  },
]
