import { AsyncAction, AsyncThunkAction } from 'actions/actions.types'
import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import { photoLikeApi } from 'api/rating/likePhotoApi'
import { voteSourcePhotoViewer } from 'common/constants'
import {
  ratingVotingLimitExceededId,
  ratingVotingVipLimitExceeded,
} from 'components/uninotice/uniNoticeIdList'

export const LIKE_PHOTO_VIEWER = 'LIKE_PHOTO_VIEWER' as const

export const likePhotoPlainAction = (photoId: number) => ({
  type: LIKE_PHOTO_VIEWER,
  photoId,
})

export const LIKE_PHOTO_VIEWER_API = 'LIKE_PHOTO_VIEWER_API' as const

interface LikePhotoAction extends AsyncAction {
  type: typeof LIKE_PHOTO_VIEWER_API
}

export const likePhotoAction = (photoId: number): LikePhotoAction => ({
  type: LIKE_PHOTO_VIEWER_API,
  promise: () => photoLikeApi(photoId, voteSourcePhotoViewer),
})

export const likePhotoActionWithHandlerError = (
  photoId: number
): AsyncThunkAction => async (dispatch) => {
  const { error } = await dispatch(likePhotoAction(photoId))

  if (error?.code === 'vip_limit_exceeded') {
    return dispatch(
      fetchUniNoticeAction(ratingVotingVipLimitExceeded, {
        limit: error.data?.limit,
      })
    )
  }

  if (error?.code === 'limit_exceeded') {
    return dispatch(
      fetchUniNoticeAction(ratingVotingLimitExceededId, {
        limit: error.data!.limit,
      })
    )
  }

  return dispatch(likePhotoPlainAction(photoId))
}

export const RESET_PHOTO_LIKE_UPDATE = 'RESET_PHOTO_LIKE_UPDATE' as const

export const resetPhotoLikeUpdatePlainAction = () => ({
  type: RESET_PHOTO_LIKE_UPDATE,
})

export type PhotoViewerLikePhotoActionTypes =
  | ReturnType<typeof likePhotoPlainAction>
  | ReturnType<typeof resetPhotoLikeUpdatePlainAction>
  | ReturnType<typeof likePhotoAction>
