import { LOCALES_REGEXP } from 'common-constants/locale'
import { mergeAllUrls } from 'functions/mergeAllUrls'

const localeWithSlashRegExp = new RegExp(`^/(${LOCALES_REGEXP})/`, 'gi')
const localeOnlyRegExp = new RegExp(`^/(${LOCALES_REGEXP})$`, 'gi')

/**
 * @param url
 * для этого надо проверить как поведет себя функция в местах её использования.
 * @deprecated использовать removeLocaleFromPath2
 */
export const removeLocaleFromPath = (url: string) => {
  if (localeWithSlashRegExp.test(url)) {
    return url.replace(localeWithSlashRegExp, '')
  }
  if (localeOnlyRegExp.test(url)) {
    return url.replace(localeOnlyRegExp, '')
  }
  return url
}

/**
 * Делает то же самое, но оставляет слеш в начале.
 * @param url
 */
export const removeLocaleFromPath2 = (url: string) => {
  if (localeWithSlashRegExp.test(url)) {
    return url.replace(localeWithSlashRegExp, '/')
  }
  if (localeOnlyRegExp.test(url)) {
    return url.replace(localeOnlyRegExp, '/')
  }
  return url
}

export const createNewLanguagePath = (currentUrl, newLanguage) => {
  const cleanPathname = removeLocaleFromPath(currentUrl)
  // console.log({newLanguage, currentUrl, cleanPathname, newUrl, LOCALES_REGEXP})
  return mergeAllUrls(newLanguage, cleanPathname)
}

export const extractLocaleFromPath = (path: string) => {
  return path.match(localeWithSlashRegExp)
}
