import { Reducer } from 'redux'

import {
  DELETE_PHOTO,
  deletePhotoAction,
  FETCH_ATTACH_PHOTOS,
  FETCH_INCOGNITO_RELATION,
  FETCH_INCOGNITO_STATUS,
  FETCH_PHOTO_VIEWER_PHOTOS,
  FETCH_PHOTOS_PROFILE,
  PHOTOS_LOADING,
  PhotoViewerActionTypes,
  RESET_PHOTO_VIEWER,
  SET_NEW_MAIN_PHOTO,
} from 'actions/photo/photoViewerAction'
import {
  LIKE_PHOTO_VIEWER,
  LIKE_PHOTO_VIEWER_API,
  PhotoViewerLikePhotoActionTypes,
} from 'actions/photo/photoViewerLikePhotoAction'
import {
  RESTORE_DELETED_PHOTO,
  restoreDeletedPhotoPlainAction,
} from 'actions/photo/restoreDeletedPhotoAction'
import {
  RESTORE_INITIAL_STATE,
  RestoreInitialStateAction,
} from 'actions/system/restoreInitialStateAction'
import { Gender } from 'common-constants/api6.types'
import { initialStateReducer } from 'reducers/default/initialStateReducer'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { PhotoViewerState } from 'reducers/photo/PhotoViewerState'

import { changeDefaultPhoto } from './function/changeDefaultPhoto'
import { changeVotedPhoto } from './function/changeVotedPhoto'
import { mapAttachPhotos } from './function/mapAttachPhotos'

const initialState: PhotoViewerState = {
  /** @deprecated Нужно ориентироваться ан userId запрошенных фото */
  isLoading: true,
  photos: [],
  userId: 0,
  isIncognitoStatus: false,
  isCurrentUserCanSeePhotos: true,
  name: '',
  gender: null,
  lockedLike: false,
  isRestoreDeletedPhoto: false,
  isDeleted: false,
}

export const photoViewerReducer: Reducer<
  PhotoViewerState,
  | PhotoViewerActionTypes
  | PhotoViewerLikePhotoActionTypes
  | RestoreInitialStateAction
  | ReturnType<typeof restoreDeletedPhotoPlainAction>
  | ReturnType<typeof deletePhotoAction>
> = (state = initialState, action): PhotoViewerState => {
  switch (action.type) {
    case PHOTOS_LOADING:
      return { ...state, isLoading: action.value }

    case FETCH_PHOTO_VIEWER_PHOTOS:
      return defaultPromiseReducer(
        state,
        action,
        () => ({ photos: [] }),
        ({ photos, totalPhotos }) => {
          return {
            photos,
            isDeleted: false,
            isLoading: false,
            userId: action.userId,
            totalPhotos: totalPhotos,
            isRestoreDeletedPhoto: false,
          }
        }
      )

    case FETCH_PHOTOS_PROFILE:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        ({ name, gender }) => {
          return { name, gender: gender as Gender }
        }
      )

    case FETCH_INCOGNITO_RELATION:
      return defaultPromiseReducer(state, action, undefined, ({ canView }) => ({
        isCurrentUserCanSeePhotos: canView,
      }))

    case FETCH_INCOGNITO_STATUS:
      return defaultPromiseReducer(
        state,
        action,
        undefined,
        ({ incognito }) => {
          return {
            isIncognitoStatus: incognito,
          }
        }
      )

    case SET_NEW_MAIN_PHOTO:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          isLoading: false,
        }),
        () => ({
          isLoading: false,
          photos: changeDefaultPhoto(state.photos, action.photoId),
        }),
        () => ({
          isLoading: false,
        })
      )

    case RESTORE_DELETED_PHOTO:
      return defaultPromiseReducer(state, action, undefined, () => ({
        isRestoreDeletedPhoto: true,
      }))

    case LIKE_PHOTO_VIEWER_API:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          lockedLike: true,
        }),
        () => ({ lockedLike: false }),
        () => ({ lockedLike: false })
      )

    case LIKE_PHOTO_VIEWER:
      return {
        ...state,
        photos: changeVotedPhoto(state.photos, action.photoId),
      }

    case FETCH_ATTACH_PHOTOS:
      return {
        ...state,
        photos: mapAttachPhotos(action.result.photos),
        totalPhotos: action.result.photos.length,
      }

    case RESET_PHOTO_VIEWER:
      return {
        ...initialState,
      }

    case DELETE_PHOTO:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          isDeleted: true,
        }),
        () => ({
          isDeleted: false,
        })
      )

    case RESTORE_INITIAL_STATE:
      return initialStateReducer(state, action, 'photoViewerReducer')

    default:
      return state
  }
}
