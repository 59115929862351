import React, { FC } from 'react'

import loadable from '@loadable/component'

import { LogoutModalLoadable } from 'components/layout/MambaLayout/LogoutModalLoadable'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { useRedesign } from 'hooks/useRedesign'

export const Logout: FC = () => {
  const redesign = useRedesign()

  if (redesign) {
    return <ConfirmExitLoadable />
  }

  return <LogoutModalLoadable />
}

const ConfirmExitLoadable = loadable(
  async () => (await import('../Settings/ConfirmExit')).ConfirmExit,
  {
    fallback: <DefaultLoader />,
  }
)
