import React, { FC, ReactNode, MouseEvent } from 'react'

import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { goBackStorefrontAction } from 'actions/route/routeAction'
import { textOverflowStyles } from 'common/styles/textOverflow'
import { BackSvg } from 'components/presentational/svg/BackSvg'
import { CloseSvg } from 'components/presentational/svg/CloseSvg'

import { media } from './index'
import { PlainLink, PlainRouterLink } from './link'
import { SpaceSeparator } from './separator'

export const Panel = styled.nav<{ $withoutTitle: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 20px 20px;
  min-height: 44px;
  box-sizing: border-box;
  ${media.phone`
    padding: 8px 10px;
  `};

  ${(props) =>
    props.$withoutTitle &&
    `
    border-bottom-color: transparent;
  `};
`

export const ModalTitle = styled.div`
  font-size: 20px;
  max-width: 300px;
  ${textOverflowStyles};
  margin: 0 10px;

  ${media.phone`
    font-size: 18px;
    margin: 0;    
  `};
`

export const ModalTitleBold = styled.h2`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const Action = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  width: 24px;
  height: 24px;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

/**
 * TODO отрефакторить в более простой код
 */
export const ModalNavigation: FC<{
  className?: string
  title?: ReactNode | string
  onBackClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  editFolder?: boolean
  /**
   * @deprecated
   */
  backRoute?: string
  backUrl?: string
  historyBack?: boolean
  action?: ReactNode
  icon?: ReactNode
  showIcon?: boolean
  withoutTitle?: boolean
  forceHideDummy?: boolean
}> = ({
  className,
  editFolder = false,
  title,
  onBackClick,
  backUrl,
  historyBack = false,
  action,
  icon,
  showIcon = true,
  withoutTitle = false,
  forceHideDummy = false,
}) => {
  const dispatch = useDispatch()

  if (editFolder) {
    return null
  }

  return (
    <Panel className={className} $withoutTitle={withoutTitle}>
      {showIcon && historyBack && !Boolean(onBackClick) && (
        <PlainLink
          data-name={'close-action'}
          onClick={(event) => {
            event.preventDefault()
            return dispatch(goBackStorefrontAction())
          }}
        >
          {icon || <BackSvg />}
        </PlainLink>
      )}
      {showIcon && Boolean(onBackClick) && (
        <PlainLink
          data-name={'close-action'}
          onClick={(event) => {
            event.preventDefault()

            onBackClick!(event)
          }}
        >
          {icon || <BackSvg />}
        </PlainLink>
      )}
      {showIcon && Boolean(backUrl) && (
        <PlainRouterLink to={backUrl} data-name={'close-action'}>
          {icon || <CloseSvg />}
        </PlainRouterLink>
      )}
      {!onBackClick && !backUrl && !historyBack && <SpaceSeparator size={24} />}
      <ModalTitle>{title}</ModalTitle>
      {Boolean(action) && (
        <Action>
          <Wrapper>{action}</Wrapper>
        </Action>
      )}
      {!action && <SpaceSeparator size={24} />}
    </Panel>
  )
}

export default ModalNavigation
