import { AsyncAction, AsyncThunkAction } from 'src/common/actions/actions.types'

import { fetchInitialPhotoLineApi } from 'api/photoLineApi'

export const FETCH_PHOTO_LINE = 'FETCH_PHOTO_LINE'

interface FetchPhotoLinePhotosAction
  extends AsyncAction<
    Awaited<ReturnType<typeof fetchInitialPhotoLineApi>>['result']
  > {
  type: typeof FETCH_PHOTO_LINE
}

export const fetchPhotoLinePhotosAction = (): AsyncThunkAction<void> => (
  dispatch
) => {
  dispatch({
    type: FETCH_PHOTO_LINE,
    promise: () => fetchInitialPhotoLineApi(),
  })
}

export const MOVE_PHOTO_LINE = 'MOVE_PHOTO_LINE'

interface MovePhotoLineAction {
  type: typeof MOVE_PHOTO_LINE
}

export const movePhotoLineAction = () => ({
  type: MOVE_PHOTO_LINE,
})

export const STOP_PHOTO_LINE = 'STOP_PHOTO_LINE'

interface StopPhotoLineAction {
  type: typeof STOP_PHOTO_LINE
}

export const stopPhotoLineAction = () => ({
  type: STOP_PHOTO_LINE,
})

export const maximumTransitionDelayMs = 1000
const transitionGapMs = 500

export const POP_DELAYED_PHOTO_LINE = 'POP_DELAYED_PHOTO_LINE'

interface PopDelayedPhotoLineAction {
  type: typeof POP_DELAYED_PHOTO_LINE
}

export const popDelayedPhotoLineAction = (): AsyncThunkAction => (
  dispatch,
  getState
) => {
  const {
    lockedPhotos,
    locked,
    movingPhotos,
    lastModified,
    delayedPhotos,
  } = getState().photoLineReducer
  const timeExpired =
    lastModified + maximumTransitionDelayMs + transitionGapMs <
    new Date().getTime()
  const hasLock = locked || movingPhotos
  const hasLockedPhotos = Boolean(lockedPhotos.length)
  if (!hasLockedPhotos && !hasLock && timeExpired && delayedPhotos.length) {
    dispatch({
      type: POP_DELAYED_PHOTO_LINE,
    })
  }
}

export const LOCK_PHOTO_LINE = 'LOCK_PHOTO_LINE'

interface ToggleLockPhotoLineAction {
  type: typeof LOCK_PHOTO_LINE
  locked: boolean
}

export const toggleLockPhotoLineAction = (
  locked: boolean
): AsyncThunkAction => (dispatch, getState) => {
  if (getState().photoLineReducer.locked !== locked) {
    return dispatch({
      type: LOCK_PHOTO_LINE,
      locked,
    })
  }
}

export const CLEAN_PHOTO_LINE = 'CLEAN_PHOTO_LINE'

interface CleanPhotoLineAction {
  type: typeof CLEAN_PHOTO_LINE
  photoId: number
}

export const cleanPhotoLineAction = (photoId: number | string) => ({
  type: CLEAN_PHOTO_LINE,
  photoId,
})

export const UPDATE_PHOTO_LINE_ITEM_WIDTH = 'UPDATE_PHOTO_LINE_ITEM_WIDTH'

interface UpdatePhotoLineItemWidthAction {
  type: typeof UPDATE_PHOTO_LINE_ITEM_WIDTH
  width: number
}

export const updatePhotoLineItemWidthAction = (width: number) => ({
  type: UPDATE_PHOTO_LINE_ITEM_WIDTH,
  width,
})

export const RESET_PHOTO_LINE = 'RESET_PHOTO_LINE'

interface ResetPhotoLineAction {
  type: typeof RESET_PHOTO_LINE
}

export const resetPhotoLineAction = () => ({
  type: RESET_PHOTO_LINE,
})

export type PhotoLineTypes =
  | ResetPhotoLineAction
  | UpdatePhotoLineItemWidthAction
  | CleanPhotoLineAction
  | ToggleLockPhotoLineAction
  | PopDelayedPhotoLineAction
  | StopPhotoLineAction
  | MovePhotoLineAction
  | FetchPhotoLinePhotosAction
