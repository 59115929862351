import React, { FC } from 'react'

import { supportFormPath } from 'components/page/Boarding/SupportForm/SupportForm.paths'
import { supportChatPath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const SupportRedirect: FC = () => {
  const { authorized } = useShallowEqualSelector(
    ({ authorizationReducer: { authorized } }) => ({
      authorized,
    })
  )

  return authorized ? (
    <Replace to={supportChatPath} uid="SupportRedirect-authorized" />
  ) : (
    <Replace to={supportFormPath} uid="SupportRedirect" />
  )
}
