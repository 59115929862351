import React, { FC, PropsWithChildren, useContext, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { createBaseUrlAction } from 'actions/system/createBaseUrlAction'
import { StaticContext } from 'components/layout/MambaLayout/Context'

export const CreateBaseUrl: FC<
  {
    uid?: string
    baseUrl: string
    basePath: string
    contextId: string
    secondary?: boolean
  } & PropsWithChildren
> = ({ uid, contextId: id, baseUrl, basePath, secondary = true, children }) => {
  const dispatch = useDispatch()
  const staticContext = useContext(StaticContext)
  useEffect(() => {
    // consoleLog('base url', { uid, baseUrl, secondary })
    dispatch(createBaseUrlAction({ uid, id, baseUrl, basePath, secondary }))
    // return () => {
    //   // dispatch(clearBaseUrlAction())
    // }
  }, [id, baseUrl, dispatch, secondary])

  if (staticContext) {
    // console.log('set static url', { uid, baseUrl, basePath, secondary })
    dispatch(createBaseUrlAction({ uid, id, baseUrl, basePath, secondary }))
  }

  return <>{children}</>
}
