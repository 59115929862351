import { AppRoute } from 'components/components.types'
import { userProfilePath } from 'components/paths'
import { LegacyDeletedServicesRedirect } from 'components/system/legacyServiceDelete/LegacyDeletedServicesRedirect'
import { mergeAllUrls } from 'functions/mergeAllUrls'

export const legacyDeletedServiceRoutes: AppRoute[] = [
  {
    path: mergeAllUrls(userProfilePath, '/diary/post/:postId(\\d+)'),
    component: LegacyDeletedServicesRedirect,
    exact: false,
  },
  {
    path: '/diary',
    component: LegacyDeletedServicesRedirect,
    exact: false,
  },
  {
    path: '/questions',
    component: LegacyDeletedServicesRedirect,
    exact: false,
  },
  {
    path: '/mb([0-9]+)/questions',
    component: LegacyDeletedServicesRedirect,
    exact: false,
  },
]
