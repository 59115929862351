import React, { FC } from 'react'

import { VoidHandler } from 'common/types'

export const OpenedEyeSvg: FC<{ size?: number; onClick?: VoidHandler }> = ({
  size,
  onClick,
  ...rest
}) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    x={size}
    onClick={onClick}
    viewBox="0 0 125 125"
  >
    <g
      transform="matrix(5.20833 0 0 5.20833 5.208 5.208)"
      fill="none"
      fillRule="evenodd"
    >
      <circle fill="#0079C0" cx="11" cy="11" r="11" />
      <g transform="translate(2 4)">
        <ellipse
          fill="#FFF"
          fillRule="nonzero"
          cx="9"
          cy="8.182"
          rx="2.909"
          ry="2.844"
        />
        <g stroke="#FFF" strokeWidth="1.6">
          <path d="M17.5 7.379h-2.278C13.126.873 5.01.632 2.778 7.379H.5" />
          <path
            d="M8.98 2.396v-1.8M13.035 3.805l1.25-1.295M4.873 3.936L3.623 2.64"
            strokeLinecap="square"
          />
        </g>
      </g>
    </g>
  </svg>
)
