import React, { useContext } from 'react'

import { LoadableComponent } from '@loadable/component'
import { History } from 'history'

import { AppStore } from 'common/store/store.types'
import { ErrorBoundaryWithRedirect } from 'components/system/ErrorBoundaryWithRedirect'
import { AppContext } from 'hooks/useReducersInsert'

export const withAppContextHoc = (
  uid: string,
  Loadable: LoadableComponent<{
    store: AppStore
    history: History
  }>
) => {
  return () => {
    const { store, history } = useContext(AppContext)

    return (
      <ErrorBoundaryWithRedirect uid={uid}>
        <Loadable store={store} history={history} />
      </ErrorBoundaryWithRedirect>
    )
  }
}
