import React, { FC } from 'react'

import { VoidHandler } from 'common/types'

export const ClosedEyeSvg: FC<{ size?: number; onClick?: VoidHandler }> = ({
  size,
  onClick,
  ...rest
}) => (
  <svg
    {...rest}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 125 125"
  >
    <g
      transform="matrix(5.20833 0 0 5.20833 5.208 5.208)"
      fill="none"
      fillRule="evenodd"
    >
      <circle fill="#0079C0" cx="11" cy="11" r="11" />
      <g stroke="#FFF" strokeWidth="1.6">
        <path d="M19.5 10.621h-2.278c-2.096 6.506-10.213 6.747-12.444 0H2.5" />
        <path
          d="M10.98 15.604v1.8M15.035 14.195l1.25 1.295M6.873 14.064l-1.25 1.295"
          strokeLinecap="square"
        />
      </g>
    </g>
  </svg>
)
