import React, { FC } from 'react'

import { TransparentButton } from 'components/presentational/button'

import { UniNoticeButtonClickHandler } from './button.types'
import { UniNoticeData } from '../UniNotice.constants'

export const PrimaryLink: FC<{
  actionId: string
  title: string
  data: UniNoticeData
  onClick: UniNoticeButtonClickHandler
}> = ({ actionId, title, data, onClick }) => {
  return (
    <TransparentButton
      data-name={`uninotice-${actionId}-secondary-action`}
      onClick={() => onClick(actionId, data)}
    >
      <span>{title}</span>
    </TransparentButton>
  )
}
