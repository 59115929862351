import React from 'react'

import loadable from '@loadable/component'
import { History } from 'history'

import { AppStore } from 'common/store/store.types'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { withAppContextHoc } from 'components/system/withAppContextHoc'
import { insertReducers } from 'hooks/useReducersInsert'

export const MainSearchSettingsLoadable = withAppContextHoc(
  'search-settings',
  loadable<{ store: AppStore; history: History }>(
    async (props) => {
      const module = await import('./MainSearchSettings')
      insertReducers(props.store, props.history, module.asyncReducers)
      return module.MainSearchSettings
    },
    {
      fallback: (({ error }) => {
        if (error) {
          console.error('Main search settings split error', error)

          if (process.env.browser) {
            const mainSearchNode = document.querySelector(
              '.js-search-form-wrapper '
            )
            if (mainSearchNode) {
              mainSearchNode.classList.remove('g_hidden')
            }
          }
        }
        return <DefaultLoader />
      })(defaultLoadableProps),
      ssr: false,
    }
  )
)
