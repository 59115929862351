import { IncomingHttpHeaders } from 'http'

import { ApiResult } from 'api/fetchApi'

export const fakeAdsApi = async (adsUrl: string): Promise<ApiResult> => {
  const response = await fetch(adsUrl)

  return {
    headers: response.headers,
    ok: response.ok,
    status: response.status,
  }
}
