/* eslint-disable max-lines */
import React, {
  ComponentPropsWithoutRef,
  CSSProperties,
  FC,
  ReactNode,
} from 'react'

import { Link } from 'react-router-dom'
import styled, { css, DefaultTheme, ThemeProvider } from 'styled-components'
import { v4 as uuidV4 } from 'uuid'

import { disabledColor, fontFamily, white } from 'common/constants'
import { Colors } from 'common-constants/colors'
import { AppClickHandler } from 'components/presentational/input'

import { AnimatedWatch } from './AnimatedWatch/AnimatedWatch'
import { FormElement } from './form'
import {
  linkColor,
  linkHoverColor,
  linkTransparentColor,
  linkTransparentHoverColor,
} from './link'

export const BaseButtonStyle = css``

type BaseButtonTypes = {
  replace?: boolean
  disabled?: boolean
  loading?: boolean
  $loading?: boolean
  $borderColor?: string
  type?: string
  theme: ButtonTheme
}

const baseButtonCss = css<BaseButtonTypes>`
  display: flex;
  flex-direction: row;
  font-family: ${fontFamily};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.background};
  color: ${(props) =>
    props.$loading ? 'rgba(255, 255, 255, 0)' : props.theme.color};
  border-width: 0;
  // TODO такой кастомизации padding и fontSize не должно быть!
  // кнопка должна быть единообразной
  padding: ${(props) => props.theme.padding || padding};
  font-size: ${(props) => props.theme.fontSize || fontSize}px;
  border-radius: ${(props) => props.theme.borderRadius || borderRadius}px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all ease 200ms;
  text-decoration: none;
  -webkit-appearance: none;

  ${(props) =>
    props.$borderColor &&
    `
    border: solid 1px ${props.$borderColor};
  `};

  &:hover {
    background-color: ${(props) => props.theme.hoverBackground};
    color: ${(props) =>
      props.loading ? 'rgba(255, 255, 255, 0)' : props.theme.hoverColor};
  }

  //TODO осталось для совместимости других кнопок
  &:active,
  &:focus {
    box-shadow: ${(props) =>
      `0px 0px 15px 3px  ${props.theme.activeAndFocusColor}`};
    outline: none;
  }

  &:active {
    background-color: ${(props) => props.theme.activeBackground};
    color: ${(props) => props.theme.activeColor};
    outline: none;
  }

  &:focus {
    background-color: ${(props) => props.theme.focusBackground};
    color: ${(props) => props.theme.focusColor};
    box-shadow: ${(props) =>
      `0px 0px 15px 3px  ${props.theme.focusBackground}`};
    outline: none;
  }

  &[disabled],
  ${FormElement}:invalid &[type='submit'] {
    color: ${(props) => props.theme.disabledColor};
    background-color: ${(props) => props.theme.disabledBackground};
    opacity: 1;

    path {
      fill: ${(props) => props.theme.disabledColor};
    }

    &:hover {
      background-color: ${(props) => props.theme.background};
      color: ${(props) => props.theme.disabledColor};
      cursor: not-allowed;
    }
  }
`

const DECREASE_COLOR_NUMBER = 15
const padding = '15px'
const fontSize = 15
const borderRadius = 10
const InputButton = styled.input<BaseButtonTypes>`
  ${baseButtonCss}
`
InputButton.defaultProps = {
  $loading: false,
}

const LinkButton = styled(Link)<BaseButtonTypes>`
  ${baseButtonCss}
`

export const ExternalLinkButton = styled.a<BaseButtonTypes>`
  ${baseButtonCss}
`
export const PlainButton = styled.button<BaseButtonTypes>`
  ${baseButtonCss}
`

export const Wrapper = styled.div<{ theme: { display?: string } }>`
  display: ${(props) => (props.theme.display ? props.theme.display : 'flex')};
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
`

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export type BaseButtonProps = ComponentPropsWithoutRef<typeof BaseButton>

export const BaseButton: FC<{
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  value?: string
  hrefExternal?: string
  to?:
    | string
    | {
        pathname: string
        cameFrom: string
      }
  externalHref?: string
  href?: string
  target?: string
  onClick?: AppClickHandler
  children?: ReactNode
  style?: CSSProperties
  disabled?: boolean
  className?: string
  replace?: boolean
  borderColor?: string
  ['data-name']?: string
  as?: string
  rel?: string
}> = ({
  loading,
  type,
  value,
  hrefExternal,
  to,
  externalHref,
  href,
  target,
  onClick,
  children,
  style,
  disabled,
  className,
  replace = false,
  borderColor,
  as,
  ['data-name']: dataName,
}) => {
  let component

  const uniqueKey = uuidV4().substring(0, 8)
  if (type === 'submit') {
    component = (
      <InputButton
        className={className}
        type={type}
        value={value}
        $loading={loading}
        style={style}
        data-name={dataName}
        disabled={disabled}
        $borderColor={borderColor}
        key={uniqueKey}
        replace={replace}
        as={as as any}
        children={children}
      />
    )
  } else if (to) {
    component = (
      <LinkButton
        onClick={onClick}
        to={to}
        $loading={loading}
        style={style}
        className={className}
        data-name={dataName}
        disabled={disabled}
        $borderColor={borderColor}
        key={uniqueKey}
        replace={replace}
      >
        {children}
      </LinkButton>
    )
  } else {
    if (href) {
      return (
        <ExternalLinkButton
          key={uniqueKey}
          href={href}
          target={target}
          style={style}
          onClick={onClick}
          className={className}
          data-name={dataName}
          $loading={loading}
          $borderColor={borderColor}
        >
          {children}
        </ExternalLinkButton>
      )
    } else if (hrefExternal) {
      component = (
        <ExternalLinkButton
          href={hrefExternal}
          $loading={loading}
          style={style}
          data-name={dataName}
          $borderColor={borderColor}
          key={uniqueKey}
        >
          {children}
        </ExternalLinkButton>
      )
    } else if (onClick) {
      component = (
        <PlainButton
          className={className}
          onClick={onClick}
          $loading={loading}
          style={style}
          data-name={dataName}
          disabled={disabled}
          type={type}
          $borderColor={borderColor}
          key={uniqueKey}
        >
          {children}
        </PlainButton>
      )
    } else if (externalHref) {
      component = (
        <ExternalLinkButton
          key={uniqueKey}
          href={externalHref}
          style={style}
          data-name={dataName}
          $loading={loading}
          $borderColor={borderColor}
        >
          {children}
        </ExternalLinkButton>
      )
    } else {
      throw new Error('Button type is not supported')
    }
  }

  return (
    <Wrapper>
      {component}
      {loading && (
        <Loader>
          <AnimatedWatch
            color={'white'}
            colorMambaPreloader={'white'}
            size={24}
          />
        </Loader>
      )}
    </Wrapper>
  )
}

const primaryColor: ButtonTheme = {
  background: '#f56323',
  hoverBackground: '#e75110',
  color: 'white',
  hoverColor: 'white',
  disabledColor: 'rgba(255, 255, 255, 0.4)',
  activeAndFocusColor: 'rgba(245, 99, 35, 0.5)',
}

export const Button: FC<BaseButtonProps> = (props) => (
  <ThemeProvider theme={(primaryColor as unknown) as DefaultTheme}>
    <BaseButton {...props} />
  </ThemeProvider>
)

export const secondaryColor: ButtonTheme = {
  background: 'transparent',
  hoverBackground: 'transparent',
  color: Colors.link,
  hoverColor: Colors.linkHover,
  disabledColor: disabledColor,
}

export const SecondaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider theme={({ ...secondaryColor } as unknown) as DefaultTheme}>
    <BaseButton {...props} />
  </ThemeProvider>
)

export const MediumSecondaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={
      ({
        background: 'transparent',
        hoverBackground: 'transparent',
        color: Colors.link,
        hoverColor: Colors.linkHover,
        disabledColor: disabledColor,
        padding: '9px 15px',
        fontSize: '12',
        borderRadius: '5',
      } as unknown) as DefaultTheme
    }
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const TransparentButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={
      ({
        background: 'transparent',
        hoverBackground: 'transparent',
        color: linkTransparentColor,
        hoverColor: linkTransparentHoverColor,
        disabledColor: 'transparent',
      } as unknown) as DefaultTheme
    }
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const ordinaryColor: ButtonTheme = {
  background: Colors.link,
  hoverBackground: Colors.linkHover,
  color: 'white',
  hoverColor: 'white',
  disabledColor: 'rgba(255, 255, 255, 0.4)',
  activeAndFocusColor: 'rgba(70, 170, 233, 0.5)',
}

export const secondaryOrdinaryColor: ButtonTheme = {
  background: 'transparent',
  hoverBackground: 'transparent',
  color: 'white',
  hoverColor: 'white',
  disabledColor: 'transparent',
}

export const OrdinaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider theme={(ordinaryColor as unknown) as DefaultTheme}>
    <BaseButton {...props} />
  </ThemeProvider>
)

export const MediumOrdinaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={
      ({
        background: Colors.link,
        hoverBackground: Colors.linkHover,
        color: 'white',
        hoverColor: 'white',
        disabledColor: 'rgba(255, 255, 255, 0.4)',
        activeAndFocusColor: 'rgba(70, 170, 233, 0.5)',
        padding: '9px 15px',
        fontSize: '12',
        borderRadius: '5',
      } as unknown) as DefaultTheme
    }
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const SecondaryOrdinaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={
      ({
        ...secondaryOrdinaryColor,
      } as unknown) as DefaultTheme
    }
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const calculateAllColorState = (rgb: string) => {
  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ''
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbArray = rgb.substr(4).split(')')[0].split(sep)

  const rgbNumber = rgbArray.map((item) => Number(item))

  const hoverColor = transformColor(rgbNumber)

  const activeColor = transformColor(hoverColor)

  const disableAlphaChannelColor = '0.4'
  const focusAlphaChannelColor = '0.5'

  return {
    normalColor: rgb,
    hoverColor: `rgb(${hoverColor[0]},${hoverColor[1]},${hoverColor[2]})`,

    activeColor: `rgb(${activeColor[0]},${activeColor[1]},${activeColor[2]})`,

    disableColor: `rgba(${rgbNumber[0]},${rgbNumber[1]}, ${rgbNumber[2]}, ${disableAlphaChannelColor})`,

    focusColor: `rgba(${rgbNumber[0]},${rgbNumber[1]},${rgbNumber[2]},  ${focusAlphaChannelColor})`,
  }
}

export const transformColor = (arrayColor: number[]) =>
  arrayColor.map((color) => {
    const transformColor = color - DECREASE_COLOR_NUMBER
    if (transformColor < DECREASE_COLOR_NUMBER) {
      return 0
    }
    return transformColor
  })

export const CustomColorButton: FC<
  BaseButtonProps & { color: string; colorText?: string }
> = (props) => {
  const colorState = calculateAllColorState(props.color)
  return (
    <ThemeProvider
      theme={
        ({
          background: colorState.normalColor,
          hoverBackground: colorState.hoverColor,
          activeBackground: colorState.hoverColor,
          color: props.colorText || white,
          hoverColor: props.colorText || white,
          disabledColor: colorState.disableColor,
          activeAndFocusColor: colorState.hoverColor,
        } as unknown) as DefaultTheme
      }
    >
      <BaseButton {...props} />
    </ThemeProvider>
  )
}

const smallSize: ButtonTheme = {
  display: 'inline-flex',
  fontSize: 10,
  padding: '9px 15px',
  borderRadius: 5,
}

export const SmallSecondaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={({ ...smallSize, ...secondaryColor } as unknown) as DefaultTheme}
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const SmallOrdinaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={({ ...smallSize, ...ordinaryColor } as unknown) as DefaultTheme}
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

export const SmallPrimaryButton: FC<BaseButtonProps> = (props) => (
  <ThemeProvider
    theme={({ ...primaryColor, ...smallSize } as unknown) as DefaultTheme}
  >
    <BaseButton {...props} />
  </ThemeProvider>
)

type ButtonTheme = {
  display?: string
  fontSize?: number
  padding?: string
  background?: string
  hoverBackground?: string
  activeBackground?: string
  color?: string
  hoverColor?: string
  disabledColor?: string
  activeAndFocusColor?: string
  borderRadius?: number
  activeColor?: string
  focusBackground?: string
  focusColor?: string
  disabledBackground?: string
}
