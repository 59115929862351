import loadable from '@loadable/component'

import {
  deleteProfilePath,
  finalProfileDeleteFullPath,
} from 'components/page/DeleteProfile/DeleteProfile.paths'
import { DeleteProfileLoadable } from 'components/page/DeleteProfile/DeleteProfileLoadable'

export const deleteProfileRoutes = [
  {
    path: deleteProfilePath,
    component: DeleteProfileLoadable,
    authorizedOnly: true,
    exact: false,
  },
  {
    path: finalProfileDeleteFullPath,
    component: loadable(() =>
      import('components/page/DeleteProfile/DeleteProfileFinal')
    ),
    authorizedOnly: true,
    exact: false,
  },
]
