import React, { FC } from 'react'

import loadable from '@loadable/component'

import { androidOsType, iOsOsType } from 'common-constants/userAgent'
import { AlertPaymentLoadable } from 'components/page/Boarding/AlertPayment/AlertPaymentLoadable'
import { AgreementLoadable } from 'components/page/Legal/Agreement/AgreementLoadable'
import { withLoader } from 'components/system/loadable/withLoader'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const ConfidentialityLoadable = loadable(
  () => import('components/page/Legal/Confidentiality/ConfidentialityIndex'),
  withLoader
)

export const ConfidentialityPage: FC = () => (
  <ConfidentialityLoadable fade={false} />
)

export const PlainConfidentialityPage: FC = () => (
  <ConfidentialityLoadable plain={true} />
)

export const AgreementPage: FC<{ fade?: boolean }> = ({ fade = true }) => (
  <AgreementLoadable fade={fade} />
)

export const StandaloneAgreementPage: FC = () => (
  <AgreementLoadable fade={false} standalone={true} />
)

const AgreementVipLoadable = loadable(
  () => import('components/page/Legal/AgreementVip/AgreementVipIndex'),
  withLoader
)

export const AgreementVipPage: FC<{ fade: boolean }> = ({ fade = true }) => (
  <AgreementVipLoadable fade={fade} />
)

export const AgreementVipPageMobile: FC = () => (
  <AgreementVipLoadable plain={true} fade={false} />
)

export const PlainAgreementPage: FC = () => {
  return <AgreementLoadable plain={true} />
}

export const PlainAlertPaymentPage: FC = () => (
  <AlertPaymentLoadable plain={true} fade={false} />
)

export const StandaloneAgreementAndroidPage: FC = () => (
  <AgreementLoadable
    fade={false}
    standalone={true}
    forcePlatform={androidOsType}
  />
)

export const StandaloneAgreementIOSPage: FC = () => (
  <AgreementLoadable standalone={true} forcePlatform={iOsOsType} />
)

export const AlertPaymentPage = () => <AlertPaymentLoadable fade={false} />
