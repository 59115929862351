import { AppRoute } from 'components/components.types'
import { AstroStarAgreementLoadable } from 'components/page/PromoService/Promo/AstroStar/AstroStarAgreement/AstroStarAgreementLoadable'
import { AstroStarCurrentUserModaLoadable } from 'components/page/PromoService/Promo/AstroStar/AstroStarModal/AstroStarCurrentUserModalLoadable'
import {
  astroStarModalAgreementPath,
  astroStarModalFormPath,
  astroStarModalPath,
} from 'components/page/PromoService/Promo/AstroStar/AstroStarModal/AstroStarModal.paths'
import { AstroStarModalFormLoadable } from 'components/page/PromoService/Promo/AstroStar/AstroStarModal/AstroStarModalFormLoadable'
import { AstroStarLoadable } from 'components/page/PromoService/Promo/AstroStar/AstroStarModal/AstroStarModalLoadable'
import { astroStarModalCurrentUserPath } from 'components/paths'

export const astroStarModalRoutes: AppRoute[] = [
  {
    path: astroStarModalPath,
    component: AstroStarLoadable,
    exact: false,
  },
  {
    path: astroStarModalCurrentUserPath,
    component: AstroStarCurrentUserModaLoadable,
    exact: false,
  },
]

export const commonAstroStarRoutes: AppRoute[] = [
  {
    path: astroStarModalFormPath,
    component: AstroStarModalFormLoadable,
  },
  {
    path: astroStarModalAgreementPath,
    component: AstroStarAgreementLoadable,
  },
]
