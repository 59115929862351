import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const LoginLoadable = loadable(() => import('./Login'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('login split error', error)
    }
    return undefined
  })(defaultLoadableProps),
})
