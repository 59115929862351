import React, { FC, useCallback } from 'react'

import {
  ErrorBoundary,
  ErrorBoundaryProps,
  captureException,
} from '@sentry/react'
import { useDispatch } from 'react-redux'

import { checkBundleUpdateAction } from 'actions/system/checkBundleUpdateAction'
import { WithChildren } from 'common/types'
import { internalServerErrorPath } from 'components/paths'
import { replace } from 'functions/router'

export const ErrorBoundaryWithRedirect: FC<{ uid: string } & WithChildren> = ({
  uid,
  children,
}) => {
  const dispatch = useDispatch()

  const handlerError = useCallback<NonNullable<ErrorBoundaryProps['onError']>>(
    (error) => {
      /** Возможно дублирование лога, если что потом уберем */
      captureException(`Error boundary "${uid}": ${error}`)
      dispatch(replace(internalServerErrorPath))
    },
    [dispatch, uid]
  )

  const handleBeforeCapture = useCallback(() => {
    console.error('Error boundary: before capture', uid)
    /**
     * Попробуем обновить PWA.
     */
    dispatch(checkBundleUpdateAction())
  }, [dispatch, uid])

  return (
    <ErrorBoundary onError={handlerError} beforeCapture={handleBeforeCapture}>
      {children}
    </ErrorBoundary>
  )
}
