import { Reducer } from 'redux'

import { LogoutAction, LOGOUT_ACTION } from 'actions/authorization/logoutAction'
import {
  RegisterPushConsumerAction,
  REGISTER_PUSH_TOKEN,
} from 'actions/push/registerPushConsumerAction'
import {
  UnregisterPushConsumerAction,
  UNREGISTER_PUSH_TOKEN,
} from 'actions/push/unregisterPushConsumerAction'
import {
  UpdatePushNotificationsPermissionStatusAction,
  UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS,
} from 'actions/push/updatePushNotificationsPermissionStatusAction'
import {
  updatePushNotificationsRequestModalHiddenTillDateAction,
  UPDATE_PUSH_NOTIFICATIONS_REQUEST_MODAL_HIDDEN_TILL_DATE,
} from 'actions/push/updatePushNotificationsRequestModalHiddenTillDateAction'
import {
  updatePushNotificationsSupportedAction,
  UPDATE_PUSH_NOTIFICATIONS_SUPPORTED,
} from 'actions/push/updatePushNotificationsSupportedAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'

const INITIAL_STATE = {
  /** Поддерживаются ли push-уведомления с учетом требований npm-пакета firebase/messaging */
  pushNotificationsSupported: null as null | boolean,
  /** Статус разрешения уведомлений в браузере */
  pushNotificationsPermissionState: null as null | PermissionState | undefined,
  /** Показывать модал "Включить уведомления" не раньше даты(в миллисекундах) */
  pushNotificationsRequestModalHiddenTillDate: 0 as number,
  /** Firebase token */
  pushNotificationsToken: null as null | string,
} as const

export type PushNotificationsState = typeof INITIAL_STATE

export const pushNotificationsReducer: Reducer<
  PushNotificationsState,
  | ReturnType<typeof updatePushNotificationsSupportedAction>
  | ReturnType<typeof updatePushNotificationsRequestModalHiddenTillDateAction>
  | UpdatePushNotificationsPermissionStatusAction
  | RegisterPushConsumerAction
  | UnregisterPushConsumerAction
  | LogoutAction
> = (state = INITIAL_STATE, action): PushNotificationsState => {
  switch (action.type) {
    case UPDATE_PUSH_NOTIFICATIONS_SUPPORTED:
      return { ...state, pushNotificationsSupported: action.value }

    case UPDATE_PUSH_NOTIFICATIONS_PERMISSION_STATUS: {
      return {
        ...state,
        pushNotificationsPermissionState: action.value,
      }
    }

    case UPDATE_PUSH_NOTIFICATIONS_REQUEST_MODAL_HIDDEN_TILL_DATE: {
      return {
        ...state,
        pushNotificationsRequestModalHiddenTillDate: action.value,
      }
    }

    case REGISTER_PUSH_TOKEN:
      return defaultPromiseReducer(
        state,
        action,
        () => ({
          pushNotificationsToken: INITIAL_STATE.pushNotificationsToken,
        }),
        () => ({
          pushNotificationsToken: action.value,
        })
      )

    case UNREGISTER_PUSH_TOKEN:
      return {
        ...state,
        pushNotificationsToken: INITIAL_STATE.pushNotificationsToken,
      }

    case LOGOUT_ACTION:
      return INITIAL_STATE

    default:
      return state
  }
}
