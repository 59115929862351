import React from 'react'

import loadable from '@loadable/component'

import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

/**
 * Здесь не нужен loader, так как он появляется в режиме телефона
 * и сжимает весь контент.
 */
export const NotificationRendererLoadable = loadable(
  () => import('./NotificationRenderer'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Notification renderer split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
