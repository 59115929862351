import { useEffect, useState } from 'react'

import { useLocation } from 'react-router'

import { thenPartPath } from 'components/block/Then/Then.paths'
import { loginPath } from 'components/page/Boarding/boarding.paths'
import { legacyGdprPath } from 'components/page/Legacy/Legacy.paths'
import { restorePasswordPath } from 'components/page/RestorePassword/RestorePasswordLoadable'
import { searchSettingsPath } from 'components/page/Search/Search.paths'
import { stepRegistrationInnerPaths } from 'components/page/StepRegistration/StepRegistration.paths'
import {
  agreementIndexPath,
  confidentialityPath,
  settingsModalPath,
  settingsPath,
  standaloneAgreementPartPath,
} from 'components/paths'
import { mobileAppSupportRoutes } from 'components/routes/mobileAppSupportRoutes'
import { baseStoreFrontPath } from 'components/storefront/StoreFront.paths'
import { noticePath } from 'components/uninotice/UniNotice.paths'
import { removeLocaleFromPath } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import commonModalRoutes from 'routes/commonModalRoutes'
import unauthorizedModalRoutes from 'routes/unauthorizedModalRoutes'

import { installMobileAppBannerLayerPath } from '../InstallMobileAppBannerLayer.paths'

export const useShowBannerOnCurrentPath = (): boolean => {
  const { pathname } = useLocation()
  const pathnameWithoutLocale = mergeAllUrls(removeLocaleFromPath(pathname))
  const [show, setShow] = useState<boolean>(isShowBanner(pathnameWithoutLocale))

  useEffect(() => {
    setShow(isShowBanner(pathnameWithoutLocale))
  }, [pathnameWithoutLocale])

  return show
}

const isShowBanner = (pathnameWithoutLocale: string): boolean => {
  if (
    /**
     * Решили показывать на боардинге:
     * https://youtrack.mamba.ru/issue/M-9117
     */
    pathWithoutAppBanner.find((path) =>
      mergeAllUrls(pathnameWithoutLocale).includes(path)
    ) ||
    pathnameWithoutLocale === legacyGdprPath
  ) {
    return false
  }

  return true
}

/**
 * На ряде в том числе модальных экранов
 * Виталик захотел баннер:
 * https://youtrack.mamba.ru/issue/M-9496
 */
const forceShowAppBannerPaths = [
  loginPath,
  restorePasswordPath,
  mergeAllUrls(restorePasswordPath),
]

const pathWithoutAppBanner: string[] = [
  ...[...commonModalRoutes, ...unauthorizedModalRoutes].reduce<string[]>(
    (acc, { path }) => {
      return [...acc, path]
    },
    []
  ),
  settingsPath,
  searchSettingsPath,
  settingsModalPath,
  baseStoreFrontPath,
  noticePath,
  thenPartPath,
  confidentialityPath,
  agreementIndexPath,
  standaloneAgreementPartPath,
  installMobileAppBannerLayerPath,
  ...mobileAppSupportRoutes.map(({ path }) => path),
].filter((path) => !forceShowAppBannerPaths.includes(path))
