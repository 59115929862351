import React, { FC } from 'react'

import { useDispatch } from 'react-redux'

import { removeShortCutNotificationAction } from 'actions/uninotice/noticeShortCutAction'
import { UniNoticeId } from 'api/uninotice/uniNoticeApi'
import { NotificationRendererLoadable } from 'components/presentational/Notifications/NotificationRendererLoadable'
import { NotificationType } from 'components/presentational/Notifications/NotificationType'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const NoticeShortCut: FC = () => {
  const dispatch = useDispatch()

  const { notificationsQueue } = useShallowEqualSelector(
    ({ noticeShortCut: { notificationsQueue } }) => ({
      notificationsQueue,
    })
  )

  const firstNoticeShortCut = notificationsQueue[0]

  const removeShortcutNotification = (id: UniNoticeId) => {
    dispatch(removeShortCutNotificationAction(id))
  }

  if (!firstNoticeShortCut) {
    return null
  }

  return (
    <NotificationRendererLoadable
      key={firstNoticeShortCut.id}
      type={firstNoticeShortCut.type as NotificationType}
      id={firstNoticeShortCut.id}
      removeNotification={() =>
        removeShortcutNotification(firstNoticeShortCut.id)
      }
    />
  )
}
