import React, { FC } from 'react'

import loadable from '@loadable/component'

import { indexPath, ratingPath } from 'components/paths'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { Replace } from 'components/system/Replace'
import { useRedesign } from 'hooks/useRedesign'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const BlockedByIpListLoadable: FC = () => {
  const { authorized, ipBlocked } = useShallowEqualSelector(
    ({
      authorizationReducer: { authorized },
      errorReducer: { ipBlocked },
    }) => ({
      authorized,
      ipBlocked,
    })
  )
  const redesign = useRedesign()

  if (!ipBlocked && !authorized) {
    return <Replace to={indexPath} uid="BlockedByIpList" />
  } else if (!ipBlocked && authorized) {
    return <Replace to={ratingPath} uid="BlockedByIpList-authorized" push />
  }

  if (redesign) {
    return <BlockedByIpListNextLoadable />
  }

  return <_BlockedByIpListLoadable />
}

const _BlockedByIpListLoadable = loadable(() => import('./BlockedByIpList'), {
  fallback: (({ error }) => {
    if (error) {
      console.error('Blocked by ip list split error', error)
    }
    return <DefaultLoader />
  })(defaultLoadableProps),
})
const BlockedByIpListNextLoadable = loadable(() =>
  import('./BlockedByIpListNext')
)
