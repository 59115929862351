import React, { FC } from 'react'

import { useRouteMatch } from 'react-router'

import { ratingPath } from 'components/paths'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'

/**
 * Отдельная точка входа в витрины без лейаута мамбы.
 * https://youtrack.mamba.ru/issue/M-2242
 *
 * @constructor
 */
export const StandaloneStoreFront: FC = () => {
  const match = useRouteMatch()

  return (
    <Replace
      uid="store front standalone"
      to={mergeAllUrls(ratingPath, match.url)}
    />
  )
}
