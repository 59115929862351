import loadable from '@loadable/component'

import { AppRoute } from 'components/components.types'
import { oneTimePasswordPath } from 'components/page/WebView/WebView.paths'

export const webViewRoutes: AppRoute[] = [
  {
    path: oneTimePasswordPath,
    component: loadable(() => import('./OneTimePasswordCode')),
    exact: true,
  },
]
