import { defaultPromiseReducer } from 'reducers/isActionWithoutErrors'

import {
  FETCH_POPULARITY_STATUS,
  FETCH_POPULARITY_STATISTIC,
  CHANGE_FIELD_STATISTIC,
} from '../actions/popularityAction'

export default function popularityReducer(
  state = {
    status: 'low',
    userId: null,
    dailyStat: {},
    weeklyStat: {},
    activeTab: 'daily',
    loaded: false,
  },
  action
) {
  const { type, result } = action

  switch (type) {
    case FETCH_POPULARITY_STATUS:
      return defaultPromiseReducer(
        state,
        action,
        () => null,
        () => ({
          status: result.value,
        })
      )

    case FETCH_POPULARITY_STATISTIC:
      return defaultPromiseReducer(
        state,
        action,
        () => null,
        () => ({
          status: result.status,
          userId: result.userId,
          dailyStat: { ...result.dailyStat },
          weeklyStat: { ...result.weeklyStat },
          loaded: true,
        }),
        () => ({
          loaded: true,
        })
      )

    case CHANGE_FIELD_STATISTIC:
      const nextActiveTab = state.activeTab === 'daily' ? 'weekly' : 'daily'
      return {
        ...state,
        activeTab: nextActiveTab,
      }

    default:
      return state
  }
}
