import React from 'react'

import loadable from '@loadable/component'
import { History } from 'history'

import { AppStore } from 'common/store/store.types'
import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'
import { withAppContextHoc } from 'components/system/withAppContextHoc'
import { insertReducers } from 'hooks/useReducersInsert'

export const FeedPageLoadable = withAppContextHoc(
  'feed',
  loadable<{ store: AppStore; history: History }>(
    async (props) => {
      const module = await import('./FeedPageSwitcher')
      insertReducers(props.store, props.history, module.asyncReducers)
      return module.FeedPageSwitcher
    },
    {
      fallback: (({ error }) => {
        if (error) {
          console.error('Feed page loadable split error', error)
        }
        return <DefaultLoader />
      })(defaultLoadableProps),
      ssr: false,
    }
  )
)
