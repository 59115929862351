import React from 'react'

import loadable from '@loadable/component'

import { DefaultLoader } from 'components/presentational/AnimatedWatch/AnimatedWatch'

export const AllRegionsAndCitiesLoadable = loadable(
  () => import('./AllRegionsAndCities'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('Positive cities split error', error)
      }
      return <DefaultLoader />
    })({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  }
)
